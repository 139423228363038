import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
//services
import { AuthService } from 'src/app/shared/services/auth.service';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(private router: Router,private auth: AuthService, private http: HttpService){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Promise<boolean>(async (resolve,reject)=>{
        if(route.queryParams && route.queryParams['ck'] && await this.http.login(decodeURIComponent(state.url.split("=")[1]))){
          //navigate to the first permission
          //this.router.navigateByUrl(this.auth.user.permissions[0].route)
          this.router.navigateByUrl("/");
          resolve(true);
        }else if(this.auth.validateToken()){
          this.router.navigateByUrl("/");
          resolve(true);
        }
        resolve(true);
        //resolve(true); //TEST
      });
  }
  
}
