import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { retry, take,catchError  } from 'rxjs/operators';
import {throwError} from 'rxjs'
import { NavigationExtras, Router } from "@angular/router";

//primeng services
import { MessageService } from 'primeng/api';
//own services
import { AuthService } from 'src/app/shared/services/auth.service';
//models
import Module from '../models/Module';
import { groupBy, permissionMapper } from '../utils/utils';
import { environment } from 'src/environments/environment';
import { Observable,BehaviorSubject  } from 'rxjs';
import ClientesProspecto from '../models/ClientesProspecto';
import TipoProyecto from '../models/TipoProyecto';

//Contacto empresa
import ContactoEmpresa from '../models/ContactoEmpresa';
import Estados_Ciudades from '../models/Estados_Ciudades';
import IModeloEstatus from '../models/ModeloEstatus';
import IMovimientosCteProspecto from '../models/MomientosCteProspecto';
import IPuestos from '../models/Puestos';
import IClasificacionPuestos from '../models/ClasfiicacionPuestos';
import ISoporteCobranza from '../models/SoporteCobranza';
import IConfiguracionUnidades from '../models/ConfiguracionUnidades';
import IIndicadoresDesempeño from '../models/IndicadoresDesempeño';
import IInfoBrief from '../models/InfoBrief';
import { ITiposProductos } from '../models/TiposProductos';
import { IClasificacionPersonal } from '../models/ClasificacionPersonal';
import { ITipoManiobra } from '../models/TipoManiobra';
import { ITipoRutaBrief } from '../models/TipoRutaBrief';
import { ITipoConfirmacion } from '../models/TipoConfirmacion';
import { IZonaDescarga } from '../models/ZonaDescarga';
import { ITipoCaja } from '../models/TipoCaja';
import { IGeneraCarga } from '../models/GeneraCarga';

@Injectable({
  providedIn: 'root'
})
export class clienteprospecto {
  private apiUrl: string = "";
  private usuariosSubject = new BehaviorSubject<any[]>([]);
  private apiUrlComercial:string="";
  private apiUrlComercialContacto:string="";
  private apiUrlComercialEstadosMunicipios:string="";
  private apiUrlComercialEstatusLead:string="";
  private apiUrlComercialMovimientos:string="";
  private apiUrlComercialSaveMovimiento:string="";
  private apiUrlComercialSaveArchivos:string="";
  private apiUrlComercialPuestos:string="";
  private apiUrlComercialPuestosClasificacion:string="";
  private apiUrlComercialObtenerArchivos:string="";
  private apiUrlComercialObtenerNombreArchivos:string="";
  private apiUrlComercialDescargarArchivo:string="";
  private apiUrlComercialEliminarArchivo:string="";
  private apiUrlComercialSoporteCobranza:string="";
  private apiUrlComercialConfiguracionUnidades:string="";
  private apiUrlComercialIndicadoresDesempeño:string="";
  private apiUrlComercialGuardarInformacionBrief:string="";
  private apiUrlComercialObtenerInformacionBrief:string="";
  private apiUrlComercialSubirArchivoBrief:string="";
  private apiUrlComercialBriefGetTiposProductos:string="";
  private apiUrlComercialBriefGetClasificacionPersonal:string="";
  private apiUrlComercialBriefGetTipoManiobra:string="";
  private apiUrlComercialBriefGetTipoRuta:string="";
  private apiUrlComercialBriefGetTipoConfirmacion:string="";
  private apiUrlComercialBriefGetZonaDescarga:string="";
  private apiUrlComercialBriefGetTipoCaja:string="";
  private apiUrlComercialBriefGetGeneraCarga:string="";
  private apiUrlComercialPropuestaComercialCambioEstatusCliente:string="";
  private apiUrlComercialPropuestaComercialSubirArchivoPropuesta:string="";
  private apiUrlComercialPropuestaComercialSubirArchivoVat:string="";
  private apiUrlComercialPropuestaComercialSubirArchivoZIP:string="";
  private apiUrlComercialPropuestaComercialSubirArchivoID:string="";
  private apiUrlComercialPropuestaComercialSubirArchivoRegimenFiscal:string="";
  private apiUrlComercialBriefGetMercancia:string="";
  private apiUrlComercialDescargarPDFBrief:string="";
  private apiUrlComercialDescargarArchivosPropuesta:string="";
  private apiUrlComercialGetArchivosPropuesta:string="";
  private apiUrlComercialGetTipoProducto:string="";
  private apiUrlComercialMarcarProceso:string="";
  private apiUrlComercialObtenerProcesos:string="";
  private apiUrlComercialCierreRuta:string="";
  

  constructor(private http: HttpClient, private auth: AuthService, private message: MessageService, private router: Router) {
    this.apiUrl = environment.sUrlAuth + 'Auth';
    this.apiUrlComercial=environment.SUrlgApi+'CtesProspectos';
    this.apiUrlComercialContacto=environment.SUrlgApi+'CtoEmpresa';
    this.apiUrlComercialEstadosMunicipios=environment.SUrlgApi+'EstadosMunicipios';
    this.apiUrlComercialEstatusLead=environment.SUrlgApi+'EstatusCte/estatus';
    this.apiUrlComercialMovimientos=environment.SUrlgApi+'EstatusCte/movimientos';
    this.apiUrlComercialSaveMovimiento=environment.SUrlgApi+'EstatusCte/saveEstatusMovimiento';
    this.apiUrlComercialSaveArchivos=environment.SUrlgApi+'EstatusCte/saveArchivos';
    this.apiUrlComercialPuestos=environment.SUrlgApi+'Puestos';
    this.apiUrlComercialPuestosClasificacion=environment.SUrlgApi+'Puestos/clasificacion';
    this.apiUrlComercialObtenerNombreArchivos=environment.SUrlgApi+'EstatusCte/getArchivos';
    this.apiUrlComercialDescargarArchivo=environment.SUrlgApi+'EstatusCte/DescargarArchivo';
    this.apiUrlComercialEliminarArchivo=environment.SUrlgApi+'EstatusCte/eliminarArchivo';
    this.apiUrlComercialSoporteCobranza=environment.SUrlgApi+'BriefComercial/soportecobranza';
    this.apiUrlComercialConfiguracionUnidades=environment.SUrlgApi+'ConfUnidad';
    this.apiUrlComercialIndicadoresDesempeño=environment.SUrlgApi+'BriefComercial/indicadores';
    this.apiUrlComercialGuardarInformacionBrief=environment.SUrlgApi+'BriefComercial/saveBrief';
    this.apiUrlComercialObtenerInformacionBrief=environment.SUrlgApi+'BriefComercial/getBrief';
    this.apiUrlComercialSubirArchivoBrief=environment.SUrlgApi+'BriefComercial/saveArchivoBrief';
    this.apiUrlComercialBriefGetTiposProductos=environment.SUrlgApi+'BriefComercial/gettiposproductos';
    this.apiUrlComercialBriefGetClasificacionPersonal=environment.SUrlgApi+'BriefComercial/getclasificacionpersonal';
    this.apiUrlComercialBriefGetTipoManiobra=environment.SUrlgApi+'BriefComercial/gettipomaniobra';
    this.apiUrlComercialBriefGetTipoRuta=environment.SUrlgApi+'BriefComercial/gettiporuta';
    this.apiUrlComercialBriefGetTipoConfirmacion=environment.SUrlgApi+'BriefComercial/gettipoconfirmacion';
    this.apiUrlComercialBriefGetZonaDescarga=environment.SUrlgApi+'BriefComercial/getzonadescarga';
    this.apiUrlComercialBriefGetTipoCaja=environment.SUrlgApi+'BriefComercial/gettipocaja';
    this.apiUrlComercialBriefGetGeneraCarga=environment.SUrlgApi+'BriefComercial/getgeneracarga';
    this.apiUrlComercialPropuestaComercialCambioEstatusCliente=environment.SUrlgApi+'BriefComercial/updateEstatusCte';
    this.apiUrlComercialPropuestaComercialSubirArchivoPropuesta=environment.SUrlgApi+'BriefComercial/saveArchivoPropuesta';
    this.apiUrlComercialPropuestaComercialSubirArchivoVat=environment.SUrlgApi+'BriefComercial/saveArchivoVat';
    this.apiUrlComercialPropuestaComercialSubirArchivoZIP=environment.SUrlgApi+'BriefComercial/saveArchivoZIP';
    this.apiUrlComercialPropuestaComercialSubirArchivoID=environment.SUrlgApi+'BriefComercial/saveArchivoID';
    this.apiUrlComercialPropuestaComercialSubirArchivoRegimenFiscal=environment.SUrlgApi+'BriefComercial/saveArchivoRegimenFiscal';
    this.apiUrlComercialBriefGetMercancia=environment.SUrlgApi+'BriefComercial/getmercancia';
    this.apiUrlComercialDescargarPDFBrief=environment.SUrlgApi+'ArchivoBrief/generarReporte';
    this.apiUrlComercialDescargarArchivosPropuesta=environment.SUrlgApi+'EstatusCte/descargarArchivosPropuesta';
    this.apiUrlComercialGetArchivosPropuesta=environment.SUrlgApi+'EstatusCte/getArchivosPropuesta';
    this.apiUrlComercialGetTipoProducto=environment.SUrlgApi+'BriefComercial/gettiposproductos';
    this.apiUrlComercialMarcarProceso=environment.SUrlgApi+'TabuladorCliente/updateInicioRuta';
    this.apiUrlComercialObtenerProcesos=environment.SUrlgApi+'TabuladorCliente/getRegistroInicioRuta';
    this.apiUrlComercialCierreRuta=environment.SUrlgApi+'TabuladorCliente/updateCierreRuta';
   }

   UpdateCierreRuta(idxs:number):Observable<any>{
      let params={
        idx:idxs
      }
      return this.http.put<any>(this.apiUrlComercialCierreRuta,params).pipe(retry(1), catchError(this.handleError));
   }

   GetProcesos(id:number,idx:number):Observable<any>{
    let params={
      id_cte_prospecto:id,
      idx_tabulador:idx
    }
    return this.http.get<any>(this.apiUrlComercialObtenerProcesos,{params}).pipe(retry(1), catchError(this.handleError));
   }

   MarcarProceso(data:any):Observable<any>{
    return this.http.put<any>(this.apiUrlComercialMarcarProceso,data).pipe(retry(1), catchError(this.handleError));
   }

   GetTipoProducto():Observable<any[]>{
    return this.http.get<any[]>(this.apiUrlComercialGetTipoProducto).pipe(retry(1), catchError(this.handleError));
   }

   GetArchivosPropuesta(data:any):Observable<any[]>{
    let params={
      id_cte_prospecto:data.id_cte_prospecto,
      nom_empresa:data.nom_empresa
    }
    return this.http.get<any[]>(this.apiUrlComercialGetArchivosPropuesta,{params}).pipe(retry(1), catchError(this.handleError));
   }

   DescargarArchivoPropuesta(data:any):Observable<Blob>{
    const params={
      id_cte_prospecto:data.id_cte_prospecto,
      nom_empresa:data.nom_empresa
    }
    return this.http.get(this.apiUrlComercialDescargarArchivosPropuesta, {
      responseType: 'blob',
      params
    }).pipe(
      catchError(this.handleError)
    );
   }

   DescargarPDFBrief(id:number):Observable<Blob>{
    const params={
      id_cte_prospecto:id
    }
    return this.http.get(this.apiUrlComercialDescargarPDFBrief, {
      responseType: 'blob',
      params
    }).pipe(
      catchError(this.handleError)
    );
   }

   GetMercancia():Observable<any>{
    return this.http.get<any>(this.apiUrlComercialBriefGetMercancia).pipe(retry(1), catchError(this.handleError));
   }
  
   SaveArchivoPropuesta(obj:FormData,data:any):Observable<any>{
    const params={
      cte_prospecto:data.id_cte_prospecto,
      nom_empresa:data.nom_empresa,
      numero:data.numero
    };
    const headers = new HttpHeaders();
    return this.http.post<any>(this.apiUrlComercialPropuestaComercialSubirArchivoPropuesta,obj,{params,headers});
  }

  SaveArchivoVat(obj:FormData,data:any):Observable<any>{
    const params={
      cte_prospecto:data.id_cte_prospecto
    };

    const headers = new HttpHeaders();
    return this.http.post<any>(this.apiUrlComercialPropuestaComercialSubirArchivoVat,obj,{params,headers});
  }
  SaveArchivoZip(obj:FormData,data:any):Observable<any>{
    const params={
      cte_prospecto:data.id_cte_prospecto
    };

    const headers = new HttpHeaders();
    return this.http.post<any>(this.apiUrlComercialPropuestaComercialSubirArchivoZIP,obj,{params,headers});
  }

  SaveArchivoID(obj:FormData,data:any):Observable<any>{
    const params={
      cte_prospecto:data.id_cte_prospecto
    };

    const headers = new HttpHeaders();
    return this.http.post<any>(this.apiUrlComercialPropuestaComercialSubirArchivoID,obj,{params,headers});
  }

  SaveArchivoRegimenFiscal(obj:FormData,data:any):Observable<any>{
    const params={
      cte_prospecto:data.id_cte_prospecto
    };

    const headers = new HttpHeaders();
    return this.http.post<any>(this.apiUrlComercialPropuestaComercialSubirArchivoRegimenFiscal,obj,{params,headers});
  }

   GetGeneraCarga():Observable<IGeneraCarga[]>{
    return this.http.get<IGeneraCarga[]>(this.apiUrlComercialBriefGetGeneraCarga).pipe(retry(1), catchError(this.handleError));
   }

   GetTiposProductos():Observable<ITiposProductos[]>{
    return this.http.get<ITiposProductos[]>(this.apiUrlComercialBriefGetTiposProductos).pipe(retry(1), catchError(this.handleError));
  }

  GetClasificacionPersonal():Observable<IClasificacionPersonal[]>{
    return this.http.get<IClasificacionPersonal[]>(this.apiUrlComercialBriefGetClasificacionPersonal).pipe(retry(1), catchError(this.handleError));
  }

  GetTipoManiobra():Observable<ITipoManiobra[]>{
    return this.http.get<ITipoManiobra[]>(this.apiUrlComercialBriefGetTipoManiobra).pipe(retry(1), catchError(this.handleError));
  }

  GetTipoRuta():Observable<ITipoRutaBrief[]>{
    return this.http.get<ITipoRutaBrief[]>(this.apiUrlComercialBriefGetTipoRuta).pipe(retry(1), catchError(this.handleError));
  }

  GetTipoConfirmacion():Observable<ITipoConfirmacion[]>{
    return this.http.get<ITipoConfirmacion[]>(this.apiUrlComercialBriefGetTipoConfirmacion).pipe(retry(1), catchError(this.handleError));
  }

  GetZonaDescarga():Observable<IZonaDescarga[]>{
    return this.http.get<IZonaDescarga[]>(this.apiUrlComercialBriefGetZonaDescarga).pipe(retry(1), catchError(this.handleError));
  }

  GetTipoCaja():Observable<ITipoCaja[]>{
    return this.http.get<ITipoCaja[]>(this.apiUrlComercialBriefGetTipoCaja).pipe(retry(1), catchError(this.handleError));
  }


   //Medios de contacto
  public SaveClienteProspecto(data:ClientesProspecto):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.post<any>(this.apiUrlComercial + '/saveCtesProspectos/',body,{'headers':headers});
  }

  GetPuestos():Observable<IPuestos[]>{
    return this.http.get<IPuestos[]>(this.apiUrlComercialPuestos).pipe(retry(1), catchError(this.handleError));
  }

  GetNombresPDF(id:number):Observable<any[]>{

      let params={
        id_cte_prospecto:id,
        clv_opcion:1
      }

    return this.http.get<any[]>(this.apiUrlComercialObtenerNombreArchivos,{params}).pipe(retry(1), catchError(this.handleError));
  }

  GetSoporteCobranza():Observable<ISoporteCobranza[]>{
    return this.http.get<ISoporteCobranza[]>(this.apiUrlComercialSoporteCobranza).pipe(retry(1), catchError(this.handleError));
  }

  DeleteArchivo(data:any):Observable<any>{
    const params={
      nombreArchivo:data.nombreArchivo,
      nom_empresa:data.nom_empresa,
      idx:data.idx,
      num_operacion:data.num_operacion
    }
    return this.http.delete(this.apiUrlComercialEliminarArchivo,{params}).pipe(retry(1), catchError(this.handleError))
  }

  saveInformacionBrief(id:number,nom_empresa:string,data:any):Observable<any>{
    const token = this.auth.token;
    //const params = new HttpParams().set('ck', token);

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params={
      ck:token,
      cte_prospecto:id,
      nom_empresa:nom_empresa
    };

    //const headers = new HttpHeaders(); // Mantén los headers vacíos para que se establezca automáticamente el Content-Type adecuado

    // Convertir los datos a JSON
    const body = JSON.stringify(data);
    return this.http.post<any>(this.apiUrlComercialGuardarInformacionBrief,body, { params, headers });
  }

  GetInformacionBrief(id:number):Observable<IInfoBrief[]>{
    const valores={
      params: new HttpParams().set('id_cte_prospecto',id)
    };
    return this.http.get<IInfoBrief[]>(this.apiUrlComercialObtenerInformacionBrief,valores).pipe(retry(1), catchError(this.handleError));
  }

  GetDescargarArchivo(data:any):Observable<Blob>{
    const params={
      nom_empresa:data.nom_empresa,
      nombreArchivo:data.nombreArchivo
    }

    return this.http.get(this.apiUrlComercialDescargarArchivo, {
      responseType: 'blob',
      params
    }).pipe(
      catchError(this.handleError)
    );
  }

  GetClasificacionPuestos():Observable<IClasificacionPuestos[]>{
    return this.http.get<IClasificacionPuestos[]>(this.apiUrlComercialPuestosClasificacion).pipe(retry(1), catchError(this.handleError));
  }

  public getContactoEmpresa(id_cte_prospecto:number):Observable<ContactoEmpresa[]>{
    return this.http.get<ContactoEmpresa[]>(this.apiUrlComercialContacto + '?id_cte_prospecto='+id_cte_prospecto).pipe(retry(1), catchError(this.handleError));
  }

  getIndicadores():Observable<IIndicadoresDesempeño[]>{
    return this.http.get<IIndicadoresDesempeño[]>(this.apiUrlComercialIndicadoresDesempeño).pipe(retry(1), catchError(this.handleError));
  }

  getMovimientosEstatus(id:number): Observable<IMovimientosCteProspecto[]>{
    //const token = this.auth.token;

    // Definir los encabezados con el token de autorización y la cookie

    const valores={
      params: new HttpParams().set('id_cte_prospecto',id)
    };

    // Realizar la solicitud HTTP GET con los encabezados
    return this.http.get<IMovimientosCteProspecto[]>(`${this.apiUrlComercialMovimientos}`,valores)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  CambioEstatusCliente(data:any):Observable<any>{
    return this.http.put<any>(this.apiUrlComercialPropuestaComercialCambioEstatusCliente,data).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  saveMovimientoEstatus(data:any): Observable<any>{
    const token = this.auth.token;
    const params = new HttpParams().set('ck', token);

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    // Convertir los datos a JSON
    const body = JSON.stringify(data);
    
    //const headers = { 'content-type': 'application/json'}
    //const body = JSON.stringify(data);
    return this.http.post<any>(this.apiUrlComercialSaveMovimiento,body,{params,headers});
  }

  getEstatusLead(): Observable<IModeloEstatus[]> {
    // Obtener el token de autenticación del servicio de autenticación
    const token = this.auth.token;

    // Definir los encabezados con el token de autorización y la cookie

    const valores={
      params: new HttpParams().set('ck',token)
    };

    // Realizar la solicitud HTTP GET con los encabezados
    return this.http.get<IModeloEstatus[]>(`${this.apiUrlComercialEstatusLead}`,valores)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getClientesProspecto(): Observable<ClientesProspecto[]> {
    // Obtener el token de autenticación del servicio de autenticación
    const token = this.auth.token;

    const valores={
      params: new HttpParams().set('ck',token)
    };

    // Realizar la solicitud HTTP GET con los encabezados
    return this.http.get<ClientesProspecto[]>(`${this.apiUrlComercial}`,valores)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  public getEstadosMunicipios():Observable<Estados_Ciudades[]>{
    return this.http.get<Estados_Ciudades[]>(this.apiUrlComercialEstadosMunicipios).pipe(retry(1), catchError(this.handleError));
  }

  public getMunicipios(id:number):Observable<Estados_Ciudades[]>{
    return this.http.get<Estados_Ciudades[]>(this.apiUrlComercialEstadosMunicipios+'/Municipios/?id='+id).pipe(retry(1), catchError(this.handleError));
  }

  public getTipoProyecto():Observable<TipoProyecto[]>{
    return this.http.get<TipoProyecto[]>(this.apiUrlComercial).pipe(retry(1), catchError(this.handleError));
  }

  getConfiguracionUnidades():Observable<IConfiguracionUnidades[]>{
    return this.http.get<IConfiguracionUnidades[]>(this.apiUrlComercialConfiguracionUnidades).pipe(retry(1), catchError(this.handleError));
  }

  SaveBriefInfo(data:any):Observable<any>{

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    // Convertir los datos a JSON
    //const body = JSON.stringify(data);
  

    return this.http.post<any>(this.apiUrlComercialGuardarInformacionBrief,data,{headers});
  }

  SaveArchivoBrief(obj:FormData,data:any):Observable<any>{
    const token = this.auth.token;
    const params={
      ck:token,
      cte_prospecto:data.id_cte_prospecto,
      nom_empresa:data.nom_empresa
    };

    const headers = new HttpHeaders();
    return this.http.post<any>(this.apiUrlComercialSubirArchivoBrief,obj,{params,headers});
  }
  
  SaveArchivos(data: FormData,datos:any): Observable<any> {
    const token = this.auth.token;
    const param = new HttpParams().set('ck', token);
    const params={
      ck:token,
      cte_prospecto:datos.id_cte_prospecto,
      nom_empresa:datos.nom_empresa
    };

    const headers = new HttpHeaders(); // Mantén los headers vacíos para que se establezca automáticamente el Content-Type adecuado

    // Envía el FormData directamente
    return this.http.post<any>(this.apiUrlComercialSaveArchivos, data, { params, headers });
  }

  getPDFFiles(): Observable<any[]> {
    // Obtener el token de autenticación del servicio de autenticación
    const token = this.auth.token;
    // Definir los encabezados con el token de autorización y la cookie

    const valores={
      params: new HttpParams().set('ck',token)
    };

    // Realizar la solicitud HTTP GET con los encabezados
    return this.http.get<any[]>(`${this.apiUrlComercialObtenerArchivos}`,valores)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );

    //return this.http.get<[]>('/root/projects/beta/frontend/comercial/assets/files/comercial');// Cambia la ruta a la deseada
  }

  public saveContactoEmpresa(data:ContactoEmpresa):Observable<any>{
    const token = this.auth.token;
    const params = new HttpParams().set('ck', token);

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    // Convertir los datos a JSON
    const body = JSON.stringify(data);

    return this.http.post<any>(this.apiUrlComercialContacto + '/saveCtoEmpresa/',body,{params,headers});
  }

  public editContacto(data: ContactoEmpresa ):Observable<any>{
    const headers = { 'content-type': 'application/json'};
    const body = JSON.stringify(data); 
    return this.http.put<any>(this.apiUrlComercialContacto + '/editCtoEmpresa/',body,{'headers':headers});
   }

  public editClienteProspecto(data: ClientesProspecto ):Observable<any>{
    const headers = { 'content-type': 'application/json'};
    const body = JSON.stringify(data); 
    return this.http.put<any>(this.apiUrlComercial + '/editCtesProspectos/',body,{'headers':headers});
   }

   handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }
}