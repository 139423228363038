import { Component } from '@angular/core';

@Component({
  selector: 'app-importe-de-dias-para-utilidad',
  templateUrl: './importe-de-dias-para-utilidad.component.html',
  styleUrls: ['./importe-de-dias-para-utilidad.component.scss']
})
export class ImporteDeDiasParaUtilidadComponent {

}
