import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Table } from 'primeng/table';
import { HttpService } from 'src/app/shared/services/http.service';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { AuthService } from 'src/app/shared/services/auth.service';
import User from 'src/app/shared/models/user';
import  {IResultadoGeneral}  from 'src/app/shared/models/ResultadoGeneral';
import TipoCombustible from 'src/app/shared/models/TipoCombustible';
import { tipocombustibleService } from 'src/app/shared/services/tipocombustible.service';

@Component({
  selector: 'app-tipo-combustible',
  templateUrl: './tipo-combustible.component.html',
  styleUrls: ['./tipo-combustible.component.scss']
})
export class TipoCombustibleComponent implements OnInit{
  @ViewChild('dt1') dt!: Table;
  usuario:User={};
  tipocombustible:TipoCombustible[]=[];
  color_gris_deshabilitadas:string = '#D9D9D9';
  activityValues: number[] = [0, 100];
    loading: boolean = false;
    fecha_dia:any;
    numero_operacion:number=0;
    texto_operacion: string = '';

  tipoCombustibleForm: FormGroup= new FormGroup({
    nombreCombustible:new FormControl('',[]),
    id_tipoCombustible:new FormControl(0,[])
  })
  constructor(private TipoCombustibleService:tipocombustibleService,private message:MessageService, private auth:AuthService,
    private confirmationService: ConfirmationService){}

ngOnInit(): void {
  this.getListadoTipoCombustible();
}
getListadoTipoCombustible(){
  this.loading=true;
  this.TipoCombustibleService.getTipoCombustible().subscribe((resp)=>{
    this.tipocombustible=resp;
    setTimeout(() => {
      this.loading=false;
    }, 800);
  },
  (error)=>{
    this.loading=false;
    this.message.add({ severity: 'error', summary: 'Error en <Cargar Listados Medios de contacto>', detail: 'Contacte al Administrador del Sitio' });
  });
 }
 applyFilterGlobal($event: any, stringVal: any) {
  this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
}

clear(table: Table) {
  table.clear();
}
}
