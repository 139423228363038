<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="SI" rejectLabel="NO"
    rejectButtonStyleClass="p-button-text"></p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
    rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
    [rejectVisible]="false"></p-confirmDialog>
<div class="d-flex justify-content-center">
    <div class="container-fluid" style="width: 90%;">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="card card-shadow sinborde">
                    <div class="card-header sinborde" style="background: none; ">
                        <div class="cardicono rounded">
                            <i class="pi pi-share-alt mt-3" style="font-size: 2rem; color: #fff;"></i>
                        </div>
                        <header class="posicion" style="left: 11%; top: 0%;">Tipo de Combustible</header>
                    </div>
                    <div class="card-content">
                        <div class="row mt-3">
                            <p-table #dt1 [value]="tipocombustible" [tableStyle]="{'min-width': '50rem'}" styleClass="p-datatable-sm p-datatable-striped" 
                                dataKey="id" [rows]="10" 
                                [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]" [resizableColumns]="false"
                                [paginator]="true"  responsiveLayout="scroll" 
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [globalFilterFields]="['id','nombre']" [loading]="loading">
                                <ng-template pTemplate="caption">
                                    <div class="flex">
                                        <div class="col-md-3">
                                            <button pButton label="Clear" class="p-button-outlined"
                                            icon="pi pi-filter-slash" (click)="clear(dt1)" >
                                            </button>
                                        </div>
                                        <div class="col-md-3">
                                            <p-columnFilter field="nombre" matchMode="contains" [showMenu]="false">
                                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                    <p-dropdown [ngModel]="value" [options]="tipocombustible"
                                                        optionLabel="nombre" optionValue="id"
                                                        (onChange)="filter($event.value)"
                                                        placeholder="Filtrar por Nombre" [showClear]="true">
                                                    </p-dropdown>
                                                </ng-template>
                                            </p-columnFilter>
                                        </div>
                                        <div class="col-md-3">
                                            <span class="ml-auto">
                                                <i class="pi pi-search mover"></i>
                                                <input pInputText #filtroInput id="filtro" type="text"
                                                    (input)="applyFilterGlobal($event, 'contains')"
                                                    placeholder="Buscar Palabra" />
                                            </span>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="min-width:15rem">
                                            <div class="flex align-items-center">
                                                id tipo combustible
                                                <p-columnFilter type="number" field="id"
                                                    display="menu"></p-columnFilter>
                                            </div>
                                        </th>
                                        <th style="min-width:10rem">
                                            <div class="flex align-items-center">
                                                nombre tipo combustible
                                                <p-columnFilter type="text" field="nombre"
                                                    display="menu"></p-columnFilter>
                                            </div>
                                        </th>
                                        <th></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data let-index>
                                    <tr  [ngClass]="{'row-accessories': data.clv_activo ===0}">
                                        <td>
                                         <p class="ml-3">{{data.id}}</p>
                                        </td>
                                        <td>
                                            {{data.nombre}}
                                        </td>
                                        <td style="width: 130px;">
                                            <div class="d-flex justify-content-around">
                                                <button class="btn btn-info" pTooltip="Cambiar Estatus"
                                                    tooltipPosition="top">
                                                    <i class="pi pi-delete-left"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="7">Sin tipos de combustible.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            <div class="row mt-3 d-flex justify-content-center">
                                <div class="col-6">
                                    <div class="mt-3 mb-2">
                                        <p-colorPicker [(ngModel)]="color_gris_deshabilitadas"
                                            [disabled]="true"></p-colorPicker>
                                        <p class="text-left informativo" style="display: inline;">
                                            Tipos de combustible Inactivos</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>