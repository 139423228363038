import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Table } from 'primeng/table';
import { HttpService } from 'src/app/shared/services/http.service';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { AuthService } from 'src/app/shared/services/auth.service';
import User from 'src/app/shared/models/user';
import  {IResultadoGeneral}  from 'src/app/shared/models/ResultadoGeneral';
import TipoProyecto from 'src/app/shared/models/TipoProyecto';
import { tipoProyecto } from 'src/app/shared/services/tipoProyecto.service';
@Component({
  selector: 'app-tipo-proyecto',
  templateUrl: './tipo-proyecto.component.html',
  styleUrls: ['./tipo-proyecto.component.scss']
})
export class TipoProyectoComponent implements OnInit{
  @ViewChild('dt1') dt!: Table;
usuario:User={};
tipoproyecto:TipoProyecto[]=[];
color_gris_deshabilitadas:string = '#D9D9D9';
activityValues: number[] = [0, 100];
  loading: boolean = false;
  fecha_dia:any;
  numero_operacion:number=0;
  texto_operacion: string = '';



TipoProyectoForm:FormGroup= new FormGroup({
  id_TipoProyecto: new FormControl(0,[]),
  nom_TipoProyecto: new FormControl('',[]),
  des_TipoProyecto:new FormControl('',[])
})
constructor(private http:HttpService, private message:MessageService, private auth:AuthService,
  private confirmationService: ConfirmationService,private tipoproyectoservice:tipoProyecto){
}
ngOnInit(): void {
  this.getListadoProyectos();
}
getListadoProyectos(){
  this.loading=true;
  this.tipoproyectoservice.getProyectos().subscribe((resp)=>{
    this.tipoproyecto=resp;
    setTimeout(() => {
      this.loading=false;
    }, 800);
  },
  (error)=>{
    this.loading=false;
    this.message.add({ severity: 'error', summary: 'Error en <Cargar Listados Medios de contacto>', detail: 'Contacte al Administrador del Sitio' });
  });
 }
 applyFilterGlobal($event: any, stringVal: any) {
  this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
}

clear(table: Table) {
  table.clear();
}
}
