import { Component,OnInit, ViewEncapsulation } from '@angular/core';
import { Router,NavigationExtras} from '@angular/router';
import { MenuItem } from 'primeng/api';
import ICredencialUsuario from 'src/app/utils/models/User.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { Subscription } from 'rxjs';
import Module from 'src/app/utils/models/Module.model';
import { calendarioService } from 'src/app/shared/services/calendario.service';
import EventosCalendario from 'src/app/shared/models/EventosCalendario';
import { BadgeModule } from 'primeng/badge';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi, EventInput, EventDropArg, DateInput } from '@fullcalendar/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1.5s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('1.5s ease-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class MainComponent {
menuItems: MenuItem [] = [];
visible:boolean=false;
user: ICredencialUsuario = {};
displaySidebar: boolean = false;
userSubscriber?: Subscription;
blockedPanel:boolean=false;
numeroTareas:number=0;
badgeValue: string | number = 3;
catEventosCalendario:EventosCalendario[]=[];
displayDialog:boolean=false;
formGroups: { [key: string]: FormGroup } = {};
fechaYHora: string = '';

iconoSeleccionado: boolean = false;
objetonuevo:EventosCalendario[]=[];

constructor(private router: Router,private auth: AuthService, private http: HttpService,private calendarioServicio:calendarioService,
  private messageService:MessageService, private fb:FormBuilder){
  this.user.name = '';
  this.user.profession = '';
}

  /*ngOnInit(): void {
    this.mapPermissionsToMenuItems();
  }*/

  ngOnInit(): void {
    this.obtenerTareas();
    this.blockedPanel=true;
    if (this.auth.user) this.user = this.auth.user;
    if (!this.auth.user || !this.auth.user.permissions) {
      this.http.getPermissions().then((permissions: Module[]) => {
        this.auth.permissions = permissions;
        this.user = this.auth.user;
        this.mapPermissionsToMenuItems2(permissions);
      });
    }else{
      this.mapPermissionsToMenuItems2(this.auth.user.permissions);
    }
    this.userSubscriber = this.auth.$user.subscribe((user: any) => {
      this.user = user;
      if (!this.auth.user || !this.auth.user.permissions) {
        this.mapPermissionsToMenuItems2(this.auth.permissions);
      }
    });
  }

  mapPermissionsToMenuItems2(modules: Module[]){
    this.menuItems = modules.map(m =>{
      return {
        label:m.description,
        items:m.options.map(o =>{
          return {
            label:o.description,
            icon:`pi pi-fw pi-${o.icon}`,
            id:o.id,
            routerLink:o.route,
            command:()=>{
              this.handleNavigate(o.route,o.id);
            },
           // queryParams:{test:"hola"}
          } as MenuItem
        })
      } as MenuItem;
    });
  }

  onMouseEnter() {
    this.iconoSeleccionado = true;
  }

  onMouseLeave() {
    this.iconoSeleccionado = false;
  }

  public obtenerTareas(){
    this.calendarioServicio.getTareas().subscribe((tareas) => {
      this.catEventosCalendario=tareas;

         this.objetonuevo=this.catEventosCalendario.map((tarea)=> ({
          id:tarea.id,
          title:tarea.title,
          end:tarea.end,
          start:tarea.start,
          campoAgregado:tarea.start.toLocaleString().replace('T',' A las: ').substring(0,20),
          clv_status:tarea.clv_status,
          notas:tarea.notas,
          fec_fin:tarea.fec_fin,
          fec_inicio:tarea.fec_inicio,
          clv_automatico:tarea.clv_automatico,
          cod_usuario:tarea.cod_usuario,
          id_notificacion:tarea.id_notificacion,
          nom_empresa:tarea.nom_empresa
        }
    ))

      setTimeout(() => {
        this.numeroTareas=this.catEventosCalendario.length;
        this.GenerarNotificaciones();
      }, 800);

    });
  }

  handleNavigate(route:string,optionId: string) {
    this.tgSidebar();
    const extras: NavigationExtras = {
      state:{
        optionId
      }
    }
    this.router.navigate([route],extras);
  }

  showDialog() {
    this.displayDialog = true;
}

hideDialog() {
    this.displayDialog = false;
}

getForm(tareaId: string) {
    return this.formGroups[tareaId];
}

  tgSidebar(): void {
    this.displaySidebar = !this.displaySidebar;
  }

  stop(ev:any){
    ev.stopPropagation();
  }

  salir() {
    this.auth.clearCredentials();
    this.router.navigateByUrl("/login");
    /*this.menuItems=[];
    this.user.name = '';
    this.user.profession = '';*/
  }
  GenerarNotificaciones() {
    let ObjetoBusqueda: EventosCalendario[] = this.catEventosCalendario.map((campo) => ({
      id: campo.id,
      title: campo.title,
      end: campo.end,
      start: campo.start,
      id_notificacion: campo.id_notificacion,
      notas: campo.notas,
      clv_status: campo.clv_status
    }
    ));

    for (let i = 0; i < ObjetoBusqueda.length; i++) {
      let fechaInput: DateInput = ObjetoBusqueda[i].start;
      let fech=fechaInput.toLocaleString();
      fech=fech.replaceAll('-','');
      fech=fech.replaceAll(':','');
      fech=fech.replace('T','');
      let año=fech.substring(0,4);
      let mes=fech.substring(4,6);
      let dia=fech.substring(6,8);
      let hora=fech.substring(8,10);
      let minutos=fech.substring(10,12);
      let segundos=fech.substring(12,14);
      let date = new Date(parseInt(año),parseInt(mes)-1,parseInt(dia), parseInt(hora), parseInt(minutos), parseInt(segundos));

      const startDateTime = date;
      const now = new Date();
      const timeDifference = startDateTime.getTime() - now.getTime();
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hoursDifference = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutesDifference = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

      if (daysDifference <= 7 && ObjetoBusqueda[i].id_notificacion === 4) {
        this.showConfirm('Faltan ' + daysDifference + ' dias para tu tarea: ' + ObjetoBusqueda[i].title);
        let ObjetoEnviar: EventosCalendario = {
          id: ObjetoBusqueda[i].id,
          title: ObjetoBusqueda[i].title,
          end: ObjetoBusqueda[i].end,
          start: ObjetoBusqueda[i].start,
          id_notificacion: 5,
          notas: ObjetoBusqueda[i].notas,
          clv_status: ObjetoBusqueda[i].clv_status,
          fec_inicio: ObjetoBusqueda[i].fec_inicio,
          fec_fin: ObjetoBusqueda[i].fec_fin
        }
        this.calendarioServicio.editTareas(ObjetoEnviar).subscribe((resp) => {
          setTimeout(() => {
          }, 800);
        })
      } else if (daysDifference <= 1 && ObjetoBusqueda[i].id_notificacion === 3) {
        this.showConfirm('Falta ' + hoursDifference + ' hora(s) para tu tarea: ' + ObjetoBusqueda[i].title);
        let ObjetoEnviar: EventosCalendario = {
          id: ObjetoBusqueda[i].id,
          title: ObjetoBusqueda[i].title,
          end: ObjetoBusqueda[i].end,
          start: ObjetoBusqueda[i].start,
          id_notificacion: 5,
          notas: ObjetoBusqueda[i].notas,
          clv_status: ObjetoBusqueda[i].clv_status,
          fec_inicio: ObjetoBusqueda[i].fec_inicio,
          fec_fin: ObjetoBusqueda[i].fec_fin
        }
        this.calendarioServicio.editTareas(ObjetoEnviar).subscribe((resp) => {
          setTimeout(() => {
          }, 800);
        })
      } else if (hoursDifference <= 1 && ObjetoBusqueda[i].id_notificacion === 2) {
        this.showConfirm('Falta ' + minutesDifference + ' minutos para tu tarea: ' + ObjetoBusqueda[i].title);
        let ObjetoEnviar: EventosCalendario = {
          id: ObjetoBusqueda[i].id,
          title: ObjetoBusqueda[i].title,
          end: ObjetoBusqueda[i].end,
          start: ObjetoBusqueda[i].start,
          id_notificacion: 5,
          notas: ObjetoBusqueda[i].notas,
          clv_status: ObjetoBusqueda[i].clv_status,
          fec_inicio: ObjetoBusqueda[i].fec_inicio,
          fec_fin: ObjetoBusqueda[i].fec_fin
        }
        this.calendarioServicio.editTareas(ObjetoEnviar).subscribe((resp) => {
          setTimeout(() => {
          }, 800);
        })
      } else if (minutesDifference <= 30 && ObjetoBusqueda[i].id_notificacion === 1) {
        this.showConfirm('Faltan ' + minutesDifference + ' minutos para tu tarea: ' + ObjetoBusqueda[i].title);
        let ObjetoEnviar: EventosCalendario = {
          id: ObjetoBusqueda[i].id,
          title: ObjetoBusqueda[i].title,
          end: ObjetoBusqueda[i].end,
          start: ObjetoBusqueda[i].start,
          id_notificacion: 5,
          notas: ObjetoBusqueda[i].notas,
          clv_status: ObjetoBusqueda[i].clv_status,
          fec_inicio: ObjetoBusqueda[i].fec_inicio,
          fec_fin: ObjetoBusqueda[i].fec_fin
        }
        this.calendarioServicio.editTareas(ObjetoEnviar).subscribe((resp) => {
          setTimeout(() => {
          }, 800);
        })
      } else if (minutesDifference === 0 && ObjetoBusqueda[i].id_notificacion === 0) {
        this.showConfirm('Es Hora de comenzar tu tarea: ' + ObjetoBusqueda[i].title);
        let ObjetoEnviar: EventosCalendario = {
          id: ObjetoBusqueda[i].id,
          title: ObjetoBusqueda[i].title,
          end: ObjetoBusqueda[i].end,
          start: ObjetoBusqueda[i].start,
          id_notificacion: 5,
          notas: ObjetoBusqueda[i].notas,
          clv_status: ObjetoBusqueda[i].clv_status,
          fec_inicio: ObjetoBusqueda[i].fec_inicio,
          fec_fin: ObjetoBusqueda[i].fec_fin
        }
        this.calendarioServicio.editTareas(ObjetoEnviar).subscribe((resp) => {
          setTimeout(() => {
          }, 800);
        })
      }
    }
  }
  showConfirm(mensaje: string) {
    if (!this.visible) {
      this.messageService.add({ key: 'confirm', sticky: true, severity: 'success', summary: '' + mensaje + '' });
      this.visible = true;
    }
  }

  onConfirm() {
    this.messageService.clear('confirm');
    this.visible = false;

  }

  public recargarPagina(): void {
    location.reload(); 
  }

  onReject() {
    this.messageService.clear('confirm');
    this.visible = false;
  }
}
