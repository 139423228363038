import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CAPTCHA_ERR, EMAIL_ERR, PASSWORDS_ERR } from 'src/app/shared/utils/error-msjs';
import { ConfirmationService, Message, MessageService,ConfirmEventType } from 'primeng/api';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PrimeNGConfig } from 'primeng/api';
import { HttpService } from 'src/app/shared/services/http.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent  implements OnInit{
  loading:boolean = false;
  form:FormGroup;
  showPassword: boolean = false;
  Password: string = '';
  anio: number = new Date().getFullYear();

  //validaciones RXJS
  REGEX_NAMES = /^[a-zA-Z\s]{2,50}$/;
  REGEX_PASSWORD = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{7,15}$/;

  //Mensajes para los escenarios
  error_contrasenia = PASSWORDS_ERR;
  error_email = EMAIL_ERR;
  error_captcha = CAPTCHA_ERR;

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private confirmationService: ConfirmationService,
    private loginService: HttpService) {
      this.form = this.formBuilder.group({
        UserName: ['', [Validators.required, Validators.pattern(this.REGEX_NAMES)]],
        Password: ['', [Validators.required,Validators.pattern(this.REGEX_PASSWORD)]],
        Cookie:['']
      });
     }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }

  getErrors(messages:any,name:string):string{
    let errors = this.form.controls[name].errors;
    if (!errors) return '';
    return messages[Object.keys(errors)[0]];
  }

  inicioSesionUsuario(){
    if(this.form.valid){
      this.loading = true;

      if(this.form.value['Cookie']!='')
      {
        this.loginService.login(this.form.value('Cookie')).then(res =>{
          this.loading = false;
          if(res){
            this.router.navigateByUrl("/");
          } else {
            this.loading = false;
            this.mostrarMensajeInicioErroneo();
            this.reiniciarFormulario();
          }
      })}
      else{
        this.loginService.loginWithEmailNPassword(this.form.value).then(res =>{
          this.loading = false;
          if(res){
            this.router.navigateByUrl("/");
          } else {
            this.loading = false;
            this.mostrarMensajeInicioErroneo();
            this.reiniciarFormulario();
          }
        });
      }
      
    }
    else{
      this.loading = false;
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
    }
  }
  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  reiniciarFormulario(){
    this.form.reset();
    this.form.controls['Cookie'].setValue('');
  }

  mostrarMensajeInicioErroneo(){
    this.confirmationService.confirm({
      message: 'Usuario y/o Contraseña invalidos. Revise información. ',
      header: 'Inicio de sesion Invalido',
      icon: 'pi pi-info-circle',
      accept: () => {
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });
  }

  onCaptchaResponse(ev:any){
    if(ev && ev.response)
      this.form.controls['captchaToken'].setValue(ev.response);
  }


}
