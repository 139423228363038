import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { retry, take,catchError  } from 'rxjs/operators';
import {throwError} from 'rxjs'
import { NavigationExtras, Router } from "@angular/router";

//primeng services
import { MessageService } from 'primeng/api';
//own services
import { AuthService } from 'src/app/shared/services/auth.service';
//models
import { environment } from 'src/environments/environment';
import { Observable,BehaviorSubject  } from 'rxjs';
import ITabuladorCostos from '../models/TabuladorCostos';
import { IEstatusPropuesta } from '../models/EstatusPropuesta';

@Injectable({
    providedIn: 'root'
  })
  export class tabuladorCostosService{
    private apiUrl: string = "";
    private usuariosSubject = new BehaviorSubject<any[]>([]);
    private apiUrlComercialTabuladorCostos:string="";
    private apiUrlComercialTabuladorCostosSaveTabulador:string="";
    private apiUrlComercialTabuladorGetTabuladorPrincipal:string="";
    private apiUrlComercialTabuladorGetMunicipios:string="";
    private apiUrlComercialTabuladorGetMunicipiosFiltrados:string="";
    private apiUrlComercialTabuladorModificarEstatus:string="";
    private apiUrlComercialTabuladorGetEstatusPropuesta:string="";
    private apiUrlComercialTabuladorUpdatePropuestaComercial:string="";
    private apiUrlComercialObtenerOracionesJornada:string="";
    private apiUrlComercialObtenerPeriodoFacturacion:string="";
    private apiUrlComercialGuardarFormularioCotizacion:string="";
    private apiUrlComercialGetCotizacion:string="";
    private apiUrlComercialGetPdfCotizacion:string="";
    private apiUrlComercialGetEditarTabulador:string="";
    private apiUrlComercialGetTabuladorEdit:string="";
    private apiUrlComercialUpdateTabulado:string="";

    constructor(private http: HttpClient, private auth: AuthService, private message: MessageService, private router: Router) {
      this.apiUrl = environment.sUrlAuth + 'Auth';
      this.apiUrlComercialTabuladorCostos=environment.SUrlgApi+'BriefComercial/getTabulador';
      this.apiUrlComercialTabuladorCostosSaveTabulador=environment.SUrlgApi+'BriefComercial/saveTabulador';
      this.apiUrlComercialTabuladorGetTabuladorPrincipal=environment.SUrlgApi+'BriefComercial/getTabuladorFinal';
      this.apiUrlComercialTabuladorGetMunicipios=environment.SUrlgApi+'EstadosMunicipios/getmunicipios';
      this.apiUrlComercialTabuladorGetMunicipiosFiltrados=environment.SUrlgApi+'EstadosMunicipios/getfiltromunicipio';
      this.apiUrlComercialTabuladorModificarEstatus=environment.SUrlgApi+'BriefComercial/updatetabulador',
      this.apiUrlComercialTabuladorGetEstatusPropuesta=environment.SUrlgApi+'BriefComercial/getPropuestaEstatus',
      this.apiUrlComercialTabuladorUpdatePropuestaComercial=environment.SUrlgApi+'BriefComercial/updatePropuestaComercial',
      this.apiUrlComercialObtenerOracionesJornada=environment.SUrlgApi+'TabuladorCliente/getdiasoperacion';
      this.apiUrlComercialObtenerPeriodoFacturacion=environment.SUrlgApi+'TabuladorCliente/getperiodofacturacion';
      this.apiUrlComercialGuardarFormularioCotizacion=environment.SUrlgApi+'CotizacionCte/saveCotizacion';
      this.apiUrlComercialGetCotizacion=environment.SUrlgApi+'CotizacionCte/getCotizacion';
      this.apiUrlComercialGetPdfCotizacion=environment.SUrlgApi+'CotizacionCte/generarReporte';
      this.apiUrlComercialGetEditarTabulador=environment.SUrlgApi+'TabuladorCliente/getedittabulador';
      this.apiUrlComercialGetTabuladorEdit=environment.SUrlgApi+'BriefComercial/getTabuladorEdit';
      this.apiUrlComercialUpdateTabulado=environment.SUrlgApi+'TabuladorCliente/updateTabulador';
     }
     
     UpdateTabulador(data:any):Observable<any[]>{
      const headers = new HttpHeaders().set('Content-Type', 'application/json');
      return this.http.put<any[]>(this.apiUrlComercialUpdateTabulado,data,{headers});
     }

     GetTabuladorEdit(data:any):Observable<any[]>{
      const headers = new HttpHeaders().set('Content-Type', 'application/json');
      return this.http.post<any[]>(this.apiUrlComercialGetTabuladorEdit,data,{headers});
     }

     getEditTabulador(idxs:number):Observable<any[]>{
      let params={
        idx:idxs
      }
      return this.http.get<any[]>(this.apiUrlComercialGetEditarTabulador,{params})
     }

     GenerateCotizacionPDF(idxs:number):Observable<any>{
      let params={
        idx:idxs
      }
      return this.http.get(this.apiUrlComercialGetPdfCotizacion, {
        responseType: 'blob',
        params
      }).pipe(
        catchError(this.handleError)
      );
     }

     GetCotizacion(ids:number):Observable<any[]>{
      let params={
        idx:ids
      }
      return this.http.get<any[]>(this.apiUrlComercialGetCotizacion,{params}).pipe(retry(1), catchError(this.handleError));
     }

     saveFormularioCotizacion(data:any):Observable<any>{
      
      return this.http.post<any>(this.apiUrlComercialGuardarFormularioCotizacion,data).pipe(
        retry(1),
        catchError(this.handleError)
      );
     }

     getOracionesJornada():Observable<any[]>{
      return this.http.get<any[]>(this.apiUrlComercialObtenerOracionesJornada).pipe(
        retry(1),
        catchError(this.handleError)
      );
     }

     getPeriodoFacturacion():Observable<any[]>{
      return this.http.get<any[]>(this.apiUrlComercialObtenerPeriodoFacturacion).pipe(
        retry(1),
        catchError(this.handleError)
      );
     }

     getMunicipios():Observable<any>{
      return this.http.get<any>(this.apiUrlComercialTabuladorGetMunicipios).pipe(
        retry(1),
        catchError(this.handleError)
      );
     }

     getPropuestaEstatus():Observable<IEstatusPropuesta[]>{
      return this.http.get<IEstatusPropuesta[]>(this.apiUrlComercialTabuladorGetEstatusPropuesta).pipe(retry(1),
      catchError(this.handleError));
     }

     updatePropuestaComercial(data:any):Observable<any>{
      return this.http.put<any>(this.apiUrlComercialTabuladorUpdatePropuestaComercial,data);
     }

     updateEstatus(data:any):Observable<any>{
      const token=this.auth.token;
      const params = new HttpParams().set('ck', token);
      return this.http.put<any>(this.apiUrlComercialTabuladorModificarEstatus,data,{ params });
     }

    saveTabulador(data:any):Observable<any>{
      const token=this.auth.token;
      const headers = new HttpHeaders().set('Content-Type', 'application/json');

      // Convertir los datos a JSON
      const body = JSON.stringify(data);
      return this.http.post<any>(this.apiUrlComercialTabuladorCostosSaveTabulador,body, { headers });
    }

    getMunicipiosFiltrados(id_muni:number):Observable<any>{
      let params={
        id_municipio:id_muni
      }
      return this.http.get<any>(this.apiUrlComercialTabuladorGetMunicipiosFiltrados,{params}).pipe(
        retry(1),
        catchError(this.handleError)
      );
    }

    getTabuladorPrincipal(id:number):Observable<ITabuladorCostos[]>{
      let params={
        id_cte_prospecto:id
      }
      return this.http.get<ITabuladorCostos[]>(this.apiUrlComercialTabuladorGetTabuladorPrincipal,{params});
    }
  
    getTabulador(data:any): Observable<ITabuladorCostos[]> {
      const headers = new HttpHeaders().set('Content-Type', 'application/json');
      let params={
        unidadCosto: data.unidadCosto,
          id_tiporuta:data.id_tiporuta,
          des_origen: data.des_origen,
          des_destino:data.des_destino,
          num_kilometros:data.num_kilometros,
          imp_peaje:data.imp_peaje,
          horas_cargaydescarga:data.horas_cargaydescarga,
          num_choferes:data.num_choferes,
          num_auxiliares:data.num_auxiliares,
          num_porcentajeutd:data.num_porcentajeutd,
          id_tipo_cliente:data.id_tipo_cliente,
          imp_terceros:data.imp_terceros
      }
      return this.http.post<ITabuladorCostos[]>(this.apiUrlComercialTabuladorCostos,params,{headers})
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
    }

     handleError(error:any) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage = `Error: ${error.error.message}`;
      } else {
        // server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      console.log(errorMessage);
      return throwError(() => {
          return errorMessage;
      });
    }
  }