<p-toast [style]="{marginTop: '80px'}" position="top-right" key="msj"></p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<div class="root-login">
        <div class="form-card shadow-6 ajustar">
            <div class="col-md-12 col-lg-12 col-xl-9 offset-xl-1">
                <form [formGroup]="form" *ngIf="form">
                  <div class="divider d-flex align-items-center my-4">
                    <p class="text-center fw-bold mx-3 mb-0">Ingrese Al sistema Utilizando Sus Credenciales</p>
                  </div>
                  <div class="form-outline mb-4">
                    <i class="pi pi-spin pi-spinner" *ngIf="loading"></i>
                    <div class="input-group">
                    <input type="user" id="form3Example3" formControlName="UserName" class="form-control form-control-lg"
                      placeholder="Ingresar Usuario" pTooltip="Ingrese Usuario" (keyup.enter)="inicioSesionUsuario()"/>
                      <div class="input-group-append agrandar">
                        <div class="input-group-text agrandar">
                            <span class="fas fa-user"></span>
                        </div>
                    </div>
                      </div>
                      <small class="p-error p-invalid"
                      [ngClass]="{'err-msg-active':form.controls['UserName'].invalid && form.controls['UserName'].touched}">
                      {{getErrors(error_email,'UserName')}}
                      </small>
                  </div>
                  <div class="form-outline mb-3">
                    <div class="input-group">
                      <input type="{{ showPassword ? 'text' : 'Password' }}" formControlName="Password" [(ngModel)]="Password" pTooltip="Ingrese Contraseña" id="Password" class="form-control form-control-lg"
                        placeholder="Ingresar Contraseña" (keyup.enter)="inicioSesionUsuario()" name="Password" />
                      <div class="input-group-append">
                        <div class="input-group-text agrandar">
                          <span class="fas fa-lock agrandar"></span>
                        </div>
                      </div>
                    </div>
                    <div class="input-group No-border">
                      <div class="input-group-text">
                        <input type="checkbox" (click)="togglePasswordVisibility()" />
                         Mostrar contraseña
                      </div>
                    </div>
                      <small class="p-error p-invalid"
                    [ngClass]="{'err-msg-active':form.controls['Password'].invalid && form.controls['Password'].touched}">
                    {{getErrors(error_contrasenia,'Password')}}
                      </small>
                  </div>
                  <small class="captchaErrorMessage"
                  [ngClass]="{'err-msg-active':form.controls['Cookie'].invalid && form.controls['Cookie'].touched}">
                  {{getErrors(error_captcha,'Cookie')}}
                 </small>
                  <div class="text-center text-lg-start mt-4 pt-2">
                    <button type="button" class="btn button-9 btn-sm"
                      style="padding-left: 2.5rem; padding-right: 2.5rem;" (click)="inicioSesionUsuario()"><h2>Ingresar</h2></button>
                  </div>
                </form>
            </div>
        </div>
</div>
<footer>
  <div
  class="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5" style="background-color:#3161C4;">
  <!-- Copyright -->
  <div class="text-white mb-3 mb-md-0">
    Copyright ©{{anio}}. Todos los derechos reservados.
  </div>
  <!-- Copyright -->
  <!-- Right -->
  <div>
    <a href="#!" class="text-white me-4">
      <i class="fab fa-facebook-f"></i>
    </a>
    <a href="#!" class="text-white me-4">
      <i class="fab fa-twitter"></i>
    </a>
    <a href="#!" class="text-white me-4">
      <i class="fab fa-google"></i>
    </a>
    <a href="#!" class="text-white">
      <i class="fab fa-linkedin-in"></i>
    </a>
  </div>
  <!-- Right -->
</div>
</footer>