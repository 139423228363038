import { Component, ElementRef, ViewChild } from '@angular/core';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-pdf-brief',
  templateUrl: './pdf-brief.component.html',
  styleUrls: ['./pdf-brief.component.scss']
})
export class PdfBriefComponent {
  logoUrl: string = '../../../assets/images/logo_centrado.png'; // Reemplaza esto con la URL de tu logo
  clienteValue: string = 'Nombre del Cliente'; // Valor dinámico del cliente obtenido del backend
  ventaEstimadaValue: string = '10000'; // Valor dinámico de la venta estimada obtenido del backend
  fechaActual: string = new Date().toLocaleDateString();
  @ViewChild('pdfContent', { static: false }) pdfContent!: ElementRef;

  generatePDF() {
    const doc = new jsPDF.default;

    // Logo
    const logo = new Image();
    logo.src = this.logoUrl;
    doc.addImage(logo, 'PNG', 65, 3, 80, 20);

    // Header con fondo azul claro
    doc.setFillColor(173, 216, 230); // Azul claro (RGB)
    doc.rect(8, 25, doc.internal.pageSize.getWidth()-15, 15, 'F'); // Rectángulo con el ancho del documento y 20 de altura
    doc.setFontSize(20);
    doc.setTextColor(255, 255, 255); 
    doc.text('HAPPY BRIEF', 79, 35);

    //campo 1 columna 1
    doc.setFillColor(9,66, 147); // Azul claro (RGB)
    doc.rect(8, 48, 30, 8, 'F');
    doc.setFontSize(14);
    doc.setFont('Helvetica');
    doc.setTextColor(255, 255, 255); // Restaurar el color del texto a negro
    doc.text('Cliente', 15, 53);
    doc.setFillColor(240, 240, 240); // Azul claro (RGB)
    doc.rect(38, 48, 43, 8, 'F');
    doc.setTextColor(0);
    doc.text(this.clienteValue, 41, 54);

    //campo 1 columna 2
    doc.text('Venta Estimada:', 15, 150);
    doc.text('$' + this.ventaEstimadaValue, 85, 150);
    doc.text('Fecha:', 15, 170);
    doc.text(this.fechaActual, 45, 170);

    doc.save('pdf_generated.pdf');
  }

generatePDFS() {
    const content = document.getElementById('contentToConvert');

    if (!content) {
        console.error('No se encontró el contenido a convertir.');
        return;
    }

    html2canvas(content).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF.default('p', 'pt', 'a4');

        // Ajustes de margen
        const marginLeft = 0.7 * 72; // 0.7 pulgadas en puntos
        const marginTop = 0.75 * 72; // 0.75 pulgadas en puntos
        const marginRight = 0.7 * 72;
        const marginBottom = 0.75 * 72;
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        const imgWidth = pdfWidth - marginLeft - marginRight;
        const imgHeight = canvas.height * imgWidth / canvas.width;

        pdf.addImage(imgData, 'PNG', marginLeft, marginTop, imgWidth, imgHeight);

        pdf.save('report.pdf');
    });
}
}