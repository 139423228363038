import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Table } from 'primeng/table';
import Clasificacion from 'src/app/shared/models/Clasificacion';
import { HttpService } from 'src/app/shared/services/http.service';
import { Message,MessageService,ConfirmationService,ConfirmEventType } from 'primeng/api';
import User from 'src/app/shared/models/user';
import { IResultadoGeneral } from 'src/app/shared/models/IResultadoGeneral';
import { clasificacion } from 'src/app/shared/services/clasificacion.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { trigger, style, transition, animate } from '@angular/animations'
import { Estatus } from 'src/app/shared/models/Estatus';

@Component({
  selector: 'app-clasificacion-de-clientes',
  templateUrl: './clasificacion-de-clientes.component.html',
  styleUrls: ['./clasificacion-de-clientes.component.scss'],
  animations: [
    trigger('nuevoRegistro', [
      transition(':enter', [
        style({ transform: 'translateY(20px)', opacity: 0 }),
        animate('500ms', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
  ],
})
export class ClasificacionDeClientesComponent implements OnInit {
  nuevoRegistroAnimation = 'nuevoRegistro';

  usuario:User={};
  color_gris_deshabilitadas:string = '#D9D9D9';
  clasificacion: Clasificacion[]=[];
  data_editar_clasificacion:Clasificacion={
    id_clasificacion_de_cte:0,
    opcion:0,
    cod_usuario:'',
    clv_activo:0,
    nom_clasificacion:'',
    des_clasificacion:''
  }

  objEstatus:Estatus[]=[{
    clv_activo:0,
    des_estatus:'Desactivado'
  },
  {
    clv_activo:1,
    des_estatus:'Activo'
  }]
  fecha_dia:any;
  numero_operacion:number=0;
  texto_operacion: string = '';

  @ViewChild('dt1') dt!: Table;
  dspClasificacion:boolean=false;
  activityValues: number[] = [0, 100];
  loading: boolean = true;

  clasificacionForm: FormGroup = new FormGroup({
    nombreClasificacion: new FormControl('', [ 
      Validators.required
    ]),
    descripcion: new FormControl('', [ 
      Validators.required
    ]),
    clv_activo: new FormControl(0, []),
    id_clasificacion_de_cte: new FormControl(0,[])
  });

  
  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };


  constructor(private httpService: HttpService,private message:MessageService, private auth:AuthService,
    private confirmationService: ConfirmationService,private ClasificacionService:clasificacion) {
      this.cargarInformacionUsuario();
    }
  ngOnInit(): void {
    this.getListadoClasificacion();
    this.inicializarFechas();
  }

  redireccionarNueva(num_operacion:number) {
    this.numero_operacion=num_operacion;
    this.texto_operacion = '';
    this.clasificacionForm.reset();
    this.resultado = {
      clv_estatus: 0,
      messageDetails: ''
    };
    this.texto_operacion = 'Nueva clasificacion';
    //
    //
    this.dspClasificacion = true;
}

  getListadoClasificacion(){
    this.loading=true;
    this.ClasificacionService.getClasificacion().subscribe((resp)=>{
      this.clasificacion=resp;
      setTimeout(() => {
        this.loading=false;
      }, 1000);
    })
   }

  cargarInformacionUsuario(){
    this.usuario = this.auth.user;
  }

  confirmarDesactivar(data: Clasificacion) {
    let estatus_original= data.clv_activo;
    let texto_desactivar: string = data.clv_activo == 1 ? 'DESACTIVAR' : 'ACTIVAR';
    let texto_desactivar_header: string = data.clv_activo == 1 ? 'Desactivar' : 'Activar';
    this.confirmationService.confirm({
      message: 'Esta seguro que desea <b>' + texto_desactivar + '</b> el medio de contacto <b>'+data.nom_clasificacion+' '+data.des_clasificacion+'</b>?',
      header: 'Confirmacion de ' + texto_desactivar_header,
      icon: 'pi pi-info-circle',
      accept: () => {
        data.opcion=3,
        data.cod_usuario=this.usuario.cod;
        data.clv_activo = data.clv_activo == 1 ? 0 : 1;
        this.guardarInformacionDesactivar(data);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            data.clv_activo=estatus_original;
            this.message.add({ key: 'msj', severity: 'error', summary: 'Rechazado', detail: 'Se ha Rechazado' });
            break;
          case ConfirmEventType.CANCEL:
            data.clv_activo=estatus_original;
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Se ha Cancelado' });
            break;
        }
      }
    });
  }

  guardarInformacionDesactivar(data: Clasificacion) {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    //data.cod_usuario_ult_mov=this.usuario.cod==null? '0999': this.usuario.cod;
    this.ClasificacionService.editClasificacion(data).subscribe((resp)=> {
      var resultado=resp;
      if(resultado.clv_estatus==1){
        setTimeout(() => {
          this.message.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
          this.clasificacionForm.reset();
          this.cancelarVentanaModal();
        }, 800);
      }
    },
    (error)=> {
      this.message.add({ severity: 'error', summary: 'Error en <Editar Chofer>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  inicializarFechas(){
    var getYear = new Date().toLocaleDateString('es-MX',{ year: 'numeric'});   
    var getMonth = new Date().toLocaleDateString('es-MX',{ month: '2-digit'});
    var getDay = new Date().toLocaleDateString('es-MX',{day: '2-digit'});
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia=dateFormat;
    //Formatear fecha de Inicio
  }
  cancelarVentanaModal() {
    this.texto_operacion = '';
    //this.num_operacion=0;
    this.dspClasificacion = false;
    this.clasificacionForm.reset();
    this.resultado = {
      clv_estatus: 0,
      messageDetails: ''
    };
  }

  mostrarModalEditar(data: Clasificacion,num_operacion:number) {
    this.data_editar_clasificacion=data;
    this.numero_operacion=num_operacion;

    this.clasificacionForm.controls['nombreClasificacion'].setValue(data.nom_clasificacion);
    this.clasificacionForm.controls['descripcion'].setValue(data.des_clasificacion);
    this.clasificacionForm.controls['id_clasificacion_de_cte'].setValue(data.id_clasificacion_de_cte);
    this.clasificacionForm.controls['clv_activo'].setValue(data.clv_activo);
    //this.mediosDeContactoForm.controls['clv_activo'].setValue(data.clv_activo);
    //
    //
    //
    this.dspClasificacion = true;
    this.texto_operacion = 'Editar Clasificacion';
  }

  guardarInformacion(){
    this.inicializarFechas();
    let data_enviar: Clasificacion={
      id_clasificacion_de_cte:this.numero_operacion==1? 0 : this.clasificacionForm.get('id_clasificacion_de_cte')?.value,
      cod_usuario: this.usuario.cod,
      clv_activo: typeof this.clasificacionForm.get('clv_activo')?.value === 'object' ? 1 : this.clasificacionForm.get('clv_activo')?.value,
      nom_clasificacion:this.clasificacionForm.get('nombreClasificacion')?.value,
      des_clasificacion:this.clasificacionForm.get('descripcion')?.value.toUpperCase(),
      opcion:this.numero_operacion
    };
    if(this.numero_operacion==1){
      //data_enviar.cod_usuario_ult_mov=this.usuario.cod;
      this.ClasificacionService.SaveClasificacion(data_enviar).subscribe((resp)=>{
        var resultado=resp;
        setTimeout(()=>{
          this.clasificacion.push(data_enviar);
          this.getListadoClasificacion();
          this.message.add({ severity: 'success', summary: 'Medio de contacto Exitoso', detail: 'La informacion se almaceno de forma correcta' });
          this.clasificacionForm.reset();
          this.cancelarVentanaModal();
        },800);
      },
      (error)=> {
        this.message.add({ severity: 'error', summary: 'Error en <Agregar Medio de contacto>', detail: 'Contacte al Administrador del Sitio' });
      });
    } else
    if(this.numero_operacion==2){
      this.ClasificacionService.editClasificacion(data_enviar).subscribe((resp)=>{
        var resultado=resp;
        setTimeout(() => {
          this.getListadoClasificacion();
          this.message.add({severity:'success',summary:'Edicion Exitosa',detail:'la informacion se edito correctamente'});
          this.clasificacionForm.reset();
          this.cancelarVentanaModal();
        }, 800);
      })
    }
    
  }
  
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }
  clear(table: Table) {
    table.clear();
}
}
