import { Component } from '@angular/core';

@Component({
  selector: 'app-importe-de-diesel',
  templateUrl: './importe-de-diesel.component.html',
  styleUrls: ['./importe-de-diesel.component.scss']
})
export class ImporteDeDieselComponent {

}
