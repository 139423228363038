<!--<p-blockUI [target]="pnl" [blocked]="blockedPanel">
    <i class="pi pi-lock" style="font-size: 3rem"></i>
</p-blockUI>-->
<p-toast position="bottom-center" [style]="{width: '20vw'}" key="confirm" (onClose)="onReject()" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="flex flex-column align-items-start" style="flex: 1">
            <div class="flex align-items-center gap-2">
                <p-avatar image="../../../assets/images/therock.jpg" size="xlarge" shape="circle"
                    class="avatar-grande"></p-avatar>
                <span class="font-bold text-900">Rockordatorio</span>
            </div>
            <div class="font-medium text-lg my-3 text-900">{{ message.summary }}</div>
            <p-button class="p-button-sm" label="Aceptar" (click)="onConfirm()"></p-button>
        </div>
    </ng-template>
</p-toast>

<p-sidebar [(visible)]="displaySidebar" styleClass="side-bar-menu">
    <div class="logo mt-2 ml-2 mb-3">
        <img src="/../../assets/images/hm_logo_header-trans.svg" alt="LogoHM">
    </div>
    <hr class="style-two">
    <div class="sidebar-profile-block">
        <div class="profile-image">
            <img src="/../../assets/images/usuario.png" alt="usuario">
        </div>
        <div class="profile-info">
            <div class="info-name t-subtitle2 tc-w">{{user.name}}</div>
        </div>
    </div>
    <hr class="style-two">
    <p-menu [model]="menuItems" styleClass="menu" (click)="stop($event)">
    </p-menu>
</p-sidebar>


<div class="nav-bar p-shadow-4 pd-flex bgc-main">
    <div class="nav-bar-inside-left">
        <p-button icon="pi pi-bars"
            styleClass="p-button-rounded p-button-text p-button-white nav-bar-toggle-button text-white"
            (click)="tgSidebar()"></p-button>
        <div class="nav-bar-title t-h5 tc-w text-white">
            <div class="logo mt-2 ml-2">
                <img src="../../../assets/images/hm_logo_header-trans.svg" [routerLink]="['/']" alt="LogoHM"
                    class="enlace"><a></a>
            </div>
        </div>
    </div>
    <div class="nav-bar-inside-right">
        <div class="profile-block">
            <div class="profile-image"></div>
            <div class="profile-info">
                <div class="info-name t-subtitle2 tc-w">{{user.name}}</div>
            </div>
        </div>
        <div>
            <i class="pi pi-bell text-3xl" style="margin-right: 30px; color: #FFFFFF; cursor: pointer;" pBadge
                value='{{numeroTareas}}' pTooltip="Notificaciones" tooltipPosition="left" (mouseenter)="onMouseEnter()"
                (mouseleave)="onMouseLeave()" (click)="showDialog()">
            </i>
        </div>
        <p-button icon="pi pi-sign-out" styleClass="p-button-rounded p-button-text p-button-white text-white"
            pTooltip="Cerrar sesión" tooltipPosition="left" (click)="salir()"></p-button>
    </div>
</div>
<p-dialog header="Notificaciones Activas" [(visible)]="displayDialog" [modal]="true" [style]="{'width': '35vw','header':'blue','border-radius':'8px;' }"
[draggable]="false" [resizable]="false" styleClass="blue-dialog">
<ng-template pTemplate="header">
    <span class="text-white" style="font-size: 2rem;">
      <i class="pi pi-info-circle text-white" style="font-size: 2rem;"></i> 
      Notificaciones Activas : {{numeroTareas}}
    </span>
</ng-template>
<ng-template pTemplate="content">
    <div class="whatsapp-message-container" *ngFor="let mensaje of objetonuevo" [@fadeInOut]>
        <div class="whatsapp-avatar">
            <!--img src="avatar.jpg" alt="Avatar"-->
            <i class="pi pi-whatsapp mt-3" style="color: rgb(90, 170, 90); font-size: 2rem !important;"></i>
        </div>
        <div class="whatsapp-message-content">
            <div class="whatsapp-message-bubble">
                <ng-container *ngIf="objetonuevo.length > 0;">
                    <div *ngIf="mensaje.nom_empresa===''">
                        <p class="whatsapp-message-text" ><h5><b>Tarea: </b></h5><h6>{{ mensaje.title }}</h6>
                            <hr class="my-0">
                    </div>
                    <div *ngIf="mensaje.nom_empresa!==''">
                        <p class="whatsapp-message-text">
                            <h5><b>Tarea: </b></h5><h5><b>{{mensaje.title}}</b> del Cliente <b>{{mensaje.nom_empresa}}</b></h5>
                        <hr class="my-0">
                    </div>
                    <p class="whatsapp-message-text"><h5><b>Fecha: </b></h5><h6>{{mensaje.campoAgregado}}</h6>
                      <hr class="my-0">
                  </ng-container>
            </div>
        </div>
    </div>
    <!--div class="mensaje-container" *ngFor="let mensaje of objetonuevo">
        <div class="mensaje-icon">
          <i class="pi pi-whatsapp mt-3" style="color: rgb(56, 137, 56); font-size: 2rem !important;"></i>
        </div>
        <div class="mensaje-content">
          <ng-container *ngIf="objetonuevo.length > 0; else noMensajes">
            <div class="mensaje">
              <h5>Tarea: </h5>{{ mensaje.title }}
              <hr class="my-0">
              <h5>Fecha: </h5>{{mensaje.campoAgregado}}
              <hr class="my-0">
            </div>
          </ng-container>
          <ng-template #noMensajes>
            <div class="no-mensaje">
              No hay mensajes disponibles.
            </div>
          </ng-template>
        </div>
      </!--div-->
</ng-template>
<ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="hideDialog()" label="Cerrar" pAutoFocus [autofocus]="true"></p-button>
</ng-template>
</p-dialog >

<!--p-dialog header="Notificaciones Activas" [(visible)]="displayDialog" [modal]="true" [style]="{'width': '45vw','header':'blue','border-radius':'8px;' }"
    [draggable]="false" [resizable]="false" styleClass="blue-dialog">
    <ng-template pTemplate="header">
        <span class="text-white" style="font-size: 2rem;">
          <i class="pi pi-info-circle text-white" style="font-size: 2rem;"></i> 
          Notificaciones Activas : {{numeroTareas}}
        </span>
      </ng-template>
    <div class="p-6">
        <ng-container style="margin-top: 10px; border-radius: 100px;" *ngFor=" let tarea of objetonuevo" >
            <p-panel header="Tarea: {{tarea.title}}" styleClass="blue-panel" [style]="{'width':'100%','margin-bottom':'50px','border-radius':'100px;'}"
                *ngIf="displayDialog" [@fadeInOut]>
                   <h3>Notas: {{tarea.notas}}</h3>
                   <h3>Fecha de Inicio: {{tarea.campoAgregado}}</h3>
            </p-panel>
        </ng-container>
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="hideDialog()" label="Cerrar" pAutoFocus [autofocus]="true"></p-button>
    </ng-template>
</!--p-dialog-->

<div class="content mt-0">
    <router-outlet></router-outlet>
</div>