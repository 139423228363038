import { IUsuarios } from './../../shared/models/usuarios';
import { ConfirmationService,ConfirmEventType,MessageService } from 'primeng/api';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { HttpService } from 'src/app/shared/services/http.service';
import ClientesProspecto from 'src/app/shared/models/ClientesProspecto';
import { Estatus } from 'src/app/shared/models/Estatus';
import { FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { IResultadoGeneral } from 'src/app/shared/models/IResultadoGeneral';
import { AuthService } from 'src/app/shared/services/auth.service';
//import TipoProyecto from 'src/app/shared/models/TipoProyecto';
//servicios
import { clasificacion } from 'src/app/shared/services/clasificacion.service';
import { subclasificacion } from 'src/app/shared/services/subclasificacion.service';
import { mediodecontacto } from 'src/app/shared/services/mediodecontacto.service';
import { clienteprospecto } from 'src/app/shared/services/clienteprospecto.service';
import User from 'src/app/shared/models/user';
import { tipoProyecto } from 'src/app/shared/services/tipoProyecto.service';

import Estados_Ciudades from 'src/app/shared/models/Estados_Ciudades';
import { UsuariosService } from 'src/app/shared/services/usuarios.service';
import { ProspeccionEstatusClienteService } from 'src/app/shared/services/prospeccionEstatusClienteService';
import ProspeccionEstatusCliente from 'src/app/shared/models/ProspeccionEstatusCliente';

@Component({
  selector: 'app-prospeccion-estatus-cliente',
  templateUrl: './prospeccion-estatus-cliente.component.html',
  styleUrls: ['./prospeccion-estatus-cliente.component.scss']
})
export class ProspeccionEstatusClienteComponent implements OnInit {
  cat_prospeccion:ProspeccionEstatusCliente[]=[];
  cat_clientesProspecto:ClientesProspecto[]=[];
  loading: boolean = true;
  usuario:User={};
  color_gris_deshabilitadas:string = '#D9D9D9';
  @ViewChild('dt1') dt!: Table;

  constructor(private httpService: HttpService, private ClasificacionService: clasificacion, private SubClasificacionService: subclasificacion, private MedioDeContactoService: mediodecontacto,
    private ClienteProspectoService: clienteprospecto,private confirmationService: ConfirmationService,private message:MessageService,private auth:AuthService,
    private tipoproyectoservice:tipoProyecto, private prospeccionService: ProspeccionEstatusClienteService) { 
      this.cargarInformacionUsuario();
    }

    ngOnInit(): void {
      this.ObtenerClientesProspeccion();
      this.ObtenerClientesProspecto();
    }

    cargarInformacionUsuario(){
      this.usuario = this.auth.user;
    }

    ObtenerClientesProspecto(){
      this.loading=true;
      this.ClienteProspectoService.getClientesProspecto().subscribe((resp)=>{
        this.cat_clientesProspecto=resp;
        setTimeout(() => {
          this.loading=false;
        }, 800);
      })
    }

    openNewWindow(pagina:string): void {
      window.open("//" + pagina, '_blank');
    }

    ObtenerClientesProspeccion(){
      this.prospeccionService.getProspeccionEstatusCliente().subscribe((resp)=>{
        this.cat_prospeccion=resp;
        
        setTimeout(() => {
        }, 800);
      });
    }

    clear(table: Table) {
      table.clear();
    }
    applyFilterGlobal($event: any, stringVal: any) {
      this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
    }
}
