import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { retry, take,catchError  } from 'rxjs/operators';
import {throwError} from 'rxjs'
import { NavigationExtras, Router } from "@angular/router";

//primeng services
import { MessageService } from 'primeng/api';
//own services
import { AuthService } from 'src/app/shared/services/auth.service';
//models
import { environment } from 'src/environments/environment';
import { Observable,BehaviorSubject  } from 'rxjs';
import ProspeccionEstatusCliente from '../models/ProspeccionEstatusCliente';
@Injectable({
    providedIn: 'root'
  })
  export class ProspeccionEstatusClienteService {
    private apiUrl: string = "";
    private usuariosSubject = new BehaviorSubject<any[]>([]);
    private apiUrlComercialProspeccionCliente:string="";
  
    constructor(private http: HttpClient, private auth: AuthService, private message: MessageService, private router: Router) {
      this.apiUrl = environment.sUrlAuth + 'Auth';
      this.apiUrlComercialProspeccionCliente=environment.SUrlgApi+'CtePotencial/ctepotencial';
     }
  
    getProspeccionEstatusCliente(): Observable<ProspeccionEstatusCliente[]> {
      // Obtener el token de autenticación del servicio de autenticación
      const token = this.auth.token;
  
      // Definir los encabezados con el token de autorización y la cookie
  
      const valores={
        params: new HttpParams().set('ck',token)
      };
  
      // Realizar la solicitud HTTP GET con los encabezados
      return this.http.get<ProspeccionEstatusCliente[]>(`${this.apiUrlComercialProspeccionCliente}`,valores)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
    }
  
  
     handleError(error:any) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage = `Error: ${error.error.message}`;
      } else {
        // server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      console.log(errorMessage);
      return throwError(() => {
          return errorMessage;
      });
    }
  }