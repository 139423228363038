import { Component } from '@angular/core';

@Component({
  selector: 'app-comision-combustible-sodexo',
  templateUrl: './comision-combustible-sodexo.component.html',
  styleUrls: ['./comision-combustible-sodexo.component.scss']
})
export class ComisionCombustibleSodexoComponent {

}
