import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { retry, take,catchError  } from 'rxjs/operators';
import {throwError} from 'rxjs'
import { NavigationExtras, Router } from "@angular/router";

//primeng services
import { MessageService } from 'primeng/api';
//own services
import { AuthService } from 'src/app/shared/services/auth.service';
//models
import Module from '../models/Module';
import { groupBy, permissionMapper } from '../utils/utils';
import { environment } from 'src/environments/environment';
import { Observable,BehaviorSubject  } from 'rxjs';
import { EventInput } from '@fullcalendar/core';
import Agenda from '../models/Agenda';
import EstatusCalendario from '../models/EstatusCalendario';
import EventosCalendario from '../models/EventosCalendario';
import NotificacionesCalendario from '../models/NotificacionesCalendario';
import UsuariosComercial from '../models/UsuariosComercial';

@Injectable({
    providedIn: 'root'
  })
export class calendarioService{
    private apiUrlComercial:string="";
    private apiUrlComercialsaveEventos:string="";
    private apiUrlComercialeditEventos:string="";
    private apiUrlComercialObtenerAgenda:string="";
    private apiUrlComercialObtenerEstatus:string="";
    private apiUrlComercialObtenerNotificaciones:string="";
    private apiUrlComercialObtenerUsuariosComercial:string="";
    constructor(private http: HttpClient, private auth: AuthService, private message: MessageService, private router: Router) {
        this.apiUrlComercial=environment.SUrlgApi+'CalendarioCte/eventos';
        this.apiUrlComercialsaveEventos=environment.SUrlgApi+'CalendarioCte/saveEventos';
        this.apiUrlComercialeditEventos=environment.SUrlgApi+'CalendarioCte/editEventos';
        this.apiUrlComercialObtenerAgenda=environment.SUrlgApi+'CalendarioCte/agenda';
        this.apiUrlComercialObtenerEstatus=environment.SUrlgApi+'CalendarioCte/estatusevento';
        this.apiUrlComercialObtenerNotificaciones=environment.SUrlgApi+'CalendarioCte/notificaciones';
        this.apiUrlComercialObtenerUsuariosComercial=environment.SUrlgApi+'CalendarioCte/usuarios';
       }

       getTareas(): Observable<EventosCalendario[]> {

        const token = this.auth.token;
    
        const valores={
          params: new HttpParams().set('ck',token)
        };

        // Realizar la solicitud HTTP GET con los encabezados
        return this.http.get<EventosCalendario[]>(`${this.apiUrlComercial}`,valores)
          .pipe(
            retry(1),
            catchError(this.handleError)
          );
      }

      saveTareas(data: any):Observable<any>{
        const token = this.auth.token;

    // Parámetros de la URL con el token
    const params = new HttpParams().set('ck', token);

    // Encabezados de la solicitud
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    // Convertir los datos a JSON
    const body = JSON.stringify(data);

    // Realizar la solicitud POST
    return this.http.post<any>(this.apiUrlComercialsaveEventos, body, { params, headers });
      }

      public editTareas(data: any ):Observable<any>{
        const token = this.auth.token;
    const params = new HttpParams().set('ck', token);

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    // Convertir los datos a JSON
    const body = JSON.stringify(data);
        return this.http.put<any>(this.apiUrlComercialeditEventos,body,{params,headers});
       }


       public getEstatusCalendario():Observable<EstatusCalendario[]>{
        return this.http.get<EstatusCalendario[]>(this.apiUrlComercialObtenerEstatus).pipe(retry(1), catchError(this.handleError));
       }

       public getNotificaciones():Observable<NotificacionesCalendario[]>{
        return this.http.get<NotificacionesCalendario[]>(this.apiUrlComercialObtenerNotificaciones).pipe(retry(1),catchError(this.handleError));
       }

       public getUsuariosComercial():Observable<UsuariosComercial[]>{
        return this.http.get<UsuariosComercial[]>(this.apiUrlComercialObtenerUsuariosComercial).pipe(retry(1),catchError(this.handleError));
       }
       public getAgenda():Observable<Agenda[]>{
        return this.http.get<Agenda[]>(this.apiUrlComercialObtenerAgenda).pipe(retry(1),catchError(this.handleError));
       }
       

    

      handleError(error:any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(() => {
            return errorMessage;
        });
      }
}