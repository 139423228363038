
import { Component, ChangeDetectorRef, ViewChild, OnInit } from '@angular/core';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi, EventInput, EventDropArg, DateInput } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import Calendario_Recordatorios from 'src/app/shared/models/Calendario_Recordatorios';
import { calendarioService } from 'src/app/shared/services/calendario.service';
import EstatusCalendario from 'src/app/shared/models/EstatusCalendario';
import Calendario from 'src/app/shared/models/Calendario';
import EventosCalendario from 'src/app/shared/models/EventosCalendario';


import { Table } from 'primeng/table';
import { HttpService } from 'src/app/shared/services/http.service';
import MediosDeContactoModel from 'src/app/shared/models/MediosDeContactoModel';
import { AuthService } from 'src/app/shared/services/auth.service';
import User from 'src/app/shared/models/user';
import { IResultadoGeneral } from 'src/app/shared/models/ResultadoGeneral';
import { mediodecontacto } from 'src/app/shared/services/mediodecontacto.service';
import { trigger, style, transition, animate } from '@angular/animations'
import { Estatus } from 'src/app/shared/models/Estatus';
import NotificacionesCalendario from 'src/app/shared/models/NotificacionesCalendario';
import UsuariosComercial from 'src/app/shared/models/UsuariosComercial';
import { IusuarioTarea } from 'src/app/shared/models/usuario_tareas';
import ClientesProspecto from 'src/app/shared/models/ClientesProspecto';
import { clienteprospecto } from 'src/app/shared/services/clienteprospecto.service';
import { Calendar } from 'primeng/calendar';
import { MainComponent } from '../main/main.component';
import esLocale from '@fullcalendar/core/locales/es';
import Agenda from 'src/app/shared/models/Agenda';
import { Checkbox } from 'primeng/checkbox';
@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss'],
})
export class CalendarioComponent implements OnInit {
  //visible: boolean = false;
  //visible2: boolean = false;
  loading: boolean = true;
  tuFormulario: FormGroup;
  text: string = '';
  fechaYHora: string = '';
  fechaYHoraFin: string = '';
  CardCalendario: EventosCalendario[] = [];
  CardCalendarios: EventosCalendario = {
    title: '',
    id: '',
    start: '',
    end: '',
    clv_status: 0,
    notas: '',
    fec_inicio: new Date(),
    fec_fin: new Date(),
    id_notificacion: 0,
  };


  dspAgregarEvento: boolean = false;
  calendarVisible = true;
  calendarApi: any;
  catCalendario: Calendario[] = [];
  objEventInput: EventInput[] = [];
  usuario: User = {};
  catClientesProspecto: ClientesProspecto[] = [];

  catAgenda: Agenda[] = [];
  valorpasar1: Date = new Date();
  valorpasar2: Date = new Date();

  hover = false;

  cat_medioDeContacto: MediosDeContactoModel[] = [];

  cat_usuariosComercial: UsuariosComercial[] = [];
  usuariosSeleccionados: string[] = [];

  lstUsuariosTareas: IusuarioTarea[] = [];

  notificaciones: NotificacionesCalendario[] = [];
  //seleccion de tareas
  displayDialog: boolean = false;
  tareaSeleccionada: any;
  check: boolean = true;
  checked: boolean = true;
  Ctepros: number = 0;

  CatEventosCalendario: EventosCalendario[] = [];


  dspAgenda: boolean = false;

  catEstatus: EstatusCalendario[] = [];
  currentEvents: EventApi[] = [];
  showSeconds: boolean = true;

  @ViewChild('calendar') calendar!: Calendar;
  @ViewChild('calendar2') calendar2!: Calendar;
  @ViewChild('fullcalendar') fullcalendar!: FullCalendarComponent;

  CardVisible: boolean = false;

  calendarioForm: FormGroup = new FormGroup({
    titulo: new FormControl('', [
      Validators.required
    ]),
    notas: new FormControl('',
      [
      ]),
    estatus: new FormControl('',
      [
      ]),
    fecha_inicio: new FormControl(Date, [Validators.required]),
    fecha_fin: new FormControl(Date, [Validators.required]),
    tiempo_recordatorio: new FormControl(0, []),
    nom_empleado: new FormControl<UsuariosComercial[] | null>(null, [Validators.required]),
    id_clienteProspecto: new FormControl(0, []),
    medioDeContacto: new FormControl('', []),
    chekk:new FormControl(false)
  });

  calendarOptions: CalendarOptions = {
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
    ],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridDay,listWeek'
    },
    locales: [esLocale], // Configurar el idioma español
    locale: 'es', // Establecer el idioma por defecto como español
    initialView: 'dayGridMonth',
    weekends: true,
    editable: true,
    eventDrop: this.handleEventDrop.bind(this),
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    //eventContent: this.customEventContent.bind(this), 
    /* you can update a remote database when these fire:
    eventAdd:
    eventChange:
    eventRemove:
    */
  };

  constructor(private changeDetector: ChangeDetectorRef, private router: Router, private message: MessageService,
    private calendarioServicio: calendarioService, private confirmationService: ConfirmationService, private auth: AuthService, private messageService: MessageService,
    private ClienteProspectoService: clienteprospecto, private mediodecontactoService: mediodecontacto, private main: MainComponent) {
    this.tuFormulario = new FormGroup({
      tiempo_recodatorio: new FormControl({ value: '', disabled: true }) // Aquí estableces el control como deshabilitado

    });
    this.cargarInformacionUsuario();
  }

  ngOnInit(): void {
    this.obtenerTareas();
    this.getEstatusCalendario();
    this.getNotificaciones();
    this.obtenerUsuariosComercial();
    this.getListadoClientesProspecto();
    this.getMedioDeContacto();
    this.obtenerContactosAgenda();
    this.loading = false;
  }
  habilitarDropdown() {
    this.tuFormulario.controls['tiempo_recodatorio'].enable(); // Habilita el FormControl
  }
  getNotificaciones() {
    this.calendarioServicio.getNotificaciones().subscribe((resp) => {
      this.notificaciones = resp;
      setTimeout(() => {
      }, 800);
    });
  }

  getMedioDeContacto() {
    this.mediodecontactoService.getMedioContacto().subscribe((resp) => {
      this.cat_medioDeContacto = resp;
      setTimeout(() => {
      }, 800);
    })
  }

  cargarInformacionUsuario() {
    this.usuario = this.auth.user;
  }

  deshabilitarDropdown() {
    this.tuFormulario.controls['tiempo_recodatorio'].disable(); // Deshabilita el FormControl
  }

  ngAfterViewInit() {
    this.calendarApi = this.fullcalendar.getApi();
  }

  EstatusTarea(tarea: any) {
    let fechainicio: DateInput;
    let fechafin: DateInput;
    fechainicio = tarea.start;
    fechafin = tarea.end;
    let nuevaFecha: DateInput = new Date();
    let nuevafechafin: DateInput = new Date();
    if (fechainicio !== null && fechafin !== null) {
      nuevafechafin = fechafin;
      nuevaFecha = fechainicio; // Asigna el valor de la fecha a una nueva variable de tipo Date
    } else {
    }


    const even: EventosCalendario = {
      title: tarea.title,
      start: tarea.start,
      end: tarea.end,
      id: tarea.id,
      notas: tarea._def.extendedProps['notas'],
      clv_status: tarea._def.extendedProps['clv_status'],
      fec_inicio: tarea._def.extendedProps['fec_inicio'],
      fec_fin: tarea._def.extendedProps['fec_fin'],
      nom_comercial: '',
      cod_usuario: '',
      id_notificacion: 0
    };

    let objetoBusqueda;
    let estatus_original: number = 0;

    if (even && even.clv_status !== undefined) {
      objetoBusqueda = this.CatEventosCalendario.find(objeto => objeto.clv_status === 1);
      estatus_original = even.clv_status;
    }

    if (even.clv_status === 0 && objetoBusqueda == undefined) {
      this.confirmationService.confirm({
        message: 'Esta seguro que desea iniciar esta actividad? <b>' + even.title + '</b>',
        header: 'Confirmacion de Inicio de actividad ' + even.notas + '',
        icon: 'pi pi-info-circle',
        accept: () => {
          even.clv_status = 1;
          let obj_enviar: EventosCalendario = {
            title: even.title,
            start: even.start,
            end: even.end,
            id: even.id,
            notas: even.notas,
            clv_status: even.clv_status,
            fec_inicio: new Date(),
            fec_fin: even.fec_fin,
            clv_automatico: 0,
            id_notificacion: 0
          };
          this.calendarioServicio.editTareas(obj_enviar).subscribe((resp) => {
            setTimeout(() => {
              this.obtenerTareas();
              this.message.add({ severity: 'success', summary: 'Inicio Exitoso', detail: 'la tarea se inicio correctamente' });
              this.recargarCalendario();
            }, 800);
          })
        },
        reject: (type: any) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              even.clv_status = estatus_original;
              this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
              break;
            case ConfirmEventType.CANCEL:
              even.clv_status = estatus_original;
              this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
              break;
          }
        }
      });
    } else if (even.clv_status == 1) {
      this.confirmationService.confirm({
        message: 'Esta seguro que desea finalizar esta actividad?',
        header: 'Confirmacion de Finalizacion de actividad',
        icon: 'pi pi-info-circle',
        accept: () => {
          even.clv_status = 2;
          let obj_enviar: EventosCalendario = {
            title: even.title,
            start: even.start,
            end: even.end,
            id: even.id,
            notas: even.notas,
            clv_status: even.clv_status,
            fec_fin: new Date(),
            clv_automatico: 0,
            id_notificacion: 0
          };
          this.calendarioServicio.editTareas(obj_enviar).subscribe((resp) => {
            setTimeout(() => {
              this.obtenerTareas();
              this.message.add({ severity: 'success', summary: 'Finalizacion Exitoso', detail: 'la tarea se Finalizo Corrrectamente' });
              this.recargarCalendario();
              this.main.obtenerTareas();
            }, 800);
          })
        },
        reject: (type: any) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              even.clv_status = estatus_original;
              this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
              break;
            case ConfirmEventType.CANCEL:
              even.clv_status = estatus_original;
              this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
              break;
          }
        }
      })
      //this.message.add({severity:'danger',summary:'Ya tienes una actividad en inicio',detail:'Por favor cierra una actividad para poder abrir otra'})
    } else if (even.clv_status == 2) {
      this.message.add({ severity: 'danger', summary: 'Esta actividad ya fue finalizada', detail: 'Esta actividad no puede volverse a iniciar' });
    } else if (even.clv_status == 0) {
      this.message.add({ severity: 'danger', summary: 'Error en inicio de actividades', detail: 'Asegurate de cerrar tus actividades antes de abrir una nueva' });
    }

  }

  obtenerContactosAgenda() {
    this.calendarioServicio.getAgenda().subscribe((resp) => {
      this.catAgenda = resp;
      setTimeout(() => {
      }, 600);
    })
  }

  VerTarea(tarea: any) {
    this.CardVisible = true;
    let fechainicio: DateInput;
    let fechafin: DateInput;
    fechainicio = tarea.start;
    fechafin = tarea.end;
    let nuevaFecha: DateInput = new Date();
    let nuevafechafin: DateInput = new Date();
    if (fechainicio !== null && fechafin !== null) {
      nuevafechafin = fechafin;
      nuevaFecha = fechainicio; // Asigna el valor de la fecha a una nueva variable de tipo Date
    } else {
    }

    const even: EventosCalendario = {
      title: tarea.title,
      start: tarea.start,
      end: tarea.end,
      id: tarea.id,
      notas: tarea._def.extendedProps['notas'],
      clv_status: tarea._def.extendedProps['clv_status'],
      fec_inicio: tarea._def.extendedProps['fec_inicio'],
      fec_fin: tarea._def.extendedProps['fec_fin'],
      nom_comercial: '',
      cod_usuario: '',
      id_notificacion: tarea._def.extendedProps['id_notificacion'],
      nom_empresa: tarea._def.extendedProps['nom_empresa'],
      mediocontacto: tarea._def.extendedProps['mediocontacto']
    };

    even.start = even.start.toLocaleString().replace(',', ' a las ').substring(0, 22);
    this.CardCalendario[0] = even;
  }

  OcultarAgenda() {
    this.dspAgenda = false;
  }

  MostrarDrop() {
    this.check = !this.check;
  }

  MostrarClientes() {
    this.checked = !this.checked;
  }

  handleCalendarToggle() {

    this.calendarVisible = !this.calendarVisible;
    this.dspAgenda = !this.dspAgenda
  }

  getListadoClientesProspecto() {
    this.ClienteProspectoService.getClientesProspecto().subscribe((resp) => {
      this.catClientesProspecto = resp;
      setTimeout(() => {
      }, 1000);
    })
  }

  obtenerUsuariosComercial() {
    this.calendarioServicio.getUsuariosComercial().subscribe((resp) => {
      this.cat_usuariosComercial = resp;
      setTimeout(() => {
      }, 800);
    })
  }

  showCard() {
    this.CardVisible = true;
  }


  obtenerTareas() {
    this.calendarioServicio.getTareas().subscribe((tareas) => {
      this.CatEventosCalendario = tareas;

      const events: EventInput[] = this.CatEventosCalendario.map((tarea) => ({
        title: tarea.title,
        start: tarea.start,
        end: tarea.end,
        id: tarea.id,
        extendedProps: {
          notas: tarea.notas,
          clv_status: tarea.clv_status,
          fec_inicio: tarea.fec_inicio,
          fec_fin: tarea.fec_fin,
          id_notificacion: tarea.id_notificacion,
          nom_empresa: tarea.nom_empresa,
          mediocontacto: tarea.mediocontacto
        },
      }));

      const fechasInicio = events.map((evento) => evento.start);

      this.objEventInput = events;
      this.calendarOptions.events = events;

    });
  }

  handleEventDrop(dropInfo: EventDropArg) {
    const { event, oldEvent } = dropInfo;
    if (event._def.extendedProps['clv_status'] === 1 || event._def.extendedProps['clv_status'] === 2) {
      this.message.add({ severity: 'danger', summary: 'Problema con actividad', detail: 'Esta actividad ya fue finalizada o esta en curso, no se puede mover de fecha' });
      this.obtenerTareas();
      return this.recargarCalendario();
    }

    let fechavieja: Date | null;
    fechavieja = oldEvent.start;
    let nuevaFechaVieja: Date = new Date();
    if (fechavieja !== null) {
      nuevaFechaVieja = fechavieja;
    } else {
    }


    let fechainicio: Date | null;
    let fechafin: Date | null;
    fechainicio = event.start;
    fechafin = event.end;
    let nuevaFecha: Date = new Date();
    let nuevaFechafin: Date = new Date();
    if (fechainicio !== null && fechafin !== null) {
      nuevaFecha = fechainicio; // Asigna el valor de la fecha a una nueva variable de tipo Date
      nuevaFechafin = fechafin;
    } else {
    }






    // Supongamos que tienes dos fechas
    let fechaVieja: Date = new Date(nuevaFechaVieja);
    let fechanueva: Date = new Date(nuevaFecha);

    // Calcula la diferencia en milisegundos
    let diferenciaMilisegundos: number = fechanueva.getTime() - fechaVieja.getTime();

    // Convierte la diferencia a días
    let diferenciaDias: number = diferenciaMilisegundos / (1000 * 60 * 60 * 24);


    let objbusqueda: EventosCalendario = {
      id: event.id,
      title: event.title,
      end: nuevaFechafin,
      start: nuevaFecha,
      clv_status: event._def.extendedProps['clv_status'],
      notas: event._def.extendedProps['notas'],
      fec_inicio: event._def.extendedProps['fec_inicio'],
      fec_fin: event._def.extendedProps['fec_fin'],
      clv_automatico: 0,
      id_notificacion: 0
    }

    this.calendarioServicio.editTareas(objbusqueda).subscribe((resp) => {
      setTimeout(() => {
        this.obtenerTareas();
        this.message.add({ severity: 'success', summary: 'Modificacion Exitosa', detail: 'la fecha se movio exitosamente' });
        this.recargarCalendario();
      }, 800);

    })
  }
  onMouseEnter() {
    this.hover = true;
  }

  onMouseLeave() {
    this.hover = false;
  }

  /*GenerarNotificaciones() {
      let ObjetoBusqueda: EventosCalendario[] = this.CatEventosCalendario.map((campo) => ({
        id: campo.id,
        title: campo.title,
        end: campo.end,
        start: campo.start,
        id_notificacion: campo.id_notificacion,
        notas: campo.notas,
        clv_status: campo.clv_status
      }
      ));
      for (let i = 0; i < ObjetoBusqueda.length; i++) {
        let fechaInput: DateInput = ObjetoBusqueda[i].start;
        let fech=fechaInput.toLocaleString();
        fech=fech.replaceAll('-','');
        fech=fech.replaceAll(':','');
        fech=fech.replace('T','');
        let año=fech.substring(0,4);
        let mes=fech.substring(4,6);
        let dia=fech.substring(6,8);
        let hora=fech.substring(8,10);
        let minutos=fech.substring(10,12);
        let segundos=fech.substring(12,14);
        let date = new Date(parseInt(año),parseInt(mes)-1,parseInt(dia), parseInt(hora), parseInt(minutos), parseInt(segundos));
  
        const startDateTime = date;
        const now = new Date();
        const timeDifference = startDateTime.getTime() - now.getTime();
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hoursDifference = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutesDifference = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  
  
        if (daysDifference <= 7 && ObjetoBusqueda[i].id_notificacion === 4) {
          this.showConfirm('Faltan ' + daysDifference + ' dias para tu tarea: ' + ObjetoBusqueda[i].title);
          let ObjetoEnviar: EventosCalendario = {
            id: ObjetoBusqueda[i].id,
            title: ObjetoBusqueda[i].title,
            end: ObjetoBusqueda[i].end,
            start: ObjetoBusqueda[i].start,
            id_notificacion: 5,
            notas: ObjetoBusqueda[i].notas,
            clv_status: ObjetoBusqueda[i].clv_status,
            fec_inicio: ObjetoBusqueda[i].fec_inicio,
            fec_fin: ObjetoBusqueda[i].fec_fin
          }
          this.calendarioServicio.editTareas(ObjetoEnviar).subscribe((resp) => {
            setTimeout(() => {
            }, 800);
          })
        } else if (daysDifference <= 1 && ObjetoBusqueda[i].id_notificacion === 3) {
          this.showConfirm('Falta ' + daysDifference + ' dia para tu tarea: ' + ObjetoBusqueda[i].title);
          let ObjetoEnviar: EventosCalendario = {
            id: ObjetoBusqueda[i].id,
            title: ObjetoBusqueda[i].title,
            end: ObjetoBusqueda[i].end,
            start: ObjetoBusqueda[i].start,
            id_notificacion: 5,
            notas: ObjetoBusqueda[i].notas,
            clv_status: ObjetoBusqueda[i].clv_status,
            fec_inicio: ObjetoBusqueda[i].fec_inicio,
            fec_fin: ObjetoBusqueda[i].fec_fin
          }
          this.calendarioServicio.editTareas(ObjetoEnviar).subscribe((resp) => {
            setTimeout(() => {
            }, 800);
          })
        } else if (hoursDifference <= 1 && ObjetoBusqueda[i].id_notificacion === 2) {
          this.showConfirm('Falta ' + hoursDifference + ' hora para tu tarea: ' + ObjetoBusqueda[i].title);
          let ObjetoEnviar: EventosCalendario = {
            id: ObjetoBusqueda[i].id,
            title: ObjetoBusqueda[i].title,
            end: ObjetoBusqueda[i].end,
            start: ObjetoBusqueda[i].start,
            id_notificacion: 5,
            notas: ObjetoBusqueda[i].notas,
            clv_status: ObjetoBusqueda[i].clv_status,
            fec_inicio: ObjetoBusqueda[i].fec_inicio,
            fec_fin: ObjetoBusqueda[i].fec_fin
          }
          this.calendarioServicio.editTareas(ObjetoEnviar).subscribe((resp) => {
            setTimeout(() => {
            }, 800);
          })
        } else if (minutesDifference <= 30 && ObjetoBusqueda[i].id_notificacion === 1) {
          this.showConfirm('Faltan ' + minutesDifference + ' minutos para tu tarea: ' + ObjetoBusqueda[i].title);
          let ObjetoEnviar: EventosCalendario = {
            id: ObjetoBusqueda[i].id,
            title: ObjetoBusqueda[i].title,
            end: ObjetoBusqueda[i].end,
            start: ObjetoBusqueda[i].start,
            id_notificacion: 5,
            notas: ObjetoBusqueda[i].notas,
            clv_status: ObjetoBusqueda[i].clv_status,
            fec_inicio: ObjetoBusqueda[i].fec_inicio,
            fec_fin: ObjetoBusqueda[i].fec_fin
          }
          this.calendarioServicio.editTareas(ObjetoEnviar).subscribe((resp) => {
            setTimeout(() => {
            }, 800);
          })
        } else if (minutesDifference === 0 && ObjetoBusqueda[i].id_notificacion === 0) {
          this.showConfirm('Es Hora de comenzar tu tarea: ' + ObjetoBusqueda[i].title);
          let ObjetoEnviar: EventosCalendario = {
            id: ObjetoBusqueda[i].id,
            title: ObjetoBusqueda[i].title,
            end: ObjetoBusqueda[i].end,
            start: ObjetoBusqueda[i].start,
            id_notificacion: 5,
            notas: ObjetoBusqueda[i].notas,
            clv_status: ObjetoBusqueda[i].clv_status,
            fec_inicio: ObjetoBusqueda[i].fec_inicio,
            fec_fin: ObjetoBusqueda[i].fec_fin
          }
          this.calendarioServicio.editTareas(ObjetoEnviar).subscribe((resp) => {
            setTimeout(() => {
            }, 800);
          })
        }
      }
    }
  */


  showNotification(message: string) {
    alert(message);
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    this.AgregarEvento(1);
    /*this.showSeconds = false;
    const calendarApi = selectInfo.view.calendar;
    let valor1=new Date(selectInfo.startStr);
    let valor2=new Date(selectInfo.endStr);
    this.fechaYHora = selectInfo.startStr;
    this.fechaYHoraFin = selectInfo.endStr;
   

    let fech=selectInfo.startStr.toLocaleString();
      fech=fech.replaceAll('-','/');
      let año=fech.substring(0,4);
      let mes=fech.substring(4,6);
      let dia=fech.substring(6,8);
      let date = new Date(parseInt(año),parseInt(mes)-1,parseInt(dia));

      let fech2=selectInfo.endStr.toLocaleString();
      fech2=fech2.replaceAll('-','/');
      let año2=fech2.substring(0,4);
      let mes2=fech2.substring(4,6);
      let dia2=fech2.substring(6,8);
      let date2 = new Date(parseInt(año2),parseInt(mes2)-1,parseInt(dia2));

      this.calendarioForm.controls['fecha_inicio'].setValue(fech);
      this.calendarioForm.controls['fecha_fin'].setValue(fech2);

      this.valorpasar1=date;
      this.valorpasar2=date2;
      this.fechaYHora=fech;
      this.fechaYHoraFin=fech2;
    
    calendarApi.unselect(); // clear date selection
    this.AgregarEvento(2);*/
  }

  handleEventClick(clickInfo: EventClickArg) {
    const tarea = clickInfo.event;
  }

  getEstatusCalendario() {
    this.calendarioServicio.getEstatusCalendario().subscribe((resp) => {
      this.catEstatus = resp;
      setTimeout(() => {
      }, 1000);
    })
  }

  guardarEvento() {
    const usuariosSeleccionados = this.calendarioForm.get('nom_empleado')?.value;

    if (!usuariosSeleccionados || usuariosSeleccionados.length === 0) {
      return this.message.add({ severity: 'danger', summary: 'Error', detail: 'Debe seleccionar al menos un usuario' });
    }

    if (this.usuariosSeleccionados.length > 0) {
      this.usuariosSeleccionados.map((val) => {
        let usuario: IusuarioTarea = {
          cod_usuario: ''
        };
        usuario.cod_usuario = val;
        this.lstUsuariosTareas.push(usuario);
      });
    }

    let fecha1: Date = new Date(this.fechaYHora);
    let fecha2: Date = new Date(this.fechaYHoraFin);
    if (fecha1 > fecha2) {
      return this.message.add({ severity: 'error', summary: 'error de fechas', detail: 'No puedes ingresar una fecha de inicio mayor a una fecha fin' });
    } else if (this.fechaYHora.length === 0 || this.fechaYHoraFin.length === 0) {
      return this.message.add({ severity: 'error', summary: 'error de fechas', detail: 'No puedes dejar vacia alguna fecha para poder guardar' });
    } else if (this.fechaYHora == this.fechaYHoraFin) {
      return this.message.add({ severity: 'error', summary: 'error de fechas', detail: 'no puedes guardar dos fechas iguales' });
    }

    const { calendarOptions, calendarioForm } = this;
    const nuevoEvento = {
      cod_usuario: this.lstUsuariosTareas,
      id: '',
      title: this.calendarioForm.get('titulo')?.value,
      start: this.fechaYHora,
      end: this.fechaYHoraFin,
      notas: this.calendarioForm.get('notas')?.value.toUpperCase(),
      clv_automatico: 0,
      nom_comercial: '',
      id_notificacion: this.calendarioForm.get('tiempo_recordatorio')?.value,//===null? 0: this.calendarioForm.get('tiempo_recordatorio')?.value
      id_cte_prospecto: this.checked === true ? 0 : this.Ctepros,
      mediocontacto: this.checked === true ? '' : this.calendarioForm.get('medioDeContacto')?.value.toUpperCase(),
      nom_empresa: ''
    };

    // Agregar el evento al calendario
    this.calendarApi.addEvent(nuevoEvento);

    this.calendarioServicio.saveTareas(nuevoEvento).subscribe((resp) => {
      setTimeout(() => {
        this.obtenerTareas();
        this.message.add({ severity: 'success', summary: 'Evento guardado', detail: 'La información se almacenó correctamente' });
        this.recargarCalendario();
        this.main.obtenerTareas();
        this.calendarioForm.reset();
        this.lstUsuariosTareas = [];
        this.usuariosSeleccionados = [];
        this.resetearCalendario();
        this.resetCampo();
        this.MostrarDrop();
        this.MostrarClientes();
      }, 800);
    },
      (error) => {
        this.lstUsuariosTareas = [];
        this.resetCampo();
        this.messageService.add({ severity: 'error', summary: 'Error en <Guardar Tarea>', detail: 'Contacte al Administrador del Sitio' });
      });
    this.dspAgregarEvento = false;
  }

  resetearCalendario() {
    this.calendarioForm.get('fecha_inicio')?.reset();
    this.calendarioForm.get('fecha_fin')?.reset();
  }

  resetCampo() {
    this.fechaYHora = '';
    this.fechaYHoraFin = '';
    this.usuariosSeleccionados = [];
  }

  recargarCalendario() {
    this.fullcalendar.getApi().refetchEvents(); // Método para recargar los eventos del calendario
  }
  AgregarEvento(id: number) {
    if (id === 1) {
      this.check = true;
      this.checked = true;
      this.fechaYHora = '';
      this.fechaYHoraFin = '';
      let idcte = this.catClientesProspecto[0].id_cte_prospecto;
      let medio = this.cat_medioDeContacto[0].nom_mediosdecontacto;
      this.calendarioForm.get('tiempo_recordatorio')?.disable();
      this.calendarioForm.get('id_clienteProspecto')?.disable();
      this.Ctepros = idcte;
      this.calendarioForm.controls['id_clienteProspecto'].setValue(idcte);
      this.calendarioForm.get('medioDeContacto')?.disable();
      this.calendarioForm.controls['medioDeContacto'].setValue(medio);
      this.dspAgregarEvento = true;
    }
  }
  AgregarEventoAgenda(id: number, data: Agenda) {
    if (id === 1) {
      this.check = true;
      this.checked = false;
      this.fechaYHora = '';
      this.fechaYHoraFin = '';
      let idcte = data.id_cte_prospecto;
      let medio = this.cat_medioDeContacto[0].nom_mediosdecontacto;
      this.calendarioForm.get('tiempo_recordatorio')?.disable();
      this.calendarioForm.get('id_clienteProspecto')?.enable();
      this.Ctepros = idcte;
      this.calendarioForm.controls['chekk'].setValue(true);
      this.calendarioForm.controls['id_clienteProspecto'].setValue(idcte);
      this.calendarioForm.get('medioDeContacto')?.enable();
      this.calendarioForm.controls['medioDeContacto'].setValue(medio);
      this.dspAgregarEvento = true;
    }
  }
  cancelarVentanaModal() {

    this.dspAgregarEvento = false;
    this.resetearCalendario();
    this.resetCampo();
    this.calendarioForm.reset();

  }
  agregarTarea(data: any) {

  }
  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
    this.changeDetector.detectChanges();
  }

}