import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Table } from 'primeng/table';
import { HttpService } from 'src/app/shared/services/http.service';
import SubClasificacion from 'src/app/shared/models/SubClasificacion';
import TipoClasificacion from 'src/app/shared/models/TipoClasificacion';
import User from 'src/app/shared/models/user';
import { subclasificacion } from 'src/app/shared/services/subclasificacion.service';
import { MessageService,ConfirmationService,ConfirmEventType } from 'primeng/api';
import { AuthService } from 'src/app/shared/services/auth.service';
import { IResultadoGeneral } from 'src/app/shared/models/IResultadoGeneral';
import { clasificacion } from 'src/app/shared/services/clasificacion.service';
import Clasificacion from 'src/app/shared/models/Clasificacion';
import { trigger, style, transition, animate } from '@angular/animations'
import { Estatus } from 'src/app/shared/models/Estatus';

@Component({
  selector: 'app-sub-clasificacion-de-clientes',
  templateUrl: './sub-clasificacion-de-clientes.component.html',
  styleUrls: ['./sub-clasificacion-de-clientes.component.scss'],
  animations: [
    trigger('nuevoRegistro', [
      transition(':enter', [
        style({ transform: 'translateY(20px)', opacity: 0 }),
        animate('500ms', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
  ],
})
export class SubClasificacionDeClientesComponent implements OnInit{
   //animacion
   nuevoRegistroAnimation = 'nuevoRegistro';

  usuario:User={};
  color_gris_deshabilitadas:string = '#D9D9D9';
  objClasificacion:Clasificacion[]=[];
  objEstatus:Estatus[]=[{
    clv_activo:0,
    des_estatus:'Desactivado'
  },
  {
    clv_activo:1,
    des_estatus:'Activo'
  }]
  /*tipoclasificacion:TipoClasificacion[]=[{
    tipoClasificacion:'mayorista'
  },
  {
    tipoClasificacion:'minorista'
  }]*/

  subclasificacion:SubClasificacion[]=[];
  @ViewChild('dt1') dt!: Table;
  dspSubClasificacion:boolean=false;
  activityValues: number[] = [0, 100];
  loading: boolean = true;
  subClasificacionForm: FormGroup = new FormGroup({
    id_medio_de_contacto: new FormControl(0,[]),
    nombreSubClasificacion: new FormControl('', [ 
      Validators.required
    ]),
    descripcion: new FormControl('', [ 
      Validators.required
    ]),
    clv_activo: new FormControl(0, []),
    des_tipoclasificacion: new FormControl('', [ 
      Validators.required
    ])
  });
  fecha_dia:any;
  numero_operacion:number=0;
  texto_operacion: string = '';

  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };

  /*id:number;
  cod_usuario_registro?: string;
  cod_usuario_ult_mov?: string;
  clv_activo: number;
  opcion?:number;
  fecha_Registro:string;
  nombre:string;
  descripcion:string
  tipoClasificacion:string;*/

  data_editar_subClasificacion: SubClasificacion={
    id_sub_clasificacion_de_cte:0,
    opcion:0,
    cod_usuario:'',
    clv_activo:0,
    nom_subclasificacion:'',
    des_subclasificacion:'',
    des_tipoclasificacion:''
  };

  constructor(private httpService: HttpService, private SubClasif:subclasificacion,private message:MessageService,private auth:AuthService,
    private confirmationService: ConfirmationService, private Clasificacionservice:clasificacion) {
    this.cargarInformacionUsuario();
  }
  ngOnInit(): void {
    this.getListadoSubClasificacion();
    this.inicializarFechas();
  }
  obtenerClasificaciones(){
    this.loading=true;
    this.Clasificacionservice.getClasificacion().subscribe((resp)=>{
      this.objClasificacion=resp;
      setTimeout(() => {
        this.loading=false;
      }, 1000);
    })
  }

  redireccionarNueva(num_operacion:number) {
    this.obtenerClasificaciones();
    this.numero_operacion=num_operacion;
    this.texto_operacion = '';
    this.subClasificacionForm.reset();
    this.resultado = {
      clv_estatus: 0,
      messageDetails: ''
    };
    this.texto_operacion = 'Nuevo Medio de contacto';
    //
    //
    this.dspSubClasificacion = true;
}

  getListadoSubClasificacion(){
    this.loading=true;
    this.SubClasif.getSubClasificacion().subscribe((resp)=>{
      this.subclasificacion=resp;
      setTimeout(() => {
        this.loading=false;
      }, 1000);
    })
   }

  cargarInformacionUsuario(){
    this.usuario = this.auth.user;
  }

  confirmarDesactivar(data: SubClasificacion) {
    let estatus_original= data.clv_activo;
    let texto_desactivar: string = data.clv_activo == 1 ? 'DESACTIVAR' : 'ACTIVAR';
    let texto_desactivar_header: string = data.clv_activo == 1 ? 'Desactivar' : 'Activar';
    this.confirmationService.confirm({
      message: 'Esta seguro que desea <b>' + texto_desactivar + '</b> el medio de contacto <b>'+data.nom_subclasificacion+' '+data.des_subclasificacion+'</b>?',
      header: 'Confirmacion de ' + texto_desactivar_header,
      icon: 'pi pi-info-circle',
      accept: () => {
        data.clv_activo = data.clv_activo == 1 ? 0 : 1;
        data.cod_usuario=this.usuario.cod;
        data.opcion=3;
        this.guardarInformacionDesactivar(data);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            data.clv_activo=estatus_original;
            this.message.add({ key: 'msj', severity: 'error', summary: 'Rechazado', detail: 'Se ha Rechazado' });
            break;
          case ConfirmEventType.CANCEL:
            data.clv_activo=estatus_original;
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Se ha Cancelado' });
            break;
        }
      }
    });
  }

  guardarInformacionDesactivar(data: SubClasificacion) {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    //data.cod_usuario_ult_mov=this.usuario.cod==null? '0999': this.usuario.cod;
    this.SubClasif.editSubClasificacion(data).subscribe((resp)=> {
      var resultado=resp;
      if(resultado.clv_estatus==1){
        setTimeout(() => {
          this.message.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
          this.subClasificacionForm.reset();
          this.cancelarVentanaModal();
        }, 800);
      }
    },
    (error)=> {
      this.message.add({ severity: 'error', summary: 'Error en <Editar Chofer>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  inicializarFechas(){
    var getYear = new Date().toLocaleDateString('es-MX',{ year: 'numeric'});   
    var getMonth = new Date().toLocaleDateString('es-MX',{ month: '2-digit'});
    var getDay = new Date().toLocaleDateString('es-MX',{day: '2-digit'});
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia=dateFormat;
    //Formatear fecha de Inicio
  }
  cancelarVentanaModal() {
    this.texto_operacion = '';
    this.dspSubClasificacion = false;
    this.subClasificacionForm.reset();
    this.resultado = {
      clv_estatus: 0,
      messageDetails: ''
    };
  }

  mostrarModalEditar(data: SubClasificacion,num_operacion:number) {
    this.data_editar_subClasificacion=data;
    this.numero_operacion=num_operacion;

    this.subClasificacionForm.controls['nombreSubClasificacion'].setValue(data.nom_subclasificacion);
    this.subClasificacionForm.controls['descripcion'].setValue(data.des_subclasificacion);
    this.subClasificacionForm.controls['des_tipoclasificacion'].setValue(data.des_tipoclasificacion);
    //this.mediosDeContactoForm.controls['clv_activo'].setValue(data.clv_activo);
    //
    //
    //
    this.dspSubClasificacion = true;
    this.texto_operacion = 'Editar SubClasificacion';
  }

  guardarInformacion(){
    this.inicializarFechas();
    let data_enviar: SubClasificacion={
      id_sub_clasificacion_de_cte:this.numero_operacion==1? 0:this.subClasificacionForm.get('id_sub_clasificacion_de_cte')?.value,
      cod_usuario: this.usuario.cod,
      clv_activo: typeof this.subClasificacionForm.get('clv_activo')?.value === 'object' ? 1 : this.subClasificacionForm.get('clv_activo')?.value,
      nom_subclasificacion:this.subClasificacionForm.get('nombreSubClasificacion')?.value,
      des_subclasificacion:this.subClasificacionForm.get('descripcion')?.value.toUpperCase(),
      des_tipoclasificacion:this.subClasificacionForm.get('des_tipoclasificacion')?.value,
      opcion:this.numero_operacion
    };
    if(this.numero_operacion==1){
      this.SubClasif.SaveSubClasificacion(data_enviar).subscribe((resp)=>{
        var resultado=resp;
        setTimeout(()=>{
          this.subclasificacion.push(data_enviar);
          this.getListadoSubClasificacion();
          this.message.add({ severity: 'success', summary: 'Medio de contacto Exitoso', detail: 'La informacion se almaceno de forma correcta' });
          this.subClasificacionForm.reset();
          this.cancelarVentanaModal();
        },800);
      },
      (error)=> {
        this.message.add({ severity: 'error', summary: 'Error en <Agregar Medio de contacto>', detail: 'Contacte al Administrador del Sitio' });
      });
    } else
    if(this.numero_operacion==2){
      this.SubClasif.editSubClasificacion(data_enviar).subscribe((resp)=>{
        var resultado=resp;
        setTimeout(() => {
          this.getListadoSubClasificacion();
          this.message.add({severity:'success',summary:'Edicion Exitosa',detail:'la informacion se edito correctamente'});
          this.subClasificacionForm.reset();
          this.cancelarVentanaModal();
        }, 800);
      })
    }
    
  }
  
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }
  clear(table: Table) {
    table.clear();

}
}
