<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="SI" rejectLabel="NO"
    rejectButtonStyleClass="p-button-text"></p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
    rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
    [rejectVisible]="false"></p-confirmDialog>
<div *ngIf="loading" class="loading-overlay">
    <!--i class="fas fa-spinner fa-spin" style="background-color: red;"></-i-->
    <img src="../../../assets/images/lapt.gif" alt="Cargando..." class="cart-spinner">
    <h1 class="h1">Cargando datos...</h1>
</div>
<div class="d-flex justify-content-center">
    <div class="container-fluid" style="width: 100%;">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="card card-shadow sinborde">
                    <div class="card-header sinborde" style="background: none; ">
                        <div class="cardicono rounded">
                            <i class="pi pi-share-alt mt-3" style="font-size: 2rem; color: #fff;"></i>
                        </div>
                        <header class="posicion" style="left: 11%; top: 0%;">Clientes prospecto</header>
                        <div class="row d-flex justify-content-end">
                            <div class="col-2">
                                <button class="btn btn-primary w-100 p-button-raised" (click)="redireccionarNueva(1)"
                                    pTooltip="Nuevo medio de contacto" tooltipPosition="top">
                                    Agregar Cliente Prospecto<i class="pi pi-plus-circle"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-content">
                        <div class="row mt-3 mx-0 px-1">
                            <p-table #dt1 [value]="clienteProspecto" [tableStyle]="{'min-width': '50rem'}"
                                styleClass="p-datatable-sm p-datatable-striped" dataKey="id" [rows]="10"
                                [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]" [paginator]="true"
                                responsiveLayout="scroll" [scrollable]="true" scrollDirection="both"
                                scrollHeight="580px"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                                [globalFilterFields]="['num_folio','nom_empresa','rfc','des_paginaweb','nom_razonsocial','clv_interesado']">
                                <ng-template pTemplate="caption">
                                    <div class="flex">
                                        <button pButton label="Limpiar" class="p-button-outlined mr-2"
                                            icon="pi pi-filter-slash" (click)="clear(dt1)">
                                        </button>
                                        <p-columnFilter field="clv_interesado" matchMode="equals" [showMenu]="false">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="estatus"
                                                    optionLabel="des_estatus" optionValue="clv_activo"
                                                    (onChange)="filter($event.value)" placeholder="Filtrar por estatus"
                                                    [showClear]="true">
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                        <span class="p-input-icon-left ml-auto">
                                            <input pInputText type="text"
                                                (input)="applyFilterGlobal($event, 'contains')"
                                                placeholder="Buscar RFC,Nombre,Folio" size="40" />
                                        </span>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr [ngClass]="{'texto_sm':true}">
                                        <th pFrozenColumn style="min-width:5rem;width: 5rem;">
                                            #
                                        </th>
                                        <th style="min-width:15rem" pFrozenColumn pSortableColumn="num_folio">
                                            <div class="flex align-items-center">
                                                Numero de folio
                                                <p-sortIcon field="num_folio"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th style="min-width:15rem" pFrozenColumn pSortableColumn="nom_empresa">
                                            <div class="flex align-items-center">
                                                Nombre del Cliente
                                                <p-sortIcon field="nom_empresa"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th style="min-width:10rem" pFrozenColumn pSortableColumn="rfc">
                                            <div class="flex align-items-center">
                                                RFC
                                                <p-columnFilter type="text" field="rfc" display="menu"></p-columnFilter>
                                            </div>
                                        </th>
                                        <th style="min-width:10rem">
                                            <div class="flex align-items-center">
                                                Página Web
                                                <p-columnFilter type="text" field="des_paginaweb"
                                                    display="menu"></p-columnFilter>
                                            </div>
                                        </th>
                                        <th style="min-width:10rem">
                                            <div class="flex align-items-center">
                                                Razon social
                                                <p-columnFilter type="text" field="nom_razonsocial"
                                                    display="menu"></p-columnFilter>
                                            </div>
                                        </th>
                                        <th style="min-width: 10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Interesado&nbsp;
                                            </div>
                                        </th>
                                        <th style="min-width: 15rem;width: 15rem;">
                                            <div class="flex align-items-center" style="padding-left: 45px;">
                                                Lead &nbsp; <i class="fa-solid fa-flag color-icon"></i>
                                            </div>
                                        </th>
                                        <th style="min-width: 15rem;width: 15rem;">
                                            <div class="flex align-items-center">
                                                Estatus Actual
                                            </div>
                                        </th>
                                        <th style="min-width: 10rem;padding-left: 40px;">
                                            <div class="flex align-items-center">
                                                Propuesta Comercial &nbsp; <i class="pi pi-clipboard"></i>
                                            </div>
                                        </th>
                                        <th style="min-width: 10rem;padding-left: 25px;">
                                            <div class="flex align-items-center">
                                                Tabulador &nbsp; <i class="pi pi-clipboard"></i>
                                            </div>
                                        </th>
                                        <th style="min-width: 10rem; width: 10rem;">
                                            Contacto
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data let-index="rowIndex" let-editing="editing">
                                    <tr [ngClass]="{'row-accessories': data.clv_activo ===0}">
                                        <td pFrozenColumn [frozen]="true" class="texto_negrita">
                                            {{(index+1)}}
                                        </td>
                                        <td pFrozenColumn [frozen]="true" class="texto_negrita texto_naranja_ba">
                                            {{data.num_folio}}
                                        </td>
                                        <td pFrozenColumn [frozen]="true" style="z-index: 700;">
                                            <div class="texto_negrita texto_verde_oliva"><i style="font-size:1.3rem"
                                                    class="pi pi-user"></i> {{data.nom_empresa}}</div>
                                        </td>
                                        <td pFrozenColumn [frozen]="true" style="z-index: 700;">
                                            <span class="texto_negrita">{{data.rfc}}</span>
                                        </td>
                                        <td>
                                            <a [routerLink]="" (click)="openNewWindow(data.des_paginaweb)"
                                                class="selecs"><i style="font-size: 1.3rem;"
                                                    class="fa-solid fa-globe texto_negrita"></i>
                                                {{data.des_paginaweb}}</a>
                                        </td>
                                        <td>
                                            <span class="texto_negrita">{{data.nom_razonsocial}}</span>
                                        </td>
                                        <td [pEditableColumn]="data.clv_interesado"
                                            pEditableColumnField="clv_interesado">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-dropdown [options]="estatus" optionLabel="des_estatus"
                                                        [(ngModel)]="valorCombo" (onShow)="SetDropDown(data)"
                                                        (onChange)="confirmarDesactivar(data)" optionValue="clv_activo"
                                                        appendTo="body" [style]="{'width':'100%'}"></p-dropdown>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    <p-tag *ngIf="data.clv_interesado==0" icon="pi pi-user"
                                                        [rounded]="true" severity='danger'
                                                        value="No interesado"></p-tag>
                                                    <p-tag *ngIf="data.clv_interesado==1" icon="pi pi-user"
                                                        [rounded]="true" severity='success' value="Interesado"></p-tag>
                                                    <p-tag *ngIf="data.clv_interesado==2" icon="pi pi-user"
                                                        [rounded]="true" severity='warning'
                                                        value="Sin respuesta"></p-tag>
                                                    &nbsp;<span
                                                        pTooltip="De Click Sobre el Interes del Cliente para Modificarlo*">
                                                        <i style="color:#2454B8 !important;font-size: 1.4rem;"
                                                            class="fa-solid fa-circle-info color-icon"></i>
                                                    </span>
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td>
                                            <div *ngIf="data.clv_interesado===1"
                                                class="flex justify-content-center flex-wrap gap-3">
                                                <p-splitButton icon="fa-solid fa-envelopes-bulk"
                                                    styleClass="p-button-raised p-button-help mr-3 mb-2"
                                                    label="Ver Estatus" [(ngModel)]="SplitValor" [model]="items"
                                                    (onDropdownClick)="mostrar(data)" appendTo="body" />
                                            </div>
                                        </td>
                                        <td [pEditableColumn]="data.des_estatus" pEditableColumnField="des_estatus">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-dropdown [options]="ModeloEstatus" optionLabel="estatus"
                                                        [(ngModel)]="valorEstatus"
                                                        (onShow)="ValorEstatus(data.des_estatus)"
                                                        (onChange)="CambiarEstatus($event,data)" optionValue="id_orden"
                                                        appendTo="body" [style]="{'width':'100%'}"></p-dropdown>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    <span
                                                        class="texto_negrita texto_naranja_ba">{{data.des_estatus}}&nbsp;<span
                                                            pTooltip="De Click Sobre el Estatus para Modificarlo*">
                                                            <i style="color:#2454B8 !important;font-size: 1.4rem;"
                                                                class="fa-solid fa-circle-info color-icon"></i>
                                                        </span></span>
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td style="width: 100px;">
                                            <div *ngIf="data.flag_tabulador==1" class="d-fex">
                                                <button class="btn btn-info p-button-raised" label="Propuesta Comercial"
                                                    pButton icon="pi pi-briefcase" pTooltip="Propuesta Comercial"
                                                    (click)="verPropuestaComercial(data)">
                                                </button>
                                            </div>
                                        </td>
                                        <td style="width: 100px;">
                                            <div class="d-fex">
                                                <button class="btn btn-info colores p-button-raised" label="Tabulador"
                                                    pButton icon="pi pi-calculator" pTooltip="Tabulador"
                                                    (click)="IrNavegador(data)">
                                                </button>
                                            </div>
                                        </td>
                                        <td style="min-width: 10rem; width: 10rem;">
                                            <div class="d-flex justify-content-around">
                                                <button class="btn btn-info p-button-raised" pButton
                                                    icon="pi pi-user-plus" styleClass="p-button-sm"
                                                    pTooltip="Agregar Contacto"
                                                    style="margin-right: 10px !important;width: 52px;"
                                                    tooltipPosition="top" (click)="AgregarContacto(data,1);">
                                                </button>
                                                <button pButton icon="pi pi-users" styleClass="p-button-sm"
                                                    class="p-button-raised"
                                                    style="margin-right: 10px !important; background-color: lightslategray; width: 52px; border: none;"
                                                    pTooltip="Ver contacto" tooltipPosition="top"
                                                    (click)="VerContacto(data.id_cte_prospecto);">
                                                </button>
                                                <p-button icon="pi pi-user-edit"
                                                    styleClass="p-button-warning p-button-lg"
                                                    class="rosa p-button-raised" pTooltip="Editar" tooltipPosition="top"
                                                    (click)="mostrarModalEditar(data,2);">
                                                </p-button>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="7">Sin Clientes Prospecto.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            <div class="row mt-3 d-flex justify-content-center">
                                <div class="col-6">
                                    <div class="mt-3 mb-2">
                                        <p-colorPicker [(ngModel)]="color_gris_deshabilitadas"
                                            [disabled]="true"></p-colorPicker>
                                        <p class="text-left informativo" style="display: inline;">
                                            Clientes prospecto inactivos</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--p-dialog [resizable]="false" [modal]="true" [responsive]="true" [draggable]="false" [(visible)]="VerEstatus"
        [style]="{width: '100vw','height':'1800px'}" styleClass="card-shadow">
        <ng-template pTemplate="header">
            Estatus de Lead
        </ng-template>
        <ng-template pTemplate="content">
            <div class="card">
                <p-toast></p-toast>
                <p-steps [model]="otroMenu" [readonly]="false" [activeIndex]="activeIndex"
                    (activeIndexChange)="onActiveIndexChange($event)" [style]="{'width':'100%'}"></p-steps>
            </div>
            <div class="container-fluid">
                <div class="form-card">
                    <span>
                        <h5><b>Modificar el Estatus del Cliente <i class="fa-regular fa-user color-icon"></i></b></h5>
                    </span>
                    <p-dropdown [appendTo]="'body'" [options]="ModeloEstatus" optionLabel="estatus"
                        optionValue="id_orden" [(ngModel)]="PasoSeleccionado"
                        (onChange)="ModificarEstatus(PasoSeleccionado)"></p-dropdown>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button type="button" pButton pRipple icon="pi pi-times" (click)="cerrarEstatus()" label="Cerrar"
                class="p-button-text"></button>
        </ng-template>
    </!--p-dialog-->

    <!--De aqui parriba-->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspNuevoCliente"
        [style]="{width: '70vw','height':'650px'}" [transitionOptions]="'0ms'" styleClass="card-shadow">
        <ng-template pTemplate="header">
            {{this.texto_dialog}}
            <!--p>{{ texto_operacion}}</!--p-->
        </ng-template>
        <ng-template pTemplate="content">
            <div class="mt-5">
                <div class="card-content card-shadow sinborde">
                    <form class="form_cliente" [formGroup]="ClienteProspectoForm" (ngSubmit)="guardarInformacion()">
                        <div class="ml-1 px-1">
                            <span class="title-light">Información del Cliente<i class="pi pi-cog"
                                    style="size: 2rem;"></i></span>
                            <div class="row mt-5">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="fa-solid fa-building color-icon"
                                                    style="font-size: 2rem;margin-left: 4px;"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <input type="text" class="form-control sinborde"
                                                placeholder="Nombre de la Empresa*"
                                                formControlName="nombreClienteProspecto"
                                                style="text-transform:uppercase;" pTooltip="Nombre"
                                                tooltipPosition="right">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="fa-solid fa-pen-nib color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11" pTooltip="RFC" tooltipPosition="top">
                                            <input type="text" class="form-control sinborde" placeholder="RFC"
                                                formControlName="rfc" style="text-transform:uppercase;">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="fa-brands fa-google color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <input type="text" class="form-control sinborde"
                                                placeholder="Ingrese la Pagina Web*" formControlName="paginaWeb"
                                                style="text-transform:uppercase;" pTooltip="Pagina web"
                                                tooltipPosition="right">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0 mr-4">
                                            <span class="input-group-addon"> <i class="fa-solid fa-handshake color-icon"
                                                    style="font-size: 2rem;"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <input type="text" class="form-control sinborde"
                                                placeholder="Ingrese la Razon Social*" formControlName="razonSocial"
                                                style="text-transform:uppercase;" pTooltip="Razon Social"
                                                tooltipPosition="right">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="form-group col-md-6">
                                    <div class="row g-0 mb-20">
                                        <div style="margin-bottom: 15px; margin-left: 85px;">
                                            <span style="color: #2454B8;"><b>¿Quien le Dara Seguimiento?</b></span>
                                        </div>
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-user color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11 pl-1">
                                            <p-dropdown [options]="cat_usuarioscomercial" autoWidth="false"
                                                placeholder="Seleccione un Usuario*" optionValue="nom_empleado"
                                                formControlName="nom_personaseguimiento" [showClear]="true"
                                                optionLabel="nom_empleado" [appendTo]="'body'"
                                                [style]="{'width':'100%'}" display="chip"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i
                                                    class="fa-regular fa-id-badge color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11 pl-2">
                                            <p-dropdown [options]="medioDContacto" optionLabel="nom_mediosdecontacto"
                                                formControlName="medioDeContacto" pTooltip="Medio de Contacto"
                                                placeholder="Seleccione el Medio de Contacto*" autoWidth="false"
                                                [style]="{'width':'100%'}" optionValue="id_medio_de_contacto"
                                                [showClear]="true" tooltipPosition="left"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11 pl-2">
                                            <p-dropdown [options]="estatus" optionLabel="des_estatus"
                                                pTooltip="Estatus del Cte" formControlName="clv_interesado"
                                                placeholder="Seleccione el Estatus*" autoWidth="false"
                                                [style]="{'width':'100%'}" optionValue="clv_activo" [showClear]="true"
                                                tooltipPosition="left"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i
                                                    class="fa-solid fa-diagram-project color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <p-dropdown [options]="tipoproyectos" optionLabel="nombre"
                                                formControlName="tipoproyecto"
                                                placeholder="Seleccione el tipo de proyecto*" autoWidth="false"
                                                pTooltip="Tipo de Proyecto" [style]="{'width':'100%'}"
                                                optionValue="id_proyecto" [showClear]="true" tooltip="tipo de proyecto"
                                                tooltipPosition="right"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-2 mx-0 align-items-center">
                                            <span class="input-group-addon"> <i class="fa-solid fa-flag-usa color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                            <p class="color-icon mt-2"
                                                style="display: inline-block; font-weight: bold;">Estado</p>
                                        </div>
                                        <div class="col-sm-10">
                                            <p-dropdown [options]="estados" optionLabel="estado"
                                                formControlName="estado" placeholder="Seleccione el Estado"
                                                autoWidth="false" [style]="{'width':'100%'}" optionValue="id"
                                                [showClear]="true"
                                                (onChange)="getListadoMunicipios($event)"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-2 mx-0 align-items-center">
                                            <span class="input-group-addon"> <i class="fa-solid fa-flag color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                            <p class="color-icon mt-2"
                                                style="display: inline-block; font-weight: bold;">Municipio</p>
                                        </div>
                                        <div class="col-sm-10">
                                            <p-dropdown [options]="municipios" optionLabel="municipios"
                                                formControlName="municipio" placeholder="Seleccione el Municipio"
                                                [(ngModel)]="valorSeleccionado" autoWidth="false"
                                                [style]="{'width':'100%'}" optionValue="id_Ciudad"
                                                [showClear]="true"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"><i
                                                    class="fa-solid fa-code-branch color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>

                                        </div>
                                        <div class="col-sm-11 pl-2">
                                            <p-dropdown [options]="clasificacion" optionLabel="nom_clasificacion"
                                                formControlName="clasificacionCliente" pTooltip="Clasificacion"
                                                placeholder="Seleccione Clasificacion*" autoWidth="false"
                                                [style]="{'width':'100%'}" optionValue="id_clasificacion_de_cte"
                                                [showClear]="true"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-4 mx-0 d-flex align-items-center">
                                            <span class="input-group-addon"> <i class="fa-solid fa-list color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                            <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Sub Clasificacion</p>
                                        </div>
                                        <div class="col-sm-8">
                                            <p-dropdown [options]="subclasificacion" optionLabel="nom_subclasificacion"
                                                formControlName="subclasificacionCliente" pTooltip="Sub Clasificacion"
                                                placeholder="Seleccione SubClasificacion" autoWidth="false"
                                                [style]="{'width':'100%'}" optionValue="id_sub_clasificacion_de_cte"
                                                [showClear]="true"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-2 mx-0 d-flex align-items-center">
                                                <span class="input-group-addon"> <i
                                                        class="fa-solid fa-pen-to-square color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                                <p class="color-icon mt-2 ml-2"
                                                    style="display: inline-block; font-weight: bold;"></p>
                                            </div>
                                            <div class="col-sm-11">
                                                <input type="text" class="form-control sinborde"
                                                    placeholder="Ingrese la Observacion(opcional)"
                                                    pTooltip="Ingrese una Observacion(opcional)" tooltipPosition="top"
                                                    formControlName="observacionAcercamiento">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center mt-3">
                                <div class="col-4">
                                    <p *ngIf="!ClienteProspectoForm.valid" class="texto-invalido text-right">
                                        <i class="pi pi-info-circle" style="font-size: 1rem;">Es Necesario Proporcionar
                                            La Informaci&oacute;n Obligatoria(*)</i>
                                    </p>
                                    <p *ngIf="ClienteProspectoForm.valid" class="texto-valido text-right">
                                        <i class="pi pi-check-circle" style="font-size: 1rem;">Informaci&oacute;n
                                            Completa</i>
                                    </p>
                                </div>
                                <div class="col-2">
                                    <button class="btn btn-primary form-control" type="submit"
                                        [disabled]="!ClienteProspectoForm.valid" pTooltip="Almacenar informacion"
                                        tooltipPosition="right">
                                        <span class="text-white">Guardar</span>
                                        <i class="pi pi-save text-white"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                (click)="cancelarVentanaModal()"></button>
        </ng-template>
    </p-dialog>

    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspContactoEmpresa"
        [style]="{width: '50vw','height':'600px'}" [transitionOptions]="'0ms'" styleClass="card-shadow">
        <ng-template pTemplate="header">
            {{this.StrContacto}}
        </ng-template>
        <ng-template pTemplate="content">
            <div class="mt-5">
                <div class="card-content card-shadow sinborde">
                    <form class="form_cliente" [formGroup]="contactoEmpresaForm"
                        (ngSubmit)="guardarInformacionContacto()">
                        <div class="ml-1 px-1">
                            <span class="title-light">Contacto<i class="pi pi-cog" style="size: 2rem;"></i></span>
                            <div class="row mt-5">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-2 mx-0 mr-2">
                                            <span class="input-group-addon"> <i
                                                    class="fa-regular fa-envelope color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-10">
                                            <input type="text" class="form-control sinborde"
                                                placeholder="Correo del contacto*" formControlName="correoContacto"
                                                style="text-transform:uppercase;" pTooltip="Correo del contacto"
                                                tooltipPosition="right">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-2 mx-0 mr-2">
                                            <span class="input-group-addon"> <i
                                                    class="fa-regular fa-address-card color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-10" pTooltip="Nombre(s)" tooltipPosition="top">
                                            <input type="text" class="form-control sinborde"
                                                placeholder="ingresar Nombre(s)" formControlName="nombres"
                                                style="text-transform:uppercase;">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-2 mx-0">
                                            <span class="input-group-addon"> <i class="fa-solid fa-person color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-10">
                                            <input type="text" class="form-control sinborde"
                                                placeholder="Apellido paterno*" formControlName="aPaterno"
                                                style="text-transform:uppercase;" pTooltip="Apellido paterno"
                                                tooltipPosition="right">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-2 mx-0">
                                            <span class="input-group-addon"> <i
                                                    class="fa-solid fa-person-dress color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-10" pTooltip="Apellido materno" tooltipPosition="top">
                                            <input type="text" class="form-control sinborde"
                                                placeholder="ingresar el apellido materno" formControlName="aMaterno"
                                                style="text-transform:uppercase;">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-2 mx-0">
                                            <span class="input-group-addon"> <i
                                                    class="fa-regular fa-address-book color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-10">
                                            <p-dropdown [options]="Puestos" optionLabel="nom_puesto" [appendTo]="'body'"
                                                formControlName="cargo" placeholder="Seleccione el Cargo del Contacto*"
                                                autoWidth="false" [style]="{'width':'100%'}"
                                                optionValue="id_tipo_puesto" [showClear]="true"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-2 mx-0">
                                            <span class="input-group-addon"> <i
                                                    class="fa-regular fa-building color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-10">
                                            <p-dropdown [options]="ClasificacionPuestos" optionLabel="nom_clasificacion"
                                                [appendTo]="'body'" formControlName="area"
                                                placeholder="Seleccione el Area del Contacto*" autoWidth="false"
                                                [style]="{'width':'100%'}" optionValue="id_tipo_clasificacion"
                                                [showClear]="true"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-2 mx-0">
                                            <span class="input-group-addon"> <i
                                                    class="fa-solid fa-mobile-screen-button color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-10" pTooltip="Numero de telefono" tooltipPosition="top">
                                            <input type="text" class="form-control sinborde"
                                                placeholder="ingresar el numero de telefono"
                                                formControlName="num_telefono" style="text-transform:uppercase;">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center mt-3">
                                <div class="col-4">
                                    <div class="col-4">
                                        <p *ngIf="!contactoEmpresaForm.valid" class="texto-invalido text-right">
                                            <i class="pi pi-info-circle" style="font-size: 1rem;">Es Necesario
                                                Proporcionar
                                                La Informaci&oacute;n Obligatoria(*)</i>
                                        </p>
                                        <p *ngIf="contactoEmpresaForm.valid" class="texto-valido text-right">
                                            <i class="pi pi-check-circle" style="font-size: 1rem;">Informaci&oacute;n
                                                Completa</i>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <button class="btn btn-primary form-control" type="submit"
                                        [disabled]="!contactoEmpresaForm.valid" pTooltip="Almacenar informacion"
                                        tooltipPosition="right">
                                        <span class="text-white">Guardar</span>
                                        <i class="pi pi-save text-white"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                (click)="cancelarVentanaModalContacto()"></button>
        </ng-template>
    </p-dialog>

    <p-dialog header="Ver contacto" [resizable]="false" [modal]="true" [maximizable]="true" appendTo="body"
        [(visible)]="dspVerContactoEmpresa" [style]="{width: '60vw'}" [contentStyle]="{height: '400px'}">
        <p-table [value]="contactoEmpresaObj" [scrollable]="true" scrollHeight="flex"
            [tableStyle]="{'min-width': '50rem'}">
            <ng-template pTemplate="header">
                <tr>
                    <th>#</th>
                    <th>Nombre</th>
                    <th>Apellido Paterno</th>
                    <th>Apellido Materno</th>
                    <th>Cargo</th>
                    <th>Correo</th>
                    <th>Area</th>
                    <th>Numero de telefono</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-index="rowIndex" let-data>
                <tr>
                    <td class="texto_negrita">
                        {{(index+1)}}
                    </td>
                    <td>{{data.des_nombre}}</td>
                    <td>{{data.des_apellidopaterno}}</td>
                    <td>{{data.des_apellidomaterno}}</td>
                    <td>{{data.nom_puesto}}</td>
                    <td><i *ngIf="data.correo!==''" class="pi pi-envelope mt-3"
                            style="font-size: 1rem; color:rgb(132, 119, 38);"></i>
                        {{data.des_correocontacto}}</td>
                    <td><i *ngIf="data.nom_clasificacionpuesto!==''" class="fa-regular fa-building mt-3 color-icon"
                            style="font-size: 1rem;"></i> {{data.nom_clasificacionpuesto}}</td>
                    <td><i *ngIf="data.telefono!==''" class="pi pi-phone mt-3"
                            style="font-size: 1rem; color:#2454B8;"></i>
                        {{data.num_telefono}}</td>
                    <td style="width: 180px;">
                        <div class="d-flex justify-content-around">
                            <button class="btn btn-info" pButton icon="pi pi-clone" label="Editar Contacto"
                                style="margin-right: 10px !important;" tooltipPosition="left"
                                (click)="EditarContacto(data,2);">
                            </button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <ng-template pTemplate="footer">
            <button type="button" pButton pRipple icon="pi pi-times" (click)="dspVerContactoEmpresa=false"
                label="Cerrar" class="p-button-text"></button>
        </ng-template>
    </p-dialog>
</div>

<p-dialog header="Marcado de Procesos para Inicio de Ruta" [modal]="true" [(visible)]="dspMarcadoProcesos"
    (onHide)="CerrarMarcado()" [style]="{ width: '48rem','height':'950px' }">
    <span class="p-text-secondary block mt-4 mb-4 texto_negrita" style="font-size: 1.3rem;">Selecciona o Marca los
        Procesos Identificados para Iniciar La Ruta. <i class="fa-solid fa-pen-to-square"></i></span>
    <form class="row flex align-items-left ml-2 gap-3" [formGroup]="MarcadoProcesosForm">
        <div class="row flex align-items-center gap-3 mb-3 texto_negrita" style="font-size: 1.5rem;">
            <div class="row col-md-5">
                <p-checkbox formControlName="Capacitacion" inputId="capacitacion" label="Capacitación" [binary]="true"
                    variant="filled" (onChange)="CambiarMarcado()" />
            </div>
            <div class="row col-md-7">
                <div class="row col-md-18">
                    <div class="row col-md-1" style="padding-right: 10px;">
                        <span class="fa-solid fa-circle-user colo mb-1" style="font-size: 2.2rem !important;"
                            pTooltip="Marcado por"></span>
                    </div>
                    <div class="row flex col-md-11 ml-5">
                        <input type="text" class="form-control sinborde ml-1" pTooltip="Marcado Por:"
                            formControlName="StrUsuarioCapacitacion" style="text-transform:uppercase;">
                        <hr class="my-0">
                    </div>
                </div>
                <div class="row col-md-18 mt-2">
                    <div class="row col-md-1" style="padding-right: 10px;">
                        <span class="fa-regular fa-calendar colo mb-1" style="font-size: 2.2rem !important;"></span>
                    </div>
                    <div class="row flex col-md-11 ml-5">
                        <input type="text" class="form-control sinborde ml-1" pTooltip="Fecha de Marcado:"
                            formControlName="FechaMarcadoCapacitacion" style="text-transform:uppercase;">
                        <hr class="my-0">
                    </div>
                </div>
            </div>
        </div>
        <div class="row gap-2 mb-3 texto_negrita" style="font-size: 1.5rem;">
            <div class="row col-md-5">
                <p-checkbox formControlName="ProcesoOperativo" inputId="ProcesoOperativo" label="Proceso Operativo"
                    [binary]="true" variant="filled" (onChange)="CambiarMarcado()" />
            </div>
            <div class="row col-md-7">
                <div class="row col-md-18">
                    <div class="row col-md-1" style="padding-right: 10px;">
                        <span class="fa-solid fa-circle-user colo mb-1" style="font-size: 2.2rem !important;"
                            pTooltip="Marcado por"></span>
                    </div>
                    <div class="row flex col-md-11 ml-5">
                        <input type="text" class="form-control sinborde ml-1" pTooltip="Marcado Por:"
                            formControlName="StrUsuarioProcesoOperativo" style="text-transform:uppercase;">
                        <hr class="my-0">
                    </div>
                </div>
                <div class="row col-md-18 mt-2">
                    <div class="row col-md-1" style="padding-right: 10px;">
                        <span class="fa-regular fa-calendar colo mb-1" style="font-size: 2.2rem !important;"></span>
                    </div>
                    <div class="row flex col-md-11 ml-5">
                        <input type="text" class="form-control sinborde ml-1" pTooltip="Fecha de Marcado:"
                            formControlName="FechaMarcadoProcesoOperativo" style="text-transform:uppercase;">
                        <hr class="my-0">
                    </div>
                </div>
            </div>
        </div>
        <div class="row gap-2 mb-3 texto_negrita" style="font-size: 1.5rem;">
            <div class="row col-md-5">
                <p-checkbox formControlName="RegistroPAO" inputId="RegistroPAO" label="Registro de PAO" [binary]="true"
                    variant="filled" (onChange)="CambiarMarcado()" />
            </div>
            <div class="row col-md-7">
                <div class="row col-md-18">
                    <div class="row col-md-1" style="padding-right: 10px;">
                        <span class="fa-solid fa-circle-user colo mb-1" style="font-size: 2.2rem !important;"
                            pTooltip="Marcado por"></span>
                    </div>
                    <div class="row flex col-md-11 ml-5">
                        <input type="text" class="form-control sinborde ml-1" pTooltip="Marcado Por:"
                            formControlName="StrUsuarioRegistroPAO" style="text-transform:uppercase;">
                        <hr class="my-0">
                    </div>
                </div>
                <div class="row col-md-18 mt-2">
                    <div class="row col-md-1" style="padding-right: 10px;">
                        <span class="fa-regular fa-calendar colo mb-1" style="font-size: 2.2rem !important;"></span>
                    </div>
                    <div class="row flex col-md-11 ml-5">
                        <input type="text" class="form-control sinborde ml-1" pTooltip="Fecha de Marcado:"
                            formControlName="FechaMarcadoRegistroPAO" style="text-transform:uppercase;">
                        <hr class="my-0">
                    </div>
                </div>
            </div>
        </div>
        <div class="row gap-2 mb-3 texto_negrita" style="font-size: 1.5rem;">
            <div class="row col-md-5">
                <p-checkbox formControlName="RegistroRuta" inputId="RegistroRuta" label="Registro de Ruta(S)"
                    [binary]="true" variant="filled" (onChange)="CambiarMarcado()" />
            </div>
            <div class="row col-md-7">
                <div class="row col-md-18">
                    <div class="row col-md-1" style="padding-right: 10px;">
                        <span class="fa-solid fa-circle-user colo mb-1" style="font-size: 2.2rem !important;"
                            pTooltip="Marcado por"></span>
                    </div>
                    <div class="row flex col-md-11 ml-5">
                        <input type="text" class="form-control sinborde ml-1" pTooltip="Marcado Por:"
                            formControlName="StrUsuarioRegistroRuta" style="text-transform:uppercase;">
                        <hr class="my-0">
                    </div>
                </div>
                <div class="row col-md-18 mt-2">
                    <div class="row col-md-1" style="padding-right: 10px;">
                        <span class="fa-regular fa-calendar colo mb-1" style="font-size: 2.2rem !important;"></span>
                    </div>
                    <div class="row flex col-md-11 ml-5">
                        <input type="text" class="form-control sinborde ml-1" pTooltip="Fecha de Marcado:"
                            formControlName="FechaMarcadoRegistroRuta" style="text-transform:uppercase;">
                        <hr class="my-0">
                    </div>
                </div>
            </div>
        </div>
        <div class="row gap-2 mb-3 texto_negrita" style="font-size: 1.5rem;">
            <div class="row col-md-5">
                <p-checkbox formControlName="RegistroTabulador" inputId="RegistroTabulador"
                    label="Registro de Tabulador(es)" [binary]="true" variant="filled" (onChange)="CambiarMarcado()" />
            </div>
            <div class="row col-md-7">
                <div class="row col-md-18">
                    <div class="row col-md-1" style="padding-right: 10px;">
                        <span class="fa-solid fa-circle-user colo mb-1" style="font-size: 2.2rem !important;"
                            pTooltip="Marcado por"></span>
                    </div>
                    <div class="row flex col-md-11 ml-5">
                        <input type="text" class="form-control sinborde ml-1" pTooltip="Marcado Por:"
                            formControlName="StrUsuarioRegistroTabulador" style="text-transform:uppercase;">
                        <hr class="my-0">
                    </div>
                </div>
                <div class="row col-md-18 mt-2">
                    <div class="row col-md-1" style="padding-right: 10px;">
                        <span class="fa-regular fa-calendar colo mb-1" style="font-size: 2.2rem !important;"></span>
                    </div>
                    <div class="row flex col-md-11 ml-5">
                        <input type="text" class="form-control sinborde ml-1" pTooltip="Fecha de Marcado:"
                            formControlName="FechaMarcadoRegistroTabulador" style="text-transform:uppercase;">
                        <hr class="my-0">
                    </div>
                </div>
            </div>
        </div>
        <div class="row gap-2 mb-3 texto_negrita" style="font-size: 1.5rem;">
            <div class="row col-md-5">
                <p-checkbox formControlName="ProcesoFacturacion" inputId="ProcesoFacturacion"
                    label="Registro de Facturacion" [binary]="true" variant="filled" (onChange)="CambiarMarcado()" />
            </div>
            <div class="row col-md-7">
                <div class="row col-md-18">
                    <div class="row col-md-1" style="padding-right: 10px;">
                        <span class="fa-solid fa-circle-user colo mb-1" style="font-size: 2.2rem !important;"
                            pTooltip="Marcado por"></span>
                    </div>
                    <div class="row flex col-md-11 ml-5">
                        <input type="text" class="form-control sinborde ml-1" pTooltip="Marcado Por:"
                            formControlName="StrUsuarioProcesoFacturacion" style="text-transform:uppercase;">
                        <hr class="my-0">
                    </div>
                </div>
                <div class="row col-md-18 mt-2">
                    <div class="row col-md-1" style="padding-right: 10px;">
                        <span class="fa-regular fa-calendar colo mb-1" style="font-size: 2.2rem !important;"></span>
                    </div>
                    <div class="row flex col-md-11 ml-5">
                        <input type="text" class="form-control sinborde ml-1" pTooltip="Fecha de Marcado:"
                            formControlName="FechaMarcadoProcesoFacturacion" style="text-transform:uppercase;">
                        <hr class="my-0">
                    </div>
                </div>
            </div>
        </div>
    </form>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
            (click)="CerrarMarcado()"></button>
    </ng-template>
</p-dialog>

<p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspPropuestaComercial" [modal]="true"
    [style]="{width: '80vw','height':'1200px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
    (onHide)="CerrarPropuesta()">
    <ng-template pTemplate="header">
        Propuesta Comercial
    </ng-template>
    <ng-template pTemplate="content">
        <p-blockUI [target]="dt2" [blocked]="blockedPanel">
            <i class="pi pi-lock" style="color:#ffffff;font-size: 3rem"></i>
        </p-blockUI>

        <div [formGroup]="controlform" class="p-input flex justify-content-center mt-3 mb-3">
            <span class="texto_negrita" style="font-size: 1.3rem;">{{this.StrBloqueado}} &nbsp;</span>
            <p-checkbox [binary]="true" formControlName="chckbox" variant="filled" (onChange)="bloquear()" />
        </div>
        <p-table #dt2 [value]="tablaTabuladorCostos" [tableStyle]="{'min-width': '100rem'}"
            styleClass="p-datatable-sm p-datatable-striped" dataKey="id" responsiveLayout="scroll" [scrollable]="true"
            scrollDirection="both" scrollHeight="580px" [paginator]="true" [rows]="10"
            [rowsPerPageOptions]="[10, 25, 50,100]" [loading]="loading" [showCurrentPageReport]="true"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
            [globalFilterFields]="['id_corrida','configuracion','origen','destino','costo','imp_preciodeventa','imp_utilidad',
                    'imp_utilidadmensual','imp_sueldochofer','imp_sueldoaux','imp_combustible','imp_comisioncombustible','imp_cel_gps','imp_imss','imp_hospedaje','imp_viaticos']">
            <ng-template pTemplate="header">
                <tr [ngClass]="{'texto_sm':true}">
                    <th pFrozenColumn style="min-width:5rem;width: 5rem;">
                        #
                    </th>
                    <th pFrozenColumn style="min-width:8rem;width: 8rem;">
                        <div class="flex align-items-center">
                            Estatus de la propuesta
                        </div>
                    </th>
                    <th pFrozenColumn style="min-width:8rem;width: 8rem;">
                        <div class="flex align-items-center">
                            No. Tabulado
                        </div>
                    </th>
                    <th pFrozenColumn style="min-width:10rem;width: 10rem;">
                        <div class="flex align-items-center">
                            Conf. Unidad
                        </div>
                    </th>
                    <th style="min-width:10rem;width: 10rem;">
                        <div class="flex align-items-center">
                            Origen

                        </div>
                    </th>
                    <th style="min-width:10rem;width: 10rem;">
                        <div class="flex align-items-center">
                            Destino
                        </div>
                    </th>
                    <th style="min-width:10rem;width: 10rem;">
                        <div class="flex align-items-center">
                            Costo
                            <p-sortIcon field="costo"></p-sortIcon>
                        </div>
                    </th>
                    <th style="min-width:8rem;width: 8rem;">
                        <div class="flex align-items-center">
                            Porcentaje UTD
                        </div>
                    </th>
                    <th style="min-width:10rem;width: 10rem;">
                        <div class="flex align-items-center">
                            Precio de Venta
                        </div>
                    </th>
                    <th style="min-width:10rem;width: 10rem;">
                        <div class="flex align-items-center">
                            Utilidad
                        </div>
                    </th>
                    <th style="min-width:15rem;width: 15rem;">
                        <div class="flex align-items-center">
                            Modificar el Estatus
                        </div>
                    </th>
                    <th style="min-width:12rem;width: 12rem;">

                    </th>
                    <th style="min-width:14rem;width: 14rem;">

                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-index="rowIndex">
                <tr
                    [ngClass]="{'row-accessories': data.clv_activo === 0, 'disabled-row': data.clv_aceptado === 3 || data.clv_cierre===2}">
                    <td pFrozenColumn [frozen]="true" class="texto_negrita" style="font-size:small">
                        {{(index+1)}}
                    </td>
                    <td pFrozenColumn [frozen]="true" class="texto_negrita" style="font-size:small">
                        <div *ngIf="data.clv_aceptado===1" class="texto_negrita" style="color: rgb(226, 176, 51);">
                            ENVIADA <i class="fa-solid fa-envelope-circle-check"
                                style="font-size: 1.4rem; color: rgb(194, 150, 40);"></i></div>
                        <div *ngIf="data.clv_aceptado===2" class="texto_negrita aceptada">ACEPTADA <i
                                class="fa-regular fa-thumbs-up" style="font-size: 1.4rem; color: #2ea273;"></i></div>
                        <div *ngIf="data.clv_aceptado===3" class="texto_negrita rechazado">RECHAZADA <i
                                class="fa-regular fa-thumbs-down" style="font-size: 1.4rem;"></i></div>
                    </td>
                    <td pFrozenColumn [frozen]="true" class="texto_negrita" style="font-size:small">
                        <div class="texto_negrita">#{{data.id_corrida}}</div>
                    </td>
                    <td pFrozenColumn [frozen]="true" class="texto_negrita texto_naranja_ba" style="font-size:small">
                        <div class="texto_negrita"> {{data.config_unidad}} <i class="fa-solid fa-truck-fast"
                                style="color: black; font-size: 1.4rem;"></i></div>
                    </td>
                    <td style="font-size:small">
                        <div class="texto_negrita texto_verde_oliva">{{data.des_origen}} <i
                                class="fa-solid fa-map-location" style="color: #7a7a0a; font-size: 1.3rem;"></i></div>
                    </td>
                    <td style="font-size:small">
                        <div class="texto_negrita texto_verde_oliva">{{data.des_destino}} <i
                                class="fa-solid fa-map-location" style="color: #7a7a0a; font-size: 1.3rem;"></i>
                        </div>
                    </td>
                    <td style="font-size:small">
                        <div class="texto_negrita money">{{data.costo| currency}}
                        </div>
                    </td>
                    <td style="font-size:small">
                        <div class="texto_negrita">%{{data.num_porcentajeutd}}
                        </div>
                    </td>
                    <td style="font-size:small">
                        <div class="texto_negrita money">{{data.imp_preciodeventa| currency}}</div>
                    </td>
                    <td style="font-size:small">
                        <div class="texto_negrita money">{{data.imp_utilidad| currency}}</div>
                    </td>
                    <td [pEditableColumn]="data.clv_aceptado" pEditableColumnField="clv_aceptado"
                        style="font-size:small">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <p-dropdown [options]="estatusPropuesta" optionLabel="label" optionValue="value"
                                    [(ngModel)]="valorNum" (onShow)="dropdown(data)"
                                    (onChange)="onEstatusPropuestaChange($event, data)" appendTo="body"
                                    [style]="{'width':'100%'}">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <p-tag *ngIf="data.clv_aceptado===1" icon="fa-regular fa-circle-question"
                                    [rounded]="true" severity='warning' value="SIN RESPUESTA" class="large-tag"></p-tag>
                                <p-tag *ngIf="data.clv_aceptado===2" icon="fa-regular fa-circle-check" [rounded]="true"
                                    severity='success' value="ACEPTADA" class="large-tag"></p-tag>
                                <p-tag *ngIf="data.clv_aceptado===3" icon="fa-regular fa-circle-xmark" [rounded]="true"
                                    severity='danger' value="RECHAZADA" class="large-tag"></p-tag>
                                <i style="color:#2454B8 !important;font-size: 1.4rem;"
                                    class="fa-solid fa-circle-info color-icon"
                                    pTooltip="Seleccione el estatus para Modificarlo"></i>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td style="font-size:small">
                        <div *ngIf="data.clv_aceptado===2" class="flex">
                            <button pButton label="Marcado de Procesos Ruta"
                                class="btn btn-secondary p-button-raised mr-2" icon="fa-solid fa-pen-to-square"
                                (click)="AbrirProcesos(data)" [disabled]="false">
                            </button>
                        </div>
                    </td>
                    <td style="font-size:small">
                        <div *ngIf="data.clv_cierre===1" class="flex">
                            <p-button label="Cierre de Ruta" styleClass="p-button-warning"
                                class="rosa p-button-raised mr-2" icon="pi pi-exclamation-circle"
                                (click)="CierreRuta(data)">
                            </p-button>
                        </div>
                        <div *ngIf="data.clv_cierre===2" class="flex">
                            <p-button label="Ruta Finalizada" styleClass="p-button-success" class="p-button-raised mr-2"
                                icon="fa-regular fa-circle-check">
                            </p-button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="container-fluid mt-5" style="width: 80%;" *ngIf="bolComercial">
            <div class="document-container">
                <div class="header">
                    <h2 class="headers">Subir los documentos Requeridos <i class="fa-solid fa-circle-up color-icon mt-1"
                            style="font-size: 1.5rem;color: #2454B8;"></i></h2>
                    <hr class="my-0">
                </div>
                <div class="document-item mt-2" *ngFor="let doc of documents">
                    <span class="document-name">
                        <img src="../../../assets/images/pd.jpg" style="height: 25px;width: 25px;" alt="">
                        {{doc.label}}:
                    </span>
                    <p-fileUpload accept=".pdf" chooseLabel="Seleccionar Archivo"
                        pTooltip="Seleccione Solo Archivos PDF" [style]="{'width':'100%'}" uploadLabel="Subir Archivo"
                        cancelLabel="Cancelar" name="demos[]" url="https://www.primefaces.org/cdn/api/upload.php"
                        (onUpload)="SubirArchivoPropuesta($event, doc)" (onSelect)="onFileSelect($event, doc)"
                        [multiple]="false" [auto]="true">
                        <ng-template pTemplate="content">
                            <ul *ngIf="doc.files.length">
                                <li *ngFor="let file of doc.files">
                                    <img src="../../../assets/images/pd.jpg" style="height: 25px;width: 25px;" alt="">
                                    {{ file.name }} - {{ file.size }} bytes
                                </li>
                            </ul>
                            <ul *ngIf="doc.uploaded===true">
                                <li class="mt-1"><img src="../../../assets/images/pd.jpg"
                                        style="height: 25px;width: 25px; margin-right: 5px;" alt="">{{ doc.name }}
                                </li>
                            </ul>
                        </ng-template>
                    </p-fileUpload>
                    <i style="font-size: 1.5rem;margin-left: 10px;"
                        [class]="doc.uploaded ? 'pi pi-check' : 'pi pi-times'"
                        [style.color]="doc.uploaded ? 'green' : 'red'"></i>
                    <p-button *ngIf="doc.uploaded===true" styleClass="p-button-raised p-button-text ml-5 mt-3"
                        icon="pi pi-file-pdf" type="button" label="Descargar" (click)="downloadFile(doc)"></p-button>
                    <p-button *ngIf="doc.uploaded===true"
                        styleClass="p-button-raised p-button-text p-button-danger ml-3 mt-3" icon="pi pi-eye"
                        label="Ver" (click)="viewFile(doc)"></p-button>
                    <p-button *ngIf="doc.uploaded===true"
                        styleClass="p-button-raised p-button-text p-button-warning ml-3 mt-3" icon="pi pi-trash"
                        label="Eliminar" (click)="deleteFile(doc)"></p-button>
                </div>
            </div>
        </div>
        <!--div class="container-fluid mt-5" style="width: 100vh;">
            <div class="document-container mt-5" *ngIf="this.bolComercial===true">
                <div class="header">
                    <h2 class="headers">Subir los documentos Requeridos <i class="fa-solid fa-circle-up color-icon mt-1"
                            style="font-size: 1.5rem;color: #2454B8;"></i></h2>
                    <hr class="my-0">
                </div>
                <div class="document-item mt-2" *ngFor="let doc of documents">
                    <span class="document-name"><img src="../../../assets/images/pd.jpg"
                            style="height: 25px;width: 25px;" alt=""> {{doc.label}}:</span>
                    <p-fileUpload accept=".pdf" chooseLabel="Seleccionar Archivo"
                        pTooltip="Seleccione Solo Archivos PDF" [style]="{'width':'100%'}" uploadLabel="Subir Archivo"
                        cancelLabel="Cancelar" name="demos[]" url="https://www.primefaces.org/cdn/api/upload.php"
                        (onUpload)="SubirArchivoPropuesta($event,doc)" [multiple]="false" [auto]="true">
                        <ng-template pTemplate="content">
                            <ul *ngIf="uploadedFilesPropuesta.length">
                                <li *ngFor="let file of uploadedFilesPropuesta"><img src="../../../assets/images/pd.jpg"
                                        style="height: 25px;width: 25px;" alt=""> {{ file.name }} - {{ file.size }}
                                    bytes</li>
                            </ul>
                        </ng-template>
                    </p-fileUpload>
                    <i style="font-size: 1.5rem;margin-left: 10px;"
                        [class]="doc.uploaded ? 'pi pi-check' : 'pi pi-times'"
                        [style.color]="doc.uploaded ? 'green' : 'red'"></i>
                    <p-button styleClass="p-button-raised p-button-text ml-3 mt-3" icon="pi pi-file-pdf" type="button"
                        label="Descargar Archivo" (click)="downloadFile(doc)"></p-button>
                    <p-button styleClass="p-button-raised p-button-text p-button-danger ml-3 mt-3" icon="pi pi-eye"
                        label="Ver Archivo" (click)="viewFile(doc)"></p-button>
                </div>
            </div>
        </!--div-->
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
            (click)="CerrarPropuesta()"></button>
    </ng-template>
</p-dialog>

<p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspBriefComercial" [modal]="true"
    [style]="{width: '80vw','height':'1200px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
    (onHide)="CerrarBriefComercial()">
    <ng-template pTemplate="header">
        Brief Comercial
    </ng-template>
    <ng-template pTemplate="content">
        <div class="container" style="width: 100%;">
            <div class="form-card">
                <div class="mt-5">
                    <div class="card-content card-shadow sinborde">
                        <form class="form_cliente" [formGroup]="BriefForm">
                            <div class="ml-1 px-1">
                                <span class="title-light">Informacion Brief Comercial<i class="pi pi-cog"
                                        style="size: 2.3rem;"></i></span>
                                <i class="pi pi-spin pi-spinner" *ngIf="loading"></i>
                                <div class="row mt-5">
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i
                                                        class="fa-solid fa-building color-icon"
                                                        style="font-size: 2rem;margin-left: 4px;"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11">
                                                <input type="text" class="form-control sinborde"
                                                    placeholder="Nombre del Cliente*" formControlName="nom_Cliente"
                                                    style="text-transform:uppercase;" pTooltip="Nombre del Cliente"
                                                    tooltipPosition="right">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i
                                                        class="fa-solid fa-pen-nib color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11" tooltipPosition="top">
                                                <input type="text" class="form-control sinborde"
                                                    placeholder="Tipo de proyecto" pTooltip="Tipo de Proyecto"
                                                    formControlName="tipoProyecto" style="text-transform:uppercase;">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i
                                                        class="far fa-calendar-times color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11">
                                                <input type="date" class="form-control sinborde" formControlName="fecha"
                                                    style="text-transform:uppercase;" pTooltip="Fecha"
                                                    tooltipPosition="right">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0 mr-4">
                                                <span class="input-group-addon"> <i class="fas fa-at color-icon"
                                                        style="font-size: 2rem;"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11">
                                                <input type="email" class="form-control sinborde"
                                                    formControlName="correoContacto" style="text-transform:uppercase;"
                                                    pTooltip="Correo del Contacto" tooltipPosition="right">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="form-group col-md-6">
                                        <div class="row g-0 mb-20">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i class="fas fa-university color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11" tooltipPosition="top">
                                                <input type="text" class="form-control sinborde" pTooltip="Corporativo"
                                                    placeholder="Ingresar el Corporativo al que Pertenece*"
                                                    formControlName="corporativo" style="text-transform:uppercase;">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-card row mt-4" style="margin: 20px 0;">
                                    <div class="col-md-12 mb-2 texto_negrita">
                                        <p>Seleccione las Ciudades Destino*</p>
                                        <div class="card kolor">
                                            <div class="card-body">
                                                <div class="row pt-1" style="padding-top: 0.25rem;">
                                                    <div class="mt-2 ml-2" style="margin-left: 0.5rem;">
                                                        <div class="col-sm-6 pl-2">
                                                            <p-multiSelect [options]="municipioscat"
                                                                [(ngModel)]="MunicipiosSeleccionados"
                                                                defaultLabel="Selecciona una o varias Ciudades"
                                                                optionValue="municipio" formControlName="ciudades"
                                                                [showClear]="true" optionLabel="municipio"
                                                                appendTo="body" display="chip"
                                                                (ngModelChange)="onMunicipiosChange()">
                                                            </p-multiSelect>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngFor="let municipioForm of ciudadesForms; let i = index"
                                        [formGroup]="municipioForm" class="form-card col-md-12 mt-3">
                                        <div class="card kolor">
                                            <div class="card-body">
                                                <div class="row mb-2">
                                                    <h5 class="texto_negrita">COSTO Y DETALLES : <span
                                                            class="texto_naranja_ba">{{
                                                            municipioForm.get('municipio')?.value }} <i
                                                                class="fa-solid fa-location-dot"
                                                                style="color: rgb(210, 29, 29); font-size: 1.4rem;"></i></span>
                                                    </h5>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="row col-md-25">
                                                            <div class="row col-md-6">
                                                                <div class="row col-md-3 mt-2">
                                                                    <span class="texto_negrita texto_verde_oliva"
                                                                        style="font-size: 1.5rem;">LV:</span>
                                                                </div>
                                                                <div class="row col-md-6">
                                                                    <p-inputNumber class="mt-2" placeholder="Costo LV*"
                                                                        formControlName="lv" mode="currency"
                                                                        currency="MXN" autoWidth="false"
                                                                        pTooltip="Costo LV" [style]="{'width':'100%'}"
                                                                        [showClear]="true" tooltipPosition="left">
                                                                    </p-inputNumber>
                                                                </div>
                                                            </div>
                                                            <div class="row col-md-6">
                                                                <div class="row col-md-4 mt-2">
                                                                    <span class="texto_negrita texto_verde_oliva"
                                                                        style="font-size: 1.5rem;">3.5:</span>
                                                                </div>
                                                                <div class="row col-md-6">
                                                                    <p-inputNumber class="mt-2" placeholder="Costo 3.5*"
                                                                        formControlName="v_3_5" mode="currency"
                                                                        currency="MXN" autoWidth="false"
                                                                        pTooltip="Costo 3.5" [style]="{'width':'100%'}"
                                                                        [showClear]="true" tooltipPosition="left">
                                                                    </p-inputNumber>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row col-md-25">
                                                            <div class="row col-md-6">
                                                                <div class="row col-md-3 mt-2">
                                                                    <span class="texto_negrita texto_verde_oliva"
                                                                        style="font-size: 1.5rem;">RABON:</span>
                                                                </div>
                                                                <div class="row col-md-6">
                                                                    <p-inputNumber class="mt-2"
                                                                        placeholder="Costo RABON*"
                                                                        formControlName="rabon" mode="currency"
                                                                        currency="MXN" autoWidth="false"
                                                                        pTooltip="Costo RABON"
                                                                        [style]="{'width':'100%'}" [showClear]="true"
                                                                        tooltipPosition="left">
                                                                    </p-inputNumber>
                                                                </div>
                                                            </div>
                                                            <div class="row col-md-6">
                                                                <div class="row col-md-4 mt-2">
                                                                    <span class="texto_negrita texto_verde_oliva"
                                                                        style="font-size: 1.5rem;">TH:</span>
                                                                </div>
                                                                <div class="row col-md-6">
                                                                    <p-inputNumber class="mt-2" placeholder="Costo TH*"
                                                                        formControlName="th" mode="currency"
                                                                        currency="MXN" autoWidth="false"
                                                                        pTooltip="Costo th" [style]="{'width':'100%'}"
                                                                        [showClear]="true" tooltipPosition="left">
                                                                    </p-inputNumber>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row col-md-25">
                                                            <div class="row col-md-6">
                                                                <div class="row col-md-3 mt-2">
                                                                    <span class="texto_negrita texto_verde_oliva"
                                                                        style="font-size: 1.5rem;">TRUCK:</span>
                                                                </div>
                                                                <div class="row col-md-6">
                                                                    <p-inputNumber class="mt-2"
                                                                        placeholder="Costo TRUCK*"
                                                                        formControlName="_53FT" mode="currency"
                                                                        currency="MXN" autoWidth="false"
                                                                        pTooltip="Costo TRUCK"
                                                                        [style]="{'width':'100%'}" [showClear]="true"
                                                                        tooltipPosition="left">
                                                                    </p-inputNumber>
                                                                </div>
                                                            </div>
                                                            <div class="row col-md-6">
                                                                <div class="row col-md-4 mt-2">
                                                                    <span class="texto_negrita texto_verde_oliva"
                                                                        style="font-size: 1.5rem;">USUARIO:</span>
                                                                </div>
                                                                <div class="row col-md-6">
                                                                    <input type="text" class="form-control mt-2"
                                                                        placeholder="USUARIO"
                                                                        formControlName="usuario" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row col-md-25">
                                                            <div class="row col-md-2 mt-2">
                                                                <span class="texto_negrita texto_verde_oliva"
                                                                    style="font-size: 1.5rem;">CELULAR:</span>
                                                            </div>
                                                            <div class="row col-md-3 mr-10"
                                                                style="padding-right: 90px;">
                                                                <input type="text" class="form-control mt-2"
                                                                    placeholder="CELULAR" formControlName="celular" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex" style="margin-left:25%">
                                                <button (click)="addMunicipioForm(municipioForm,i)" pButton pRipple
                                                    class="p-button-raised" icon="pi pi-plus-circle"
                                                    [disabled]="!municipioForm.valid" pTooltip="Almacenar informacion"
                                                    style="width: 25%; margin-left: 300px;margin-bottom: 20px; font-weight: bold; font-size: 1.1rem;">&nbsp;
                                                    Guardar Importes</button>
                                                <button (click)="removeMunicipioForm(i)" class="pbtn p-button-raised"
                                                    pButton pRipple icon="pi pi-minus-circle"
                                                    style="width: 25%; margin-bottom: 20px; font-weight: bold; font-size: 1.1rem; background-color: rgb(237, 166, 34);border: none;">&nbsp;
                                                    Eliminar Ciudad</button>
                                            </div>
                                            <div class="row d-flex justify-content-right mt-3">
                                                <div class="col-4">
                                                    <p *ngIf="!municipioForm.valid" class="texto-invalido text-right">
                                                        <i class="pi pi-info-circle" style="font-size: 1rem;">Es
                                                            Necesario Proporcionar
                                                            La Informaci&oacute;n Obligatoria(*)</i>
                                                    </p>
                                                    <p *ngIf="municipioForm.valid" class="texto-valido text-right">
                                                        <i class="pi pi-check-circle"
                                                            style="font-size: 1rem;">Informaci&oacute;n
                                                            Completa</i>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="form-group col-md-6">
                                        <div class="row g-0 mb-20">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i class="fas fa-university color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11" tooltipPosition="top">
                                                <input type="text" class="form-control sinborde"
                                                    pTooltip="Caracteristicas del Proyecto"
                                                    placeholder="Caracteristicas del Proyecto*"
                                                    formControlName="caracteristicasProyecto"
                                                    style="text-transform:uppercase;">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i
                                                        class="fas fa-hand-holding-usd color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                            </div>
                                            <div class="row col-sm-11">
                                                <p-inputNumber placeholder="Ingrese el Monto de la Venta*"
                                                    formControlName="importeVenta" mode="currency" currency="MXN"
                                                    autoWidth="false" pTooltip="Monto de la Venta"
                                                    [style]="{'width':'100%'}" [showClear]="true"
                                                    tooltipPosition="left">
                                                </p-inputNumber>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i
                                                        class="far fa-calendar-times color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                                <p class="color-icon mt-2 ml-2"
                                                    style="display: inline-block; font-weight: bold;"></p>
                                            </div>
                                            <div class="col-sm-11">
                                                <p-calendar dateFormat="dd/mm/yy" [appendTo]="'body'"
                                                    formControlName="fecha_inicio" pTooltip="Fecha de inicio"
                                                    placeholder="Fecha de Inicio" autoWidth="false"
                                                    [style]="{'width':'100%'}" [showClear]="true"></p-calendar>
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i
                                                        class="far fa-calendar-times color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                                <p class="color-icon mt-2 ml-2"
                                                    style="display: inline-block; font-weight: bold;"></p>
                                            </div>
                                            <div class="col-sm-11">
                                                <p-calendar dateFormat="dd/mm/yy" [appendTo]="'body'"
                                                    formControlName="fecha_termino" pTooltip="Fecha de Fin"
                                                    placeholder="Fecha de Finalizacion" autoWidth="false"
                                                    [style]="{'width':'100%'}" [showClear]="true"></p-calendar>
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i
                                                        class="fas fa-money-check color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11">
                                                <input type="number" class="form-control sinborde"
                                                    placeholder="Numero de Dias de Credito*"
                                                    pTooltip="Numero de Dias de Credito*"
                                                    formControlName="num_DiasCredito" style="text-transform:uppercase;">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i
                                                        class="fa-solid fa-diagram-project color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11">
                                                <input type="number" class="form-control sinborde"
                                                    placeholder="Ingrese el Periodo de Facturacion*"
                                                    pTooltip="Periodo de Facturacion"
                                                    formControlName="periodoFacturacion"
                                                    style="text-transform:uppercase;">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="col-md-12">
                                        <div>
                                            <span class="texto_negrita">Seleccionar Soportes de Cobranza:* </span>
                                        </div>
                                        <div>
                                        </div>
                                        <div class="mt-2 ml-2">
                                            <div class="col-sm-6 pl-2">
                                                <p-multiSelect [options]="catSoporteCobranza"
                                                    [(ngModel)]="usuariosSeleccionados"
                                                    defaultLabel="Selecciona uno o varios Soportes"
                                                    (onChange)="verSeleccionados()" optionValue="id_soportecobranza"
                                                    formControlName="soporteCobranza" [showClear]="true"
                                                    optionLabel="des_soportecobranza" appendTo="body" display="chip">
                                                </p-multiSelect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="col-md-12">
                                        <div>
                                            <span class="texto_negrita">Seleccionar los Tipos de Servicio:* </span>
                                        </div>
                                        <div>
                                        </div>
                                        <div class="row mt-2 ml-2">
                                            <div class="col-sm-6 pl-2">
                                                <p-multiSelect [options]="tipoproyectos"
                                                    [(ngModel)]="tiposProyectoSeleccionados"
                                                    defaultLabel="Selecciona uno o varios Tipos de Servicio"
                                                    autoWidth="false" optionLabel="nombre" optionValue="id_proyecto"
                                                    formControlName="tipos_Servicio" [showClear]="true"
                                                    [appendTo]="'body'" display="chip"></p-multiSelect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="row col-md-12">
                                        <div class="row col-md-6">
                                            <div class="row mt-2 ml-2">
                                                <span class="texto_negrita">Clasificacion del Producto
                                                </span>
                                                <div class="col-sm-13 pl-2">
                                                    <p-dropdown [options]="subclasificacion"
                                                        placeholder="Seleccione la Clasificacion del Producto"
                                                        optionLabel="nom_subclasificacion"
                                                        pTooltip="Clasificacion del Producto"
                                                        optionValue="id_sub_clasificacion_de_cte" autoWidth="false"
                                                        formControlName="clasificacion_producto"
                                                        [style]="{'width':'100%'}" [showClear]="true">
                                                    </p-dropdown>
                                                    <hr class="my-0">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row col-md-6">
                                            <div class="row">
                                                <span class="texto_negrita">Tipo de Traslado* <i
                                                        style="font-size: 1.7rem;"
                                                        class="fa-brands fa-product-hunt color-icon"></i>
                                                </span>
                                                <div class="row mt-2">
                                                    <div class="col-sm-13 pl-2">
                                                        <p-dropdown [options]="tipoP"
                                                            placeholder="Seleccione el tipo de prod." autoWidth="false"
                                                            [style]="{'width':'100%'}" optionValue="id_tipoproducto"
                                                            formControlName="tipoProducto" [showClear]="true"
                                                            optionLabel="nom_tipoproducto"></p-dropdown>
                                                        <hr class="my-0">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="row col-md-18">
                                        <div class="row col-md-6">
                                            <div class="row mt-2 ml-2">
                                                <span class="texto_negrita">Tipo de Mercancia:* <i
                                                        style="font-size: 1.7rem;"
                                                        class="fa-solid fa-shuffle color-icon"></i>
                                                </span>
                                                <div class="col-sm-13 pl-2">
                                                    <p-dropdown [options]="desgloseMercancia"
                                                        placeholder="Seleccione el tipo de Mercancia." autoWidth="false"
                                                        optionValue="des_mercancia" formControlName="des_mercancia"
                                                        [showClear]="true" optionLabel="des_mercancia"
                                                        (onChange)="MercanciaChange()" [appendTo]="'body'"
                                                        [(ngModel)]="mercanciaSelect"
                                                        [style]="{'margin-left': '1rem'}"></p-dropdown>
                                                    <hr class="my-0">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row col-md-6">
                                            <div class="row mt-2">
                                                <span class="texto_negrita">Tamaño de la mercancia:* <i
                                                        style="font-size: 1.7rem;"
                                                        class="fa-solid fa-weight-scale color-icon"></i>
                                                </span>
                                                <div class="col-sm-13 pl-2">
                                                    <p-dropdown [options]="tamanioRetorno"
                                                        placeholder="Seleccione el tipo de Mercancia." autoWidth="false"
                                                        optionValue="des_tamaño" formControlName="des_tamaño"
                                                        [showClear]="true" optionLabel="des_tamaño"
                                                        [style]="{'width':'100%'}"></p-dropdown>
                                                    <hr class="my-0">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="row col-md-18">
                                        <div class="row col-md-6">
                                            <span class="texto_negrita">Peso de la Mercancia en Kilos:* <i
                                                    style="font-size: 1.7rem;"
                                                    class="fa-solid fa-weight-scale color-icon"></i>
                                            </span>
                                            <div class="row col-md-12 mt-2 ml-2">

                                                <p-floatLabel>
                                                    <input formControlName="pesoMercancia" pInputText
                                                        style="width: 100%;" />
                                                </p-floatLabel>

                                            </div>
                                        </div>
                                        <div class="row col-md-6">
                                            <span class="texto_negrita ml-3">Se necesita Maniobras y Servicios
                                                Adicionales?:*
                                                <i style="font-size: 1.7rem;"
                                                    class="fa-solid fa-question color-icon"></i>
                                            </span>
                                            <div class="col-md-12 pl-2">
                                                <div class="row col-md-12 pl-2">
                                                    <p-dropdown [options]="ManiobrasServicios" placeholder="(Respuesta)"
                                                        autoWidth="false" optionValue="id_maniobra"
                                                        formControlName="id_maniobrasyserviciosadicionales"
                                                        [showClear]="true" optionLabel="opcion_maniobra"
                                                        [style]="{'margin-left': '1rem'}"></p-dropdown>
                                                    <hr class="my-0">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="col-md-12">
                                        <div>
                                            <span class="texto_negrita">Seleccionar la Configuracion de Unidades:* <i
                                                    style="font-size: 1.8rem;" class="fa-solid fa-truck color-icon"></i>
                                            </span>
                                        </div>
                                        <div>
                                        </div>
                                        <div class="row mt-2 ml-2">
                                            <div class="col-sm-6 pl-2">
                                                <p-multiSelect [options]="confUnidades"
                                                    [(ngModel)]="configuracionesSeleccionadas"
                                                    defaultLabel="Selecciona uno o varios Tipos de Configuraciones"
                                                    autoWidth="false" optionValue="clave_configuracionUnidad"
                                                    formControlName="configuracion_Unidades" [showClear]="true"
                                                    optionLabel="nombre" [appendTo]="'body'"
                                                    [style]="{'margin-left': '1rem'}" display="chip"></p-multiSelect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5 ml-3">
                                    <div class="col-md-12">
                                        <div class="row mt-2 ml-2">
                                            <div class="row col-sm-12 pl-2">
                                                <div class="col-md-4">
                                                    <span class="texto_negrita">Numero de Choferes <i
                                                            style="font-size: 1.7rem;"
                                                            class="fa-solid fa-user-nurse color-icon"></i></span>
                                                    <div>
                                                        <p-inputNumber placeholder="Numero de Choferes*"
                                                            formControlName="num_choferes" autoWidth="false"
                                                            [style]="{'width':'100%'}"
                                                            [showClear]="true"></p-inputNumber>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <span class="texto_negrita">Numero de Auxiliares <i
                                                            style="font-size: 1.7rem;"
                                                            class="fa-solid fa-people-carry-box color-icon"></i></span>
                                                    <div>
                                                        <p-inputNumber placeholder="Numero de Auxiliares*"
                                                            formControlName="num_auxiliares" autoWidth="false"
                                                            [style]="{'width':'100%'}"
                                                            [showClear]="true"></p-inputNumber>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <span class="texto_negrita">Horas de Servicio<i
                                                            style="font-size: 1.7rem;"
                                                            class="fa-solid fa-clock-rotate-left color-icon"></i></span>
                                                    <div>
                                                        <p-inputNumber placeholder="Horas de Servicio*"
                                                            formControlName="horas_servicio" autoWidth="false"
                                                            [style]="{'width':'100%'}"
                                                            [showClear]="true"></p-inputNumber>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="col-md-12">
                                        <div class="flex justify-content-center">
                                            <span style="font-size: 1.2rem;"
                                                class="texto_negrita">&nbsp;&nbsp;&nbsp;&nbsp;Horarios de Carga y
                                                Descarga:*
                                            </span>
                                        </div>
                                        <div class="row d-flex mt-2 ml-2">
                                            <div class="row col-sm-12 pl-2">
                                                <div class="col-md-6">
                                                    <span class="texto_negrita">Carga: <i style="font-size: 1.7rem;"
                                                            class="fa-regular fa-clock color-icon"></i></span>
                                                    <div>
                                                        <input type="text" class="form-control sinborde"
                                                            placeholder="Horario de Carga"
                                                            formControlName="horario_carga" style="width: 100%;"
                                                            style="text-transform:uppercase;">
                                                        <hr class="my-0">
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <span class="texto_negrita">Descarga: <i style="font-size: 1.7rem;"
                                                            class="fa-solid fa-clock color-icon"></i></span>
                                                    <div>
                                                        <input type="text" class="form-control sinborde"
                                                            placeholder="Horario de Descarga"
                                                            formControlName="horario_descarga" style="width: 100%;"
                                                            style="text-transform:uppercase;">
                                                        <hr class="my-0">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="col-md-12">
                                        <div class="flex justify-content-center">
                                            <span style="font-size: 1.2rem;"
                                                class="texto_negrita">&nbsp;&nbsp;&nbsp;&nbsp;Tiempo para Cargar y
                                                Descargar* <i style="font-size: 1.4rem;"
                                                    pTooltip="Considere el Tiempo de Descarga Dependiendo del Tipo de Centro"
                                                    class="pi pi-info-circle color-icon kilo"></i>
                                            </span>
                                        </div>
                                        <div class="row d-flex mt-2 ml-2">
                                            <div class="row col-sm-15 pl-2">
                                                <div class="col-md-4">
                                                    <span class="texto_negrita">Tiempo para Carga <i
                                                            style="font-size: 1.7rem;"
                                                            class="fa-solid fa-stopwatch color-icon"></i> </span>
                                                    <div>
                                                        <input type="text" class="form-control sinborde"
                                                            placeholder="Tiempo para Cargar"
                                                            formControlName="tiempoCarga" style="width: 100%;"
                                                            style="text-transform:uppercase;">
                                                        <hr class="my-0">
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <span class="texto_negrita">Tiempo para Descarga <i
                                                            style="font-size: 1.7rem;"
                                                            class="fa-solid fa-stopwatch color-icon"></i></span>
                                                    <div>
                                                        <input type="text" class="form-control sinborde"
                                                            placeholder="Tiempo para Descargar"
                                                            formControlName="tiempoDescarga" style="width: 100%;"
                                                            style="text-transform:uppercase;">
                                                        <hr class="my-0">
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <span class="texto_negrita">Tiempo de Espera o Estadia* <i
                                                            style="font-size: 1.7rem;"
                                                            class="fa-solid fa-hourglass-half color-icon"></i>
                                                    </span>
                                                    <input type="text" class="form-control sinborde"
                                                        placeholder="Tiempo de Espera"
                                                        formControlName="tiempoEspera_Estadia" style="width: 100%;"
                                                        style="text-transform:uppercase;">
                                                    <hr class="my-0">


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row d-flex mt-5">
                                    <div class="row col-md-12">
                                        <div class="col-md-4">
                                            <div class="ml-5">
                                                <span class="texto_negrita">&nbsp;Quien Recibe la Descarga* <i
                                                        style="font-size: 1.7rem;"
                                                        class="fa-solid fa-user color-icon"></i>
                                                </span>
                                            </div>
                                            <div class="col-md-13 pl-2">
                                                <textarea rows="4" placeholder="Quien Recibe la Descarga?*"
                                                    pTooltip="Ingrese Quien Recibe la Descarga" class="form-control"
                                                    cols="30" pInputTextarea
                                                    formControlName="recibe_descarga"></textarea>
                                                <!--p-dropdown [options]="cat_ClasificacionPersonal"
                                                    placeholder="Selecciona quien recibe la Descarga" autoWidth="false"
                                                    optionValue="id_clasificacionpersonal"
                                                    formControlName="recibe_descarga" [showClear]="true"
                                                    optionLabel="nom_clasificacionpersonal"
                                                    [style]="{'width':'100%'}"></!--p-dropdown-->
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="ml-5">
                                                <span class="texto_negrita">&nbsp;Quien Realiza la Descarga* <i
                                                        style="font-size: 1.7rem;"
                                                        class="fa-solid fa-arrow-down color-icon"></i>
                                                </span>
                                            </div>
                                            <div class="col-md-13 pl-2">
                                                <p-dropdown [options]="cat_GeneraCarga"
                                                    placeholder="Quien hace la Descarga" autoWidth="false"
                                                    optionValue="id_generacarga" formControlName="realiza_descarga"
                                                    [showClear]="true" optionLabel="des_generacarga" [appendTo]="'body'"
                                                    [style]="{'width':'100%'}"></p-dropdown>
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                        <div class="col-md-4 mt-1">
                                            <div class="ml-5">
                                                <span class="texto_negrita">&nbsp;Cual es el Proceso que
                                                    Lleva* <i style="font-size: 1.7rem;"
                                                        class="fa-solid fa-list-check color-icon"></i>
                                                </span>
                                            </div>
                                            <div class="col-md-13 ml-2">
                                                <textarea rows="4" placeholder="Ingrese el Proceso de Descarga*"
                                                    pTooltip="Ingrese el Proceso de Descarga" class="form-control"
                                                    cols="30" pInputTextarea
                                                    formControlName="proceso_descarga"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="row col-md-12">
                                        <div class="col-md-6">
                                            <div class="ml-5">
                                                <span class="texto_negrita">&nbsp;Que Tipo de Maniobra es la
                                                    Descarga?*
                                                    <i style="font-size: 1.7rem;"
                                                        class="fa-solid fa-screwdriver-wrench color-icon"></i>
                                                </span>
                                            </div>
                                            <div class="row col-md-13 pl-2">
                                                <p-dropdown [options]="cat_TipoManiobra" placeholder="Tipo de Maniobra"
                                                    autoWidth="false" optionValue="id_tipomaniobra"
                                                    formControlName="tipo_maniobradescarga" [showClear]="true"
                                                    optionLabel="des_tipomaniobra"
                                                    [style]="{'width':'100%'}"></p-dropdown>
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                        <div class="row col-md-6">
                                            <div class="ml-5">
                                                <span class="texto_negrita">&nbsp;Lugar de Descarga*
                                                    <i style="font-size: 1.7rem;"
                                                        class="fa-solid fa-screwdriver-wrench color-icon"></i>
                                                </span>
                                            </div>
                                            <div class="col-md-13 ml-2">
                                                <p-dropdown [options]="cat_ZonaDescarga" placeholder="Lugar de Descarga"
                                                    autoWidth="false" optionValue="id_zonadescarga"
                                                    formControlName="id_lugar_descarga" [showClear]="true"
                                                    optionLabel="des_zonadescarga"
                                                    [style]="{'width':'100%'}"></p-dropdown>
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="row col-md-12">
                                        <div class="col-md-6">
                                            <div class="ml-5">
                                                <span class="texto_negrita">&nbsp;Quien Genera la Carga* <i
                                                        style="font-size: 1.7rem;"
                                                        class="fa-solid fa-truck color-icon"></i>
                                                </span>
                                            </div>
                                            <div class="row col-md-13 pl-2">
                                                <p-dropdown [options]="cat_GeneraCarga"
                                                    placeholder="Quien Genera la Carga" autoWidth="false"
                                                    optionValue="id_generacarga" formControlName="genera_carga"
                                                    [showClear]="true" optionLabel="des_generacarga" [appendTo]="'body'"
                                                    [style]="{'width':'100%','margin-left': '1rem'}"></p-dropdown>
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="ml-5">
                                                <span class="texto_negrita">Lugar de la Carga* <i
                                                        style="font-size: 1.7rem;"
                                                        class="fa-solid fa-truck-ramp-box color-icon"></i>
                                                </span>
                                            </div>
                                            <div class="col-md-13 pl-2">
                                                <p-dropdown [options]="cat_ZonaDescarga" placeholder="Lugar de Carga"
                                                    autoWidth="false" optionValue="id_zonadescarga"
                                                    formControlName="lugar_carga" [showClear]="true"
                                                    optionLabel="des_zonadescarga"
                                                    [style]="{'width':'100%','margin-left': '1rem'}"></p-dropdown>
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="row col-md-12">
                                        <div class="col-md-6">
                                            <div class="ml-5">
                                                <span class="texto_negrita">&nbsp;Que Tipo de Maniobra es la Carga?* <i
                                                        style="font-size: 1.7rem;"
                                                        class="fa-solid fa-screwdriver-wrench color-icon"></i>
                                                </span>
                                            </div>
                                            <div class="col-md-13 pl-2">
                                                <p-dropdown [options]="cat_TipoManiobra" placeholder="Tipo de Maniobra"
                                                    autoWidth="false" optionValue="id_tipomaniobra"
                                                    formControlName="tipo_maniobracarga" [showClear]="true"
                                                    optionLabel="des_tipomaniobra" [appendTo]="'body'"
                                                    [style]="{'width':'100%','margin-left': '1rem'}"></p-dropdown>
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row d-flex mt-7">
                                    <div class="row col-md-12">
                                        <div class="col-md-4">
                                            <div class="ml-5">
                                                <span class="texto_negrita">&nbsp;Tipo de Embalaje* <i
                                                        style="font-size: 1.7rem;"
                                                        class="fa-solid fa-box-open color-icon"></i>
                                                </span>
                                            </div>
                                            <div class="col-md-13 pl-2">
                                                <p-dropdown [options]="cat_TipoCaja" placeholder="Tipo de Caja"
                                                    autoWidth="false" optionValue="id_tipocaja"
                                                    formControlName="tipo_caja" [showClear]="true"
                                                    optionLabel="des_tipocaja" [appendTo]="'body'"
                                                    [style]="{'width':'100%','margin-left': '1rem'}"></p-dropdown>
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="ml-5">
                                                <span class="texto_negrita">Las Cajas y Tarimas se Regresan de Tienda a
                                                    Almacen?* <i style="font-size: 1.7rem;"
                                                        class="fa-solid fa-boxes-packing color-icon"></i>
                                                </span>
                                            </div>
                                            <div class="col-md-13 pl-2">
                                                <p-dropdown [options]="regresoTarimas"
                                                    placeholder="Indicar si se Regresan las Tarimas" autoWidth="false"
                                                    optionValue="id_regresotarima" formControlName="regreso_tarimas"
                                                    [showClear]="true" optionLabel="des_regresotarima"
                                                    [style]="{'width':'100%','margin-left': '1rem'}"></p-dropdown>
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="ml-5">
                                                <span class="texto_negrita">Indique las Veces que se Visitara el Lugar*
                                                    <i style="font-size: 1.7rem;"
                                                        class="fa-solid fa-truck-arrow-right color-icon"></i>
                                                </span>
                                            </div>
                                            <div class="col-md-13 pl-2">
                                                <p-dropdown [options]="frecuenciavisita"
                                                    placeholder="Indicar la frecuencia de visita" autoWidth="false"
                                                    optionValue="id_visita" formControlName="frecuencia_visita"
                                                    [showClear]="true" optionLabel="id_visita"
                                                    [style]="{'width':'100%','margin-left': '1rem'}"></p-dropdown>
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="row col-md-12">
                                        <div class="col-md-6">
                                            <div class="ml-5">
                                                <span class="texto_negrita">&nbsp;Cual es el Tipo de Ruta?* <i
                                                        style="font-size: 1.7rem;"
                                                        class="fa-solid fa-route color-icon"></i>
                                                </span>
                                            </div>
                                            <div class="col-md-13 pl-2">
                                                <p-dropdown [options]="cat_TipoRuta" placeholder="Tipo de Ruta"
                                                    autoWidth="false" optionValue="id_tiporuta"
                                                    formControlName="tipo_ruta" [showClear]="true"
                                                    optionLabel="des_tiporuta" [appendTo]="'body'"
                                                    [style]="{'width':'100%','margin-left': '1rem'}"></p-dropdown>
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="ml-5">
                                                <span class="texto_negrita">&nbsp;Confirmacion de Servicio* <i
                                                        style="font-size: 1.7rem;"
                                                        class="fa-solid fa-clipboard-check color-icon"></i>
                                                </span>
                                            </div>
                                            <div class="col-md-13 pl-2">
                                                <p-dropdown [options]="cat_TipoConfirmacion"
                                                    placeholder="Confirmacion de Serv" autoWidth="false"
                                                    optionValue="id_tipoconfirmacion"
                                                    formControlName="tipo_confirmacion" [showClear]="true"
                                                    optionLabel="des_tipoconfirmacion" [appendTo]="'body'"
                                                    [style]="{'width':'100%','margin-left': '1rem'}"></p-dropdown>
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-5">
                                    <div class="col-md-12">
                                        <div>
                                            <span class="texto_negrita">Seleccionar los Indicadores de Desempeño:*
                                            </span>
                                        </div>
                                        <div>
                                        </div>
                                        <div class="row mt-2 ml-2">
                                            <div class="col-sm-6 pl-2">
                                                <p-multiSelect [options]="indicadoresDes"
                                                    [(ngModel)]="indicadoresSeleccionados"
                                                    defaultLabel="Selecciona uno o varios Indicadores" autoWidth="false"
                                                    optionValue="id_indicadordesempenio"
                                                    formControlName="indicadoresDesempeno" [showClear]="true"
                                                    optionLabel="des_indicadordesempenio" [appendTo]="'body'"
                                                    [style]="{'max-width': '1250px'}" display="chip"></p-multiSelect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="form-group col-md-6 mt-5">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i class="fas fa-ban color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11">
                                                <input type="text" class="form-control sinborde"
                                                    pTooltip="Penalizaciones" formControlName="penalizaciones"
                                                    placeholder="Penalizaciones" style="text-transform:uppercase;"
                                                    tooltipPosition="right">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6 mb-4">
                                        <div class="row">
                                            <div class="col-sm-12 mb-2">
                                                <span class="texto_negrita">Seleccione si se Ingresara el Portal
                                                    Comercial </span>
                                                <p-checkbox formControlName="chek"
                                                    pTooltip="Seleccionar Si se ingresara un Portal" [binary]="true"
                                                    (click)="MostrarPortal()"></p-checkbox>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12 pl-2">
                                                <div class="col-sm-11">
                                                    <input type="text" class="form-control sinborde" [disabled]="chec"
                                                        formControlName="urlPortalComercial"
                                                        placeholder="Url del Portal Comercial"
                                                        style="text-transform:uppercase;" tooltipPosition="right">
                                                    <hr class="my-0">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="form-group col-md-6 mb-4">
                                        <div class="row">
                                            <div class="col-sm-12 mb-2">
                                                <span class="texto_negrita">Seleccione si se Ingresara el Portal
                                                    Operativo </span>
                                                <p-checkbox formControlName="chet"
                                                    pTooltip="Seleccionar Si se ingresara un Portal Operativo"
                                                    [binary]="true" (click)="MostrarPortalOperativo()"></p-checkbox>
                                            </div>
                                        </div>
                                        <div class="row col-sm-13 pl-2">
                                            <input type="text" class="form-control sinborde" [disabled]="chet"
                                                formControlName="urlPortalOperativo"
                                                placeholder="Url del Portal Operativo" style="text-transform:uppercase;"
                                                tooltipPosition="right">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6 mb-4">
                                        <div class="row">
                                            <div class="col-sm-12 mb-2">
                                                <span class="texto_negrita">Seleccione si se Tendra Aplicacion
                                                </span>
                                                <p-checkbox formControlName="AplicacionFlag"
                                                    pTooltip="Seleccionar Si se Tendra Aplicacion" [binary]="true"
                                                    (click)="MostrarAplicacionFlag()"></p-checkbox>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12 pl-2">
                                                <div class="col-sm-11">
                                                    <input type="text" class="form-control sinborde"
                                                        [disabled]="AplicacionFlag" formControlName="nombreAplicacion"
                                                        placeholder="Ingrese el Nombre de la Aplicacion"
                                                        style="text-transform:uppercase;" tooltipPosition="right">
                                                    <hr class="my-0">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="row col-md-18">
                                        <div class="row col-md-6">
                                            <span class="texto_negrita">Seleccionar el Area Geografica:* </span>
                                            <div class="row mt-2 ml-2">
                                                <div class="col-sm-6 pl-2">
                                                    <p-dropdown [options]="catAreas"
                                                        placeholder="Selecciona uno o ambos tipos de area Geografica"
                                                        autoWidth="false" optionLabel="nom_area" optionValue="id_area"
                                                        formControlName="area_Geografica" [showClear]="true"
                                                        [appendTo]="'body'"></p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row col-md-6">
                                            <span class="texto_negrita texto_verde_oliva">COMENTARIOS DEL
                                                PROYECTO: <i class="pi pi-comments"
                                                    style="font-size: 1.3rem; color: black;"></i></span>
                                            <div class="row col-md-12" style="padding-right: 90px;">
                                                <textarea rows="4" placeholder="Comentarios del PROYECTO*"
                                                    class="form-control mt-2" cols="30" pInputTextarea
                                                    formControlName="des_comentariosproyecto"></textarea>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card flex justify-content-center mt-5" style="width: 80%;">
                                    <span class="form-card mt-2">
                                        <header>
                                            <h3>Seleccione el Manual de Operaciones a Subir* <i
                                                    class="fa-solid fa-circle-up color-icon mt-1"></i></h3>
                                        </header>
                                    </span>
                                    <p-fileUpload accept=".pdf" chooseLabel="Seleccionar Archivo"
                                        pTooltip="Seleccione Solo Archivos PDF" [style]="{'width':'100%'}"
                                        uploadLabel="Subir Archivo" cancelLabel="Cancelar" name="demos[]"
                                        url="https://www.primefaces.org/cdn/api/upload.php"
                                        (onUpload)="SubirArchivoBrief($event)" (onSelect)="onSelect($event)"
                                        [multiple]="true" [auto]="true">
                                        <ng-template pTemplate="content">
                                            <ul *ngIf="uploadedSoporte.length">
                                                <li *ngFor="let file of uploadedSoporte"><img
                                                        src="../../../assets/images/pd.jpg"
                                                        style="height: 25px;width: 25px;" alt=""> {{ file.name }} - {{
                                                    file.size }}
                                                    bytes <button icon="fa fa-close" pButton type="button"
                                                        label="Eliminar"
                                                        style="margin-left: 30px; height: 40px; width: 120px; background: rgb(215, 100, 58); border: none;"
                                                        (click)="delete(file)"></button></li>
                                            </ul>
                                            <ul *ngIf="NombresPDFManuales.length">
                                                <li *ngFor="let savedfile of NombresPDFManuales" class="mt-1"><img
                                                        src="../../../assets/images/pd.jpg"
                                                        style="height: 25px;width: 25px; margin-right: 5px;"
                                                        alt="">{{savedfile.nom_archivo}}
                                                    <p-button label="Ver PDF"
                                                        styleClass="p-button-raised p-button-text ml-3 mt-3"
                                                        icon="pi pi-file-pdf" (onClick)="VerArchivoBrief(savedfile)">+
                                                    </p-button>
                                                    <p-button label="Descargar PDF"
                                                        styleClass="p-button-raised p-button-text p-button-danger ml-3 mt-3"
                                                        icon="pi pi-file-pdf"
                                                        (onClick)="DescargarArchivoBrief(savedfile);">+
                                                    </p-button>
                                                    <button icon="fa fa-close" pButton type="button" class="kolor"
                                                        label="Eliminar"
                                                        style="margin-left: 30px; height: 40px; width: 120px; background: rgb(215, 100, 58); border: none;"
                                                        (click)="delete(savedfile)"></button>
                                                </li>
                                            </ul>
                                        </ng-template>
                                    </p-fileUpload>
                                </div>
                                <div class="row d-flex justify-content-center mt-3">
                                    <div class="col-4">
                                    </div>
                                    <div class="col-2">
                                        <button class="btn btn-primary form-control" (click)="guardarInformacionBrief()"
                                            pTooltip="Almacenar informacion" tooltipPosition="right">
                                            <span class="text-white">Guardar</span>
                                            <i class="pi pi-save text-white"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
            (click)="CerrarBriefComercial()"></button>
    </ng-template>
</p-dialog>
<p-dialog [resizable]="false" [modal]="true" [(visible)]="dspArchivo" [draggable]="false"
    [style]="{width:'60%','height':'700px'}" [transitionOptions]="'0ms'" styleClass="card-shadow">
    <ng-template pTemplate="header">
        Archivo Convenio NDA
    </ng-template>
    <ng-template pTemplate="content">
        <div class="container-fluid">
            <div class="form-card">
                <div class="card justify-content-center mb-4" style="width: 35%;">
                    <div class="row col-sm-12">
                        <div class="col-sm-12">
                            <span><b>Seleccione si Desea Subir o Ver un Archivo para el Convenio* </b></span>
                            <p-checkbox [style]="{'border':'2px'}" pTooltip="click para activar la notificacion"
                                [binary]="true" (click)="MostrarSubirArchivos()"></p-checkbox>&nbsp;<b><i
                                    style="font-size: 1.5rem; margin-top: 2px;"
                                    class="fa-regular fa-hand-point-up color-icon"></i></b>
                        </div>
                    </div>
                </div>
                <div class="card flex justify-content-center" style="width: 90%;">
                    <p-toast></p-toast>
                    <span class="form-card mt-2">
                        <header>
                            <h3>Seleccione el Archivo a Subir <i class="fa-solid fa-circle-up color-icon mt-1"></i>
                            </h3>
                        </header>
                    </span>
                    <p-fileUpload [disabled]="check" accept=".pdf" chooseLabel="Seleccionar Archivo"
                        pTooltip="Seleccione Solo Archivos PDF" [style]="{'width':'100%'}" uploadLabel="Subir Archivo"
                        cancelLabel="Cancelar" name="demo[]" url="https://www.primefaces.org/cdn/api/upload.php"
                        (onUpload)="onUpload($event)" (onSelect)="onSelect($event)" [multiple]="true" [auto]="true">
                        <ng-template pTemplate="content">
                            <ul *ngIf="uploadedFiles.length">
                                <li *ngFor="let file of uploadedFiles"><img src="../../../assets/images/pd.jpg"
                                        style="height: 25px;width: 25px;" alt=""> {{ file.name }} - {{ file.size }}
                                    bytes <button icon="fa fa-close" pButton type="button" label="Eliminar"
                                        style="margin-left: 30px; height: 40px; width: 120px; background: rgb(215, 100, 58); border: none;"
                                        (click)="delete(file)"></button></li>
                            </ul>
                            <ul *ngIf="NombresPDF.length">
                                <li *ngFor="let savedfile of NombresPDF" class="mt-1"><img
                                        src="../../../assets/images/pd.jpg"
                                        style="height: 25px;width: 25px; margin-right: 5px;"
                                        alt="">{{savedfile.nom_archivo}}
                                    <p-button label="Ver PDF" styleClass="p-button-raised p-button-text ml-3 mt-3"
                                        icon="pi pi-file-pdf" (onClick)="VerArchivo(savedfile);">+
                                    </p-button>
                                    <p-button label="Descargar PDF"
                                        styleClass="p-button-raised p-button-text p-button-danger ml-3 mt-3"
                                        icon="pi pi-file-pdf" (onClick)="DescargarArchivo(savedfile);">
                                    </p-button>
                                    <button icon="fa fa-close" pButton type="button" class="kolor" label="Eliminar"
                                        style="margin-left: 30px; height: 40px; width: 120px; background: rgb(215, 100, 58); border: none;"
                                        (click)="delete(savedfile)"></button>
                                </li>
                            </ul>
                        </ng-template>
                    </p-fileUpload>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
            (click)="this.dspArchivo=false"></button>
    </ng-template>
</p-dialog>