import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
//import { MainComponent } from './shared/main/main.component';

/***/
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ToastModule} from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import {ButtonModule} from 'primeng/button';
import { HttpClientModule } from '@angular/common/http';
import { RippleModule } from 'primeng/ripple';
import { ImageModule } from 'primeng/image';

import {MenuModule} from 'primeng/menu';
import {TooltipModule} from 'primeng/tooltip';
import {DropdownModule} from 'primeng/dropdown';
import { TagModule } from 'primeng/tag';

import {TableModule,Table} from 'primeng/table';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DividerModule} from 'primeng/divider';
import {CheckboxModule} from 'primeng/checkbox';
import {ColorPickerModule} from 'primeng/colorpicker';
import { BlockUIModule } from 'primeng/blockui';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SplitButtonModule } from 'primeng/splitbutton';

import {SidebarModule} from 'primeng/sidebar';
import { TabViewModule } from 'primeng/tabview';

import { ThousandPipe } from './shared/utils/pipes/thousand.pipe';
import { MainComponent } from './pages/main/main.component';
import { ClientesProspectoComponent } from './pages/clientes-prospecto/clientes-prospecto.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MediosDeContactoComponent } from './pages/medios-de-contacto/medios-de-contacto.component';
import { ClasificacionDeClientesComponent } from './pages/clasificacion-de-clientes/clasificacion-de-clientes.component';
import { SubClasificacionDeClientesComponent } from './pages/sub-clasificacion-de-clientes/sub-clasificacion-de-clientes.component';
import { BaseDeCobrosComponent } from './pages/base-de-cobros/base-de-cobros.component';
import { TipoDeRutaComponent } from './pages/tipo-de-ruta/tipo-de-ruta.component';
import { ImporteDeDieselComponent } from './pages/importe-de-diesel/importe-de-diesel.component';
import { ImporteDeGastosDeRutaAnualComponent } from './pages/importe-de-gastos-de-ruta-anual/importe-de-gastos-de-ruta-anual.component';
import { ImporteDeDiasParaUtilidadComponent } from './pages/importe-de-dias-para-utilidad/importe-de-dias-para-utilidad.component';
import { ComisionCombustibleSodexoComponent } from './pages/comision-combustible-sodexo/comision-combustible-sodexo.component';
import { TipoProyectoComponent } from './pages/tipo-proyecto/tipo-proyecto.component';
import { TipoCombustibleComponent } from './pages/tipo-combustible/tipo-combustible.component';
import { CalendarioComponent } from './pages/calendario/calendario.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BadgeModule } from 'primeng/badge';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ProspeccionEstatusClienteComponent } from './pages/prospeccion-estatus-cliente/prospeccion-estatus-cliente.component';
import { PanelModule } from 'primeng/panel';
import { CardModule } from 'primeng/card';
import { StepsModule } from 'primeng/steps';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { TabuladorCostosComponent } from './pages/tabulador-costos/tabulador-costos.component';
import { PdfBriefComponent } from './pages/pdf-brief/pdf-brief.component';
import { ChartModule } from 'primeng/chart';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TreeTableModule } from 'primeng/treetable';
import { SplitterModule } from 'primeng/splitter';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    ThousandPipe,
    ClientesProspectoComponent,
    MediosDeContactoComponent,
    ClasificacionDeClientesComponent,
    SubClasificacionDeClientesComponent,
    BaseDeCobrosComponent,
    TipoDeRutaComponent,
    ImporteDeDieselComponent,
    ImporteDeGastosDeRutaAnualComponent,
    ImporteDeDiasParaUtilidadComponent,
    ComisionCombustibleSodexoComponent,
    TipoProyectoComponent,
    TipoCombustibleComponent,
    CalendarioComponent,
    ProspeccionEstatusClienteComponent,
    TabuladorCostosComponent,
    PdfBriefComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    ToastModule,
    MessagesModule,
    ButtonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RippleModule,
    ImageModule,
    MenuModule,
    TooltipModule,
    SidebarModule,
    DropdownModule,
    TableModule,
    CalendarModule,
    SliderModule,
    MultiSelectModule,
    ContextMenuModule,
    DialogModule,
    ProgressBarModule,
    InputTextModule,
    ConfirmDialogModule,
    DividerModule,
    CheckboxModule,
    ColorPickerModule,
    TagModule,
    BlockUIModule,
    TabViewModule,
    ToggleButtonModule,
    SplitButtonModule,
    NgbModule,
    FullCalendarModule,
    InputTextareaModule,
    BadgeModule,
    AvatarModule,
    AvatarGroupModule,
    PanelModule,
    CardModule,
    StepsModule,
    FileUploadModule,
    InputNumberModule,
    ChartModule,
    NgApexchartsModule,
    SelectButtonModule,
    TreeTableModule,
    SplitterModule
  ],
  providers: [DatePipe,ConfirmationService,MessageService],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
