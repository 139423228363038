import { Component } from '@angular/core';

@Component({
  selector: 'app-base-de-cobros',
  templateUrl: './base-de-cobros.component.html',
  styleUrls: ['./base-de-cobros.component.scss']
})
export class BaseDeCobrosComponent {

}
