import { Component } from '@angular/core';

@Component({
  selector: 'app-importe-de-gastos-de-ruta-anual',
  templateUrl: './importe-de-gastos-de-ruta-anual.component.html',
  styleUrls: ['./importe-de-gastos-de-ruta-anual.component.scss']
})
export class ImporteDeGastosDeRutaAnualComponent {

}
