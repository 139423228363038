import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Table } from 'primeng/table';
import { HttpService } from 'src/app/shared/services/http.service';
import MediosDeContactoModel from 'src/app/shared/models/MediosDeContactoModel';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { AuthService } from 'src/app/shared/services/auth.service';
import User from 'src/app/shared/models/user';
import  {IResultadoGeneral}  from 'src/app/shared/models/ResultadoGeneral';
import { mediodecontacto } from 'src/app/shared/services/mediodecontacto.service';
import { trigger, style, transition, animate } from '@angular/animations'
import { Estatus } from 'src/app/shared/models/Estatus';

@Component({
  selector: 'app-medios-de-contacto',
  templateUrl: './medios-de-contacto.component.html',
  styleUrls: ['./medios-de-contacto.component.scss'],
  animations: [
    trigger('nuevoRegistro', [
      transition(':enter', [
        style({ transform: 'translateY(20px)', opacity: 0 }),
        animate('500ms', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
  ],
})
export class MediosDeContactoComponent implements OnInit {
  //animacion
  nuevoRegistroAnimation = 'nuevoRegistro';


  @ViewChild('filtroInput') filtroInput!: ElementRef;
  filtroVacio:string='';
  usuario:User={};
  color_gris_deshabilitadas:string = '#D9D9D9';
  mediosdecontactos: MediosDeContactoModel[]=[];
  @ViewChild('dt1') dt!: Table;
  dspmediodecontacto:boolean=false;
  activityValues: number[] = [0, 100];
  loading: boolean = false;
  fecha_dia:any;
  numero_operacion:number=0;
  texto_operacion: string = '';

  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };

  objEstatus:Estatus[]=[{
    clv_activo:0,
    des_estatus:'Desactivado'
  },
  {
    clv_activo:1,
    des_estatus:'Activo'
  }]
  mediosDeContactoForm: FormGroup = new FormGroup({
    NombreMedioContacto: new FormControl('',[ 
      Validators.required
    ]),
    descripcion: new FormControl('',[ 
      Validators.required
    ]),
    clv_activo: new FormControl(0,[]),
    id_medio_de_contacto: new FormControl(0,[])
  });

  data_editar_mediocontacto: MediosDeContactoModel={
    opcion:0,
    id_medio_de_contacto:0,
    cod_usuario:'',
   // cod_usuario_ult_mov:'',
    clv_activo:0,
    nom_mediosdecontacto: '',
    des_mediosdecontacto: '',
  };

  constructor(private http:HttpService, private message:MessageService, private auth:AuthService,
    private confirmationService: ConfirmationService,private MediodContacto:mediodecontacto){
    this.cargarInformacionUsuario();
  }
  ngOnInit(): void {
    this.inicializarFechas();
    this.getListadoMediosContacto();
  }

  cargarInformacionUsuario(){
    this.usuario = this.auth.user;
  }

  inicializarFechas(){
    var getYear = new Date().toLocaleDateString('es-MX',{ year: 'numeric'});   
    var getMonth = new Date().toLocaleDateString('es-MX',{ month: '2-digit'});
    var getDay = new Date().toLocaleDateString('es-MX',{day: '2-digit'});
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia=dateFormat;
  }

  getListadoMediosContacto(){
    this.loading=true;
    this.MediodContacto.getMedioContacto().subscribe((resp)=>{
      this.mediosdecontactos=resp;
      setTimeout(() => {
        this.loading=false;
      }, 1000);
    },
    (error)=>{
      this.loading=false;
      this.message.add({ severity: 'error', summary: 'Error en <Cargar Listados Medios de contacto>', detail: 'Contacte al Administrador del Sitio' });
    });
   }

   Editar(data:MediosDeContactoModel){
    this.data_editar_mediocontacto=data;
    let data_enviar: MediosDeContactoModel={
      id_medio_de_contacto:this.data_editar_mediocontacto.id_medio_de_contacto,
      nom_mediosdecontacto:this.data_editar_mediocontacto.nom_mediosdecontacto,
      des_mediosdecontacto:this.data_editar_mediocontacto.des_mediosdecontacto,
      clv_activo: this.data_editar_mediocontacto.clv_activo,
      cod_usuario: this.usuario.cod,
      opcion:2
    };
    this.MediodContacto.editMedioContacto(data_enviar).subscribe((resp)=>{
      var resultado=resp;
      setTimeout(() => {
        this.getListadoMediosContacto();
        this.message.add({severity:'success',summary:'Edicion de celda exitosa',detail:'la informacion se edito correctamente'});
      }, 800);
    })
   }

   mostrarModalEditar(data: MediosDeContactoModel,num_operacion:number) {
    this.data_editar_mediocontacto=data;
    this.numero_operacion=num_operacion;
    this.mediosDeContactoForm.controls['id_medio_de_contacto'].setValue(data.id_medio_de_contacto);
    this.mediosDeContactoForm.controls['NombreMedioContacto'].setValue(data.nom_mediosdecontacto);
    this.mediosDeContactoForm.controls['descripcion'].setValue(data.des_mediosdecontacto);
    //this.mediosDeContactoForm.controls['clv_activo'].setValue(data.clv_activo);
    //
    //
    //
    this.dspmediodecontacto = true;
    this.texto_operacion = 'Editar Colaborador';
  }

  redireccionarNueva(num_operacion:number) {
    this.numero_operacion=num_operacion;
    this.texto_operacion = '';
    this.mediosDeContactoForm.reset();
    this.resultado = {
      clv_estatus: 0,
      messageDetails: ''
    };
    this.texto_operacion = 'Nuevo Medio de contacto';
    //
    //
    this.dspmediodecontacto = true;
}



confirmarDesactivar(data: MediosDeContactoModel) {
  let estatus_original= data.clv_activo;
  let texto_desactivar: string = data.clv_activo == 1 ? 'DESACTIVAR' : 'ACTIVAR';
  let texto_desactivar_header: string = data.clv_activo == 1 ? 'Desactivar' : 'Activar';
  this.confirmationService.confirm({
    message: 'Esta seguro que desea <b>' + texto_desactivar + '</b> el medio de contacto <b>'+data.nom_mediosdecontacto+' '+data.des_mediosdecontacto+'</b>?',
    header: 'Confirmacion de ' + texto_desactivar_header,
    icon: 'pi pi-info-circle',
    accept: () => {
      data.cod_usuario=this.usuario.cod;
      data.opcion=3;
      data.clv_activo = data.clv_activo == 1 ? 0 : 1;
      this.guardarInformacionDesactivar(data);
    },
    reject: (type: any) => {
      switch (type) {
        case ConfirmEventType.REJECT:
          data.clv_activo=estatus_original;
          this.message.add({ key: 'msj', severity: 'error', summary: 'Rechazado', detail: 'Se ha Rechazado' });
          break;
        case ConfirmEventType.CANCEL:
          data.clv_activo=estatus_original;
          this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Se ha Cancelado' });
          break;
      }
    }
  });
}

guardarInformacionDesactivar(data: MediosDeContactoModel) {
  var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
  var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
  var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
  var dateFormat = getYear + "-" + getMonth + "-" + getDay;
 // data.cod_usuario_ult_mov=this.usuario.cod==null? '0999': this.usuario.cod;
  this.MediodContacto.editMedioContacto(data).subscribe((resp)=> {
    var resultado=resp;
    if(resultado.clv_estatus==1){
      setTimeout(() => {
        this.message.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
        this.mediosDeContactoForm.reset();
        this.cancelarVentanaModal();
      }, 800);
    }
  },
  (error)=> {
    this.message.add({ severity: 'error', summary: 'Error en <Editar Chofer>', detail: 'Contacte al Administrador del Sitio' });
  });
}

  guardarInformacion(){
    this.inicializarFechas();
    let data_enviar: MediosDeContactoModel={
      id_medio_de_contacto:this.numero_operacion==1 ? 0:this.mediosDeContactoForm.get('id_medio_de_contacto')?.value,
      nom_mediosdecontacto:this.mediosDeContactoForm.get('NombreMedioContacto')?.value,
      des_mediosdecontacto:this.mediosDeContactoForm.get('descripcion')?.value.toUpperCase(),
      clv_activo: typeof this.mediosDeContactoForm.get('clv_activo')?.value === 'object' ? 1 : this.mediosDeContactoForm.get('clv_activo')?.value,
      cod_usuario: this.usuario.cod ,
      opcion:this.numero_operacion
      
    };
    if(this.numero_operacion==1){
      this.MediodContacto.SaveMedioContacto(data_enviar).subscribe((resp)=>{
        var resultado=resp;
        setTimeout(()=>{
          //agregar mensaje informativo en caso de que el cliente este repetido

          this.mediosdecontactos.push(data_enviar);
          this.getListadoMediosContacto();
          this.message.add({ severity: 'success', summary: 'Medio de contacto Exitoso', detail: 'La informacion se almaceno de forma correcta' });
          this.mediosDeContactoForm.reset();
          this.cancelarVentanaModal();
        },800);
      },
      (error)=> {
        this.message.add({ severity: 'error', summary: 'Error en <Agregar Medio de contacto>', detail: 'Contacte al Administrador del Sitio' });
      });
    } else
    if(this.numero_operacion==2){
      this.MediodContacto.editMedioContacto(data_enviar).subscribe((resp)=>{
        var resultado=resp;
        setTimeout(() => {
          this.getListadoMediosContacto();
          this.message.add({severity:'success',summary:'Edicion Exitosa',detail:'la informacion se edito correctamente'});
          this.mediosDeContactoForm.reset();
          this.cancelarVentanaModal();
        }, 800);
      })
    }
  }

  cancelarVentanaModal() {
    this.texto_operacion = '';
    //this.num_operacion=0;
    this.dspmediodecontacto = false;
    this.mediosDeContactoForm.reset();
    this.resultado = {
      clv_estatus: 0,
      messageDetails: ''
    };
  }

  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }
  limpiarFiltro() {
    this.filtroInput.nativeElement.value = ''; // Establecer el valor del filtro en una cadena vacía
    // Puedes llamar a la función applyFilterGlobal aquí si es necesario
  }
  clear(table: Table) {
    table.clear();
    this.limpiarFiltro();
}
}

