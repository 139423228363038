<div class="d-flex justify-content-center">
    <div class="container-fluid" style="width: 90%;">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="card card-shadow sinborde">
                    <div class="card-header sinborde" style="background: none; ">
                        <div class="cardicono rounded">
                            <i class="pi pi-share-alt mt-3" style="font-size: 2rem; color: #fff;"></i>
                        </div>
                        <header class="posicion" style="left: 11%; top: 0%;">Prospeccion y estatus del Cliente</header>
                        <div class="row d-flex justify-content-end">
                            <div class="col-2">
                            </div>
                        </div>
                    </div>
                    <div class="card-content">
                        <div class="row mt-3 mx-0 px-1">
                            <p-table #dt1 [value]="cat_prospeccion" [tableStyle]="{'min-width': '50rem'}"
                                styleClass="p-datatable-sm p-datatable-striped" dataKey="id" 
                                responsiveLayout="scroll" [scrollable]="true" scrollDirection="both" scrollHeight="580px"
                                [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10, 25, 50,100]" [loading]="loading"
                                [showCurrentPageReport]="true" 
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                                [globalFilterFields]="['categoriaempresa','correo','empresa','fechaprimercto','fechaultcontacto','fechaproxcto','nombreprimercto','personalseguimiento','puesto','telefono','tipocontacto','ultimomediocto']">
                                <ng-template pTemplate="caption">
                                    <div class="flex">
                                        <button pButton label="Limpiar" class="p-button-outlined mr-2"
                                            icon="pi pi-filter-slash" (click)="clear(dt1)">
                                        </button>
                                        <span class="p-input-icon-left ml-auto">
                                            <!--<i class="pi pi-search"></i>-->
                                            <input pInputText type="text"
                                             (input)="applyFilterGlobal($event, 'contains')"
                                              placeholder="Buscar Folio de Cliente,Nombre,Personal a cargo"
                                              size="40" />
                                        </span>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr [ngClass]="{'texto_sm':true}">
                                        <th pFrozenColumn style="min-width:5rem;width: 5rem;">
                                            #
                                        </th>
                                        <th pFrozenColumn style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Folio
                                                <p-columnFilter type="text" field="folio"
                                                    display="menu"></p-columnFilter>
                                            </div>
                                        </th>
                                        <th pFrozenColumn style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Personal Que Le Da Seguimiento
                                                <p-columnFilter type="text" field="personalseguimiento"
                                                    display="menu"></p-columnFilter>
                                            </div>
                                        </th>
                                        <th pFrozenColumn style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Nombre de la empresa
                                                <p-columnFilter type="text" field="empresa" display="menu"></p-columnFilter>
                                            </div>
                                        </th>
                                        <th style="min-width:15rem;width: 15rem;" pSortableColumn="categoriaempresa">
                                            <div class="flex align-items-center">
                                                Categoria de la empresa
                                                <p-sortIcon field="categoriaempresa"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Nombre Del Primer Contacto
                                                <p-columnFilter type="text" field="nombreprimercto"
                                                    display="menu"></p-columnFilter>
                                            </div>
                                        </th>
                                        <th style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Puesto
                                                <p-columnFilter type="text" field="puesto"
                                                    display="menu"></p-columnFilter>
                                            </div>
                                        </th>
                                        <th style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Telefono
                                                <p-columnFilter type="text" field="telefono"
                                                    display="menu"></p-columnFilter>
                                            </div>
                                        </th>
                                        <th style="min-width:15rem;width: 15rem;" pSortableColumn="correo">
                                            <div class="flex align-items-center">
                                                Correo
                                                <p-sortIcon field="correo"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th style="min-width:10rem;width: 10rem;">
                                            <div class="text-center">
                                                Fec.Primer Contacto
                                            </div>
                                        </th>
                                        <th style="min-width:10rem;width: 10rem;">
                                            <div class="text-center">
                                                Fec.Ultimo Contacto
                                            </div>
                                        </th>
                                        <th style="min-width:10rem;width: 10rem;">
                                            <div class="text-center">
                                                Fec.Proximo Contacto
                                            </div>
                                        </th>
                                        <th style="min-width:10rem;width: 10rem;">
                                            <div class="text-center">
                                                Estatus Lead
                                            </div>
                                        </th>
                                        <th style="min-width:10rem;width: 10rem;">
                                            <div class="text-center">
                                                Origen Lead
                                            </div>
                                        </th>
                                        <!--<th style="min-width:8rem">
                                            <div class="flex align-items-center">
                                                Medio De Contacto
                                                <p-columnFilter type="text" field="tipocontacto"
                                                    display="menu"></p-columnFilter>
                                            </div>
                                        </th>
                                        <th style="min-width:8rem">
                                            <div class="flex align-items-center">
                                                Ultimo medio de contacto
                                                <p-columnFilter type="text" field="ultimomediocto"
                                                    display="menu"></p-columnFilter>
                                            </div>
                                        </th>-->
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data let-index="rowIndex" let-editing="editing">
                                    <tr [ngClass]="{'row-accessories': data.clv_activo ===0}">
                                        <td pFrozenColumn [frozen]="true" class="texto_negrita">
                                            {{(index+1)}}
                                        </td>
                                        <td pFrozenColumn [frozen]="true" class="texto_negrita texto_naranja_ba">
                                           <div> {{data.folio}}</div>
                                        </td>
                                        <td pFrozenColumn [frozen]="true">
                                            <div>{{data.personalseguimiento | uppercase}}</div>
                                        </td>
                                        <td pFrozenColumn [frozen]="true">
                                            <div class="texto_negrita texto_verde_oliva">{{data.empresa | uppercase}}</div>
                                        </td>
                                        <td>
                                            <div>{{data.categoriaempresa | uppercase}}</div>
                                            
                                        </td>
                                        <td>
                                            <div>{{data.nombreprimercto | uppercase}}</div>
                                            
                                        </td>
                                        <td>
                                            <div>{{data.puesto | uppercase}}</div>
                                        </td>
                                        <td>
                                            <i *ngIf="data.telefono!==''" class="pi pi-phone mt-3" style="font-size: 1rem; color:#2454B8;"></i>
                                            {{data.telefono}}
                                        </td>
                                        <td>
                                            <i *ngIf="data.correo!==''" class="pi pi-envelope mt-3" style="font-size: 1rem; color:rgb(132, 119, 38);"></i>
                                            {{data.correo}}
                                        </td>
                                        <td style="flex-direction: column;">
                                            <div class="text-center">
                                                <i *ngIf="data.tipocontacto=='MENSAJES'" class="pi pi-whatsapp mt-3" style="font-size: 1rem; color:green;" pTooltip="Contacto: {{data.tipocontacto}}" tooltipPosition="top"></i>
                                                <i *ngIf="data.tipocontacto=='TELEFONO'" class="pi pi-phone mt-3" style="font-size: 1rem; color:cornflowerblue;" pTooltip="Contacto: {{data.tipocontacto}}" tooltipPosition="top"></i>
                                                <i *ngIf="data.tipocontacto=='LINKEDIN'" class="pi pi-linkedin mt-3" style="font-size: 1rem; color:cornflowerblue;" pTooltip="Contacto: {{data.tipocontacto}}" tooltipPosition="top"></i>
                                                <i *ngIf="data.tipocontacto=='CORREO'" class="pi pi-envelope mt-3" style="font-size: 1rem; color:cornflowerblue;" pTooltip="Contacto: {{data.tipocontacto}}" tooltipPosition="top"></i>
                                                <i *ngIf="data.tipocontacto=='VISITA'" class="pi pi-user mt-3" style="font-size: 1rem; color:cornflowerblue;" pTooltip="Contacto: {{data.tipocontacto}}" tooltipPosition="top"></i>
                                            </div>
                                            <div class="text-center"> {{data.fechaprimercto | date: 'dd/MM/yyyy'}}</div>
                                        </td>
                                        <td style="flex-direction: column;">
                                            <div class="text-center">
                                                <i *ngIf="data.ultimomediocto=='MENSAJES' && data.fechaultcontacto!='1900-01-01'" class="pi pi-whatsapp mt-3" style="font-size: 1rem; color:green;" pTooltip="Contacto: {{data.ultimomediocto}}" tooltipPosition="top"></i>
                                                <i *ngIf="data.ultimomediocto=='TELEFONO' && data.fechaultcontacto!='1900-01-01'" class="pi pi-phone mt-3" style="font-size: 1rem; color:cornflowerblue;" pTooltip="Contacto: {{data.ultimomediocto}}" tooltipPosition="top"></i>
                                                <i *ngIf="data.ultimomediocto=='LINKEDIN' && data.fechaultcontacto!='1900-01-01'" class="pi pi-linkedin mt-3" style="font-size: 1rem; color:cornflowerblue;" pTooltip="Contacto: {{data.ultimomediocto}}" tooltipPosition="top"></i>
                                                <i *ngIf="data.ultimomediocto=='CORREO' && data.fechaultcontacto!='1900-01-01'" class="pi pi-envelope mt-3" style="font-size: 1rem; color:cornflowerblue;" pTooltip="Contacto: {{data.ultimomediocto}}" tooltipPosition="top"></i>
                                                <i *ngIf="data.ultimomediocto=='VISITA' && data.fechaultcontacto!='1900-01-01'" class="pi pi-user mt-3" style="font-size: 1rem; color:cornflowerblue;" pTooltip="Contacto: {{data.ultimomediocto}}" tooltipPosition="top"></i>
                                            </div>
                                            <div class="text-center" *ngIf="data.fechaultcontacto!='1900-01-01'">{{data.fechaultcontacto | date: 'dd/MM/yyyy'}}</div>
                                        </td>
                                        <td>
                                            <div class="d-flex justify-content-center align-items-center" *ngIf="data.fechaproxcto!='1900-01-01'"> {{data.fechaproxcto | date: 'dd/MM/yyyy'}}</div>
                                        </td>
                                        <td>
                                            <p class="d-flex justify-content-center align-items-center" style="color: #1e4cad !important; text-align: justify !important;"><b>{{data.estatuslead}}</b></p>
                                        </td>
                                        <td>
                                            <p class="d-flex justify-content-center align-items-center"> {{data.origenlead}}</p>
                                        </td>
                                        <!--<td>
                                            {{data.tipocontacto}}
                                        </td>
                                        <td>
                                            {{data.ultimomediocto}}
                                        </td>-->
                                        <!--td [pEditableColumn]="data.clv_interesado"
                                            pEditableColumnField="clv_interesado">
                                            <p-cellEditor>
                                                <!ng-template pTemplate="input">
                                                    <p-dropdown [options]="estatus" optionLabel="des_estatus" [(ngModel)]="valorCombo" (onShow)="SetDropDown(data)"
                                                        (onChange)="confirmarDesactivar(data)" optionValue="clv_activo"
                                                        appendTo="body" [style]="{'width':'100%'}"></p-dropdown>
                                                </!--ng-template>
                                                <ng-template pTemplate="output">
                                                    <p-tag *ngIf="data.clv_interesado==0" icon="pi pi-user"
                                                        [rounded]="true" severity='danger'
                                                        value="No interesado"></p-tag>
                                                    <p-tag *ngIf="data.clv_interesado==1" icon="pi pi-user"
                                                        [rounded]="true" severity='success' value="Interesado"></p-tag>
                                                    <p-tag *ngIf="data.clv_interesado==2" icon="pi pi-user"
                                                        [rounded]="true" severity='warning'
                                                        value="Sin respuesta"></p-tag>
                                                </ng-template>
                                            </p-cellEditor>
                                        </!--td-->
                                        <!--td colspan="2" style="width: 180px;">
                                            <div class="d-flex justify-content-around">
                                                <button class="btn btn-info" pButton icon="pi pi-clone"
                                                    label="Agregar Contacto" style="margin-right: 10px !important;"
                                                    tooltipPosition="left" (click)="AgregarContacto(data,1);">
                                                </button>
                                                <button pButton icon="pi pi-external-link"
                                                    style="margin-right: 10px !important; background-color: lightslategray;" tooltipPosition="left"
                                                    label="Ver contacto" (click)="VerContacto(data);">
                                                </button>
                                                <button class="btn btn-warning" pTooltip="Editar Informacion"
                                                    tooltipPosition="left" (click)="mostrarModalEditar(data,2);">
                                                    <i class="pi pi-pencil"></i>
                                                </button>
                                            </div>
                                        </!--td-->
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="7">Sin Clientes Prospecto.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            <div class="row mt-3 d-flex justify-content-center">
                                <div class="col-6">
                                    <div class="mt-3 mb-2">
                                        <p-colorPicker [(ngModel)]="color_gris_deshabilitadas"
                                            [disabled]="true"></p-colorPicker>
                                        <p class="text-left informativo" style="display: inline;">
                                            Clientes prospecto inactivos</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>